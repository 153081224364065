<template>
	<div class="root-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="按热门地区" name="first" lazy>
				<cpByHotArea @afterSaved="afterSaved" :isShowOptType="isShowOptType"></cpByHotArea>
			</el-tab-pane>
			<el-tab-pane label="按热门城市" name="second" lazy>
				<cpByHotCity @afterSaved="afterSaved" :isShowOptType="isShowOptType"></cpByHotCity>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import cpByHotArea from '@/views/hotArea/cp_SetByHotArea.vue'
	import cpByHotCity from '@/views/hotArea/cp_SetByHotCity.vue'
	export default {
		emits: ["afterSaved"],
		props:{
			isShowOptType:{
				type:Boolean,
				default:true
			}
		},
		components: {
			cpByHotArea,
			cpByHotCity
		},
		
		data() {
			return {
				activeName: 'first'
			}
		},
		computed: {},
		methods: {
			afterSaved() {
				this.$emit('afterSaved')
			}
		},
		created() {

		}
	}
</script>

<style scoped="scoped" lang="less">
	.root-container {
		background-color: white;
		height: 100%;
	}
</style>