<!--带考虑和不设置的倾向组件-->
<!--带考虑和不设置的倾向组件
属性  ShowTitle        星值显示的标题
      Val              当前星值
      MaxValue         最大值（显示的星值）
      IsshowSet        是否显示 不考虑、清空按钮

事件：
OnChange 返回 当前星值
-->
<template>
	<span class="favratescss" :ref="'sss'+new Date()" v-if="show">
		<el-rate v-model="ShowVal" text-color="#ff9900" :max="3" :title="ShowTitle"
			@change="(val) => { this.rates = val; handleChange(val) }" :disabled="Readonly">
		</el-rate>
		<el-icon class="el-icon-s-release" v-show="!Readonly" :class="rates == -1 ? 'color3' : ''"
			:disabled="rates == -1" style="float: left;margin-right: 0px;margin-left: 5px;" @click="handleChange(-1)" title="设为不考虑">
			<Failed />
		</el-icon>
		<el-icon class="el-icon-s-release" v-show="!Readonly" style="float:left;margin-left:5px;" title="清空设置"
			@click="handleChange(0)">
			<Refresh />
		</el-icon>
	</span>
</template>

<script>
	export default {
		props: {
			//模板批次ID
			ShowTitle: {
				type: String,
				default: ""
			},
			Val: {
				type: Number,
				default: 0
			},
			MaxValue: {
				type: Number,
				default: 3
			},
			//是否显示不考虑 清空按钮
			// IsshowSet: {
			//     type: Boolean,
			//     default: true
			// },
			//是否只读 只读的也不显示
			Readonly: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				favcolors: ['#F56C6C', '#F56C6C', '#F56C6C'],
				iconClasses: ['el-icon-aixin', 'el-icon-aixin', 'el-icon-aixin'],
				rates: this.Val,
				show: true
			}
		},
		watch:{
			Val(val1,val2)
			{		
				this.rates=this.Val
			}
		},
		methods: {
			reload() {
				this.show = false;
				this.$nextTick(function() {
					this.show = true;
				})
			},
			handleChange(val) {
				this.$nextTick(() => {
					this.rates = val;
					this.$emit("OnChange", val);
					//console.log("当前值：" + this.rates);
				})
			}
		},
		computed: {
			ShowVal() {
				if (this.rates <= 0) return 0;
				return this.rates;
			},
		},

	}
</script>

<style scoped>
	.favratescss {
		flex: 1;
		display: flex;
		align-items:flex-start;
		justify-content: center;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-rate {
		margin-left: 20px;
		float: left;
	}

	.color1 {
		color: #409EFF;
	}

	.color2 {
		color: #E6A23C;
	}

	.color3 {
		color: #F56C6C;
	}

	.color4 {
		color: #67C23A;
	}

	i {
		cursor: pointer;
	}

	.el-icon-s-release,
	.el-icon-refresh {
		margin-left: 5px;
		margin-right: 10px;
		font-size: 17px;
	}

	.el-icon-s-release:hover,
	.el-icon-refresh:hover {
		color: #F56C6C;
	}
</style>