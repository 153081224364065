<!---传一个录取分列表 显示分数的信息-->
<template v-if="ScoreInfo != undefined">
    <el-row v-for="item in GetYearScore" :key="item.WLType + item.Year" style="justify-content: center;">
        <span v-if="GetYearScore.length > 1">{{ item.WLType + ":" }}</span>
        <span :title="UseYear + '最低排名'" style="color: #E6A23C;">{{ item.LOrder }}</span>-
        <span :title="UseYear + '最低分数'">{{ item.LScore }}</span>-
        <span :title="UseYear + '计划人数'" style="color: #409EFF;">{{ item.PlanCount }}</span>
    </el-row>

</template>

<script>
export default {
    props: {
        //录取分对象[{Year,WLType,LScore,LOrder,PlanCount}]
        ScoreInfo: {
            type: Object,
            default: [],
            required: true
        },
        //我的分数--暂时没用
        MyScore: {
            type: Number,
            default: 0,
            required: false
        },
        //我的排名
        MyOrder: {
            type: Number,
            default: 0,
            required: false
        },
        //显示的年份 没传递时显示第一条的年份
        Year: {
            type: Number,
            default: 0,
            required: false
        }
    },
    data() {
        return {

        }
    },
    computed: {
        GetYearScore() {
            if (this.ScoreInfo == undefined || this.ScoreInfo.length == 0) return [];
            let obj = [];
            if (this.Year != 0)
                obj = this.ScoreInfo.filter(c => c.Year == this.Year && (c.LOrder || c.Score || c.PlanCount));
            else {
                //this.Year = this.ScoreInfo[0].Year
                let tmpyear = this.ScoreInfo[0].Year;
                obj = this.ScoreInfo.filter(c => c.Year == tmpyear && (c.LOrder || c.Score || c.PlanCount));
            }
            return obj;
        },
        //使用的年费
        UseYear() {
            if (this.Year == 0) {

                if (this.ScoreInfo != undefined && this.ScoreInfo.length != 0) {
                    return this.ScoreInfo[0].Year
                }
            }
            return this.Year;
        }
    },
    created() {
        //console.log("========"+this.Year+"==>"+this.ScoreInfo)
    }
}
</script>

<style scoped="scope" lang="less">
</style>
