<template>
	<div class="filter">
		<el-row class="type" >
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">专业层次</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<span class="cj_hover" v-for="item in levels" @click="filterlevel(item.Code)"
						:class="item.Code==code?'cj_color':''">{{item.Name}}</span>
				</div>
			</el-col>
		</el-row>
		<el-row class="type"  v-if="isShowSubType">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">学位类型</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<span class="cj_hover" v-for="item in subTypes" @click="clickSubType(item.key)"
						:class="item.key==subType?'cj_color':''">{{item.name}}</span>
				</div>
			</el-col>
		</el-row>
		<el-row class="type">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">{{topName}}</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<span class="cj_hover" :class="major=='全部'?'cj_color':''" @click="filtermajor('全部')">全部</span>
					<span v-for="item in MajorIDs" :class="major==item.Name?'cj_hover cj_color':'cj_hover'"
						style="margin-right: 10px;margin-bottom: 10px;"
						@click="filtermajor(item.Name,item.Code)">{{item.Name}}</span>
				</div>
			</el-col>
		</el-row>
		<el-row class="type" v-if="listOpenYears.length > 0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">开设年份</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<el-select clearable filterable v-model="openYear" placeholder="开设年份" style="width: 150px;" @change="openYearChg">
						<el-option v-for="item in listOpenYears" :label="item" :value="item"></el-option>
					</el-select>
				</div>
			</el-col>
		</el-row>
		<el-row class="type">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">专业定位</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<el-input placeholder="请输入内容" v-model="keyword" style="width: 300px;" clearable
						 @keyup.enter="selectMajor">
					</el-input>
					<el-button type="primary" style="margin-left: 10px;" @click="selectMajor">搜索</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				code: '',
				levels: [],
				MajorIDs: [],
				major: '全部',
				majorsFilter: [],
				keyword: '',
				listOpenYears:[],
				openYear: '',
				id: '',
				menlei:'',
				subTypes: [{key:'',name:'全部'},{key:'学术型',name:'学术型'},{key:'专业型',name:'专业型'}],
				subType:'',
			}
		},
		methods: {
			//监听回车按键
			keyDown(e){
				if(e.keyCode === 13 || e.keyCode === 100){
					this.selectMajor();
				}
			},
			filterlevel(code) {
				this.major = "全部";
				this.code = code;
				this.subType = '';
				this.keyword = '';
				this.getOpenYearList();
				this.getmajors();
			},
			filtermajor(name, ID) {
				this.major = name;
				this.menlei = ID;
				this.keyword = '';
				if (name == "全部") {
					this.$emit("getmajor", this.MajorIDs);
					return;
				}
				this.majorsFilter = [];
				this.MajorIDs.filter(x => {
					if (ID == x.Code) {
						this.majorsFilter.push(x);
					}
				})
				this.$emit("getmajor", this.majorsFilter);
			},
			reset() {
				this.$emit("getMajorByKeyword", this.code, '', this.menlei, this.openYear);
			},
			selectMajor() {
				this.$emit("getMajorByKeyword", this.code, this.keyword, this.menlei, this.openYear);
			},
			openYearChg(){
				this.$emit("getMajorByKeyword", this.code, this.keyword, this.menlei, this.openYear);
			},
			getmajors() {
				this.$http.get("/major/GetAllMajors", {
					Level: this.code,
					subtype: this.subType,
					IsStandard: 1
				}, true).then((res) => {
					if (res.code == 1) {
						this.MajorIDs = res.data;
						//console.log('res.data',res.data);
						if (this.id != undefined) {
							this.filtermajor(this.major, this.id);
						} else {
							this.$emit("getmajor", res.data);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			setLevel(level) {
				this.code = level;
				this.getmajors();
			},
			clickSubType(subType)
			{
				this.subType=subType
				this.getmajors();
			},
			getOpenYearList(){
				this.$http.get("/major/GetMajorOpenYearList", {
					level:  this.code
				}, true).then((res) => {
					if (res.code == 1) {
						this.listOpenYears = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.$http.get("/dictionary/GetEduLevel", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.levels = res.data;
					// this.code = this.levels[0].Code;
					this.getOpenYearList();
					this.getmajors();					
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		mounted(){
			//window.addEventListener('keydown',this.keyDown);
		},
		computed:{
			topName()
			{
				if(this.isShowSubType)
					return '学科门类'
				else
					return '专业门类'
			},
			isShowSubType()
			{
				if(this.code=='109' || this.code=='110')
					return true
				else
					return false
			}
		}
		
	}
</script>

<style scoped="scoped">
	.filter {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 30px;
	}

	.type {
		width: 100%;
		margin-bottom: 10px;
		height: auto;
		position: relative;
	}

	.leftCol {
		text-align: right;
	}
	
	.rightCol {
		border-bottom: 1px solid #D4D7DE;
	}
	
	.title {
		margin-top: 10px;
		margin-right: 10px;
		color: #000000;
	}

	.content {
		padding: 10px;
		padding-left: 20px;
	}

	.content span {
		font-size: 14px;
		display: inline-block;
		height: 25px;
		line-height: 25px;
		padding: 0px 10px;
		border: 1px solid #DCDFE6;
		margin-right: 10px;
		cursor: pointer;
		color: rgb(96, 98, 102);
	}

</style>
