<template>
	<div>
		<div class="remark">
			<!-- 注：处于<span class="light">待审核</span>状态的内容，不会显示到用户端。 -->
			注：<span class="light">审核通过前</span>，不对其他人（除添加人）展示。
			<span v-if="isOnlyMine">解读不合理或与已有解读相似度高，审核人员有权删除。</span>
		</div>
		<div class="filter">
			<el-select v-model="status" placeholder="请选择状态" class="selType" @change="filter" width="80px">
				<el-option key="0" label="全部" :value="0" />
				<el-option v-for="item in statuses" :key="item.Key" :label="item.Value" :value="item.Key" />
			</el-select>
			<el-input v-model="keyWord" placeholder="关键字" @keyup="onKeyup" clearable></el-input>
			<template v-if="!dicID">
				<span>标签：</span>
				<div class="tags">
					<template v-for="item in tags">
						<span class="tag">{{item.Name}}
							<el-icon class="removeTag" @click="removeTag(item)">
								<CircleCloseFilled />
							</el-icon></span>
					</template>
					<el-icon class="addMore" @click="addMoreTag" title="添加标签">
						<Plus />
					</el-icon>
				</div>
			</template>
			<el-button type="primary" style="margin-left:0px;" @click="filter">搜 索</el-button>
			<el-button v-if="isOnlyMine" type="default" style="margin-left:0px;" @click="add">添加内容</el-button>
		</div>
		<el-table :data="list" stripe style="width: 100%;" border>
			<el-table-column type="index" label="序" width="70" />
			<el-table-column prop="Content" align="left" label="内容">
				<template #default="scope">
					<div class="content">
						<span>{{scope.row.Content}}</span>
						<span v-if="scope.row.ZanNum>0||scope.row.ErrNum>0 || scope.row.IsHaveSimilar" class="other">
							<span v-if="scope.row.ZanNum>0" class="zan" title="点击查看点赞人详细信息">
								<img src="/imgs/zan_2.png" />
								<el-link v-if="!isDisabled"
									@click="viewZanList(scope.row)">{{scope.row.ZanNum}}</el-link>
								<span v-else>{{scope.row.ZanNum}}</span>
							</span>
							<span v-if="scope.row.ErrNum>0" class="error">
								<el-icon>
									<Failed />
								</el-icon>
								<el-link v-if="!isDisabled" @click="viewErrList(scope.row)"
									title="上报的错误数">{{scope.row.ErrNum}}</el-link>
								<span v-else>{{scope.row.ErrNum}}</span>
							</span>
							<el-link v-if="scope.row.IsHaveSimilar" type="danger" title="点击查看相似解读"
								@click="viewSimilar(scope.row)">
								<el-icon>
									<Warning />
								</el-icon>
							</el-link>
						</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="Tags" align="left" label="标签">
				<template #default="scope">
					<template v-for="tag in scope.row.Tags">
						<span class="tag">{{tag.Dic.Type==3?tag.Dic.Alias:tag.Dic.Name}}
						</span>
					</template>
				</template>
			</el-table-column>
			<el-table-column prop="LevelName" label="等级" width="100px">
			</el-table-column>
			<el-table-column prop="StatusName" align="center" label="审核状态" width="100">
				<template #default="scope">
					<el-switch @change="ChgStatus(scope.row)" v-model="scope.row.Status"
						style="--el-switch-on-color: #13ce66; --el-switch-off-color: #b1b3b8" :active-value="2"
						:inactive-value="1" :disabled="isOnlyMine || isDisabled" />

				</template>
			</el-table-column>
			<el-table-column v-if="!this.isOnlyMine" prop="AddMngName" label="添加人" width="200px">
			</el-table-column>
			<el-table-column prop="EditDt" label="变动日期" width="200px">
			</el-table-column>
			<el-table-column v-if="!isDisabled" align="center" label="操作" width="180">
				<template #default="scope">
					<el-button type="primary" text @click="edit(scope.row)">修改</el-button>
					<el-button type="danger" text @click="del(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10,15,20,30]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
		</el-pagination>
	</div>
	<el-drawer v-model="isShowDrawer" title="选择标签" size="900">
		<chooseDic :selIDs="selIDs" @afterAdd="afterAdd" @afterDel="afterDel"></chooseDic>
	</el-drawer>
	<el-dialog v-model="isShowEdit" :title="title" width="850px" :close-on-click-modal="true"
		:close-on-press-escape="true">
		<editContent :isAdmin="isAdmin" :id="focID" @afterSaved="afterSaved" @afterCancled="afterCancled"></editContent>
	</el-dialog>
	<el-dialog v-model="isShowZan" title="点赞人列表" width="850px" destroy-on-close>
		<zanlist :contentID="focID"></zanlist>
	</el-dialog>

	<el-dialog v-model="isShowErr" title="上报的错误" width="1200px" destroy-on-close>
		<errorlist :isOnlyMine="false" :contentID="focID" @afterChanged="afterChanged"></errorlist>
	</el-dialog>
	<el-dialog v-model="isShowSimilar" title="相似的解读" width="1000px" destroy-on-close>
		<div v-if="similars && similars.length>0" class="similars">
			<span>
				已有相似内容（<b>{{this.similars.length}}</b>）
			</span>
			<div v-for="(item,index) in similars">
				<span class="seq">{{index+1}}.</span>
				<span>{{item.Content}}</span>
				<span class="similar-val" title="相似度(0~1.0)">{{item.Similar}}</span>
				<span class="person" title="添加人">{{item.AddMngName}}</span>
			</div>
		</div>
	</el-dialog>

</template>
<script>
	import chooseDic from './cp_chooseDic.vue'
	import editContent from "./cp_editContent.vue"
	import zanlist from "./cp_zanlist.vue"
	import errorlist from "./cp_errorlist.vue";
	export default {
		emits: ['afterChanged'],
		components: {
			chooseDic,
			editContent,
			zanlist,
			errorlist
		},
		props: {
			isOnlyMine: {
				Type: Boolean,
				default: true
			},
			mngID: {
				Type: String,
				default: ''
			},
			dicID: {
				Type: Number,
				default: 0
			},
			//0:全部  1:待审核 2:已经审核
			defaultStatus: {
				Type: Number,
				default: 0
			},
			isDisabled: {
				Type: Boolean,
				default: false
			}
		},
		data() {
			return {
				keyWord: '',
				currentPage: 1, // 当前页码
				pageSize: 15, // 每页的数据条数
				totalCount: 0, // 总条数
				sidx: 'editdt',
				sord: 'desc',
				list: [],
				tm: null,
				ids: [],
				status: 0,
				statuses: [],
				tags: [],
				isShowDrawer: false,
				isShowEdit: false,
				focID: 0,
				isShowZan: false,
				isShowErr: false,
				isShowSimilar: false,
				similars: []
			}
		},
		watch: {
			defaultStatus() {
				this.status = this.defaultStatus
			}
		},
		computed: {
			selIDs() {
				let ary = []
				if (this.tags) {
					ary = this.tags.map((item) => {
						return item.ID
					})
				}
				return ary
			},
			title() {
				let val = ''
				if (this.focID > 0)
					val = '编辑内容'
				else
					val = '新增内容'
				return val
			},
			isAdmin() {
				let val = 0
				if (!this.isOnlyMine)
					val = 1
				return val
			}
		},
		created() {
			this.status = this.defaultStatus
			this.getDicStatuses()
			this.getData()
		},
		methods: {
			getDicStatuses() {
				this.$http.get('Tag/GetDicStatuses', {}, true).then(res => {
					if (res.code == 1) {
						this.statuses = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			onKeyup(e) {
				if (e.keyCode == 13)
					this.filter()
			},
			getData() {
				let dicIDs = this.selIDs.join(',')
				if (this.dicID)
					dicIDs += (!dicIDs ? '' : ',') + this.dicID
				let url = ''
				if (this.isOnlyMine)
					url = 'Tag/GetMyContentList'
				else
					url = 'Tag/GetContentList'
				this.$http.get(url, {
					status: this.status,
					keyWord: this.keyWord,
					dicIDs: dicIDs,
					rows: this.pageSize,
					page: this.currentPage,
					sidx: this.sidx,
					sord: this.sord,
					mngID: this.mngID,
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data.list
						this.totalCount = res.data.TotalRows;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			//每页条数改变时触发 选择一页显示多少行
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.$store.commit("set_PageRows", val);
				this.getData();
			},
			//当前页改变时触发 跳转其他页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			filter() {
				this.currentPage = 1;
				this.getData();
			},
			removeTag(dic) {
				let tag = this.tags.find(c => c.ID == dic.ID)
				if (tag == null)
					return
				let idx = this.tags.indexOf(tag)
				this.tags.splice(idx, 1)
			},
			addMoreTag() {
				this.isShowDrawer = true
			},
			afterAdd(dic) {
				let mdl = this.tags.find(c => c.ID == dic.ID)
				if (mdl == null) {
					this.tags.push(dic)
				}
			},
			afterDel(dic) {
				let mdl = this.tags.find(c => c.ID == dic.ID)
				if (mdl != null) {
					let idx = this.tags.indexOf(mdl)
					this.tags.splice(idx, 1)
				}
			},
			getStatusClass(status) {
				let clsName = ''
				switch (status) {
					case 1:
						clsName = 'status-waiting'
						break
					case 2:
						clsName = 'status-passed'
						break
				}
				return clsName
			},
			edit(mdl) {
				this.focID = mdl.ID
				this.isShowEdit = true
			},
			del(mdl) {
				this.$confirm("确认要删除吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.get('Tag/DelContent', {
						id: mdl.ID
					}, true).then(res => {
						if (res.code == 1) {
							this.getData()
							this.$emit('afterChanged')
						} else {
							this.$message.error(res.msg)
						}
					})
				})
			},
			ChgStatus(mdl) {
				this.$http.get('Tag/ChgStatus', {
					id: mdl.ID,
					status: mdl.Status
				}, true).then(res => {
					if (res.code == 1) {
						this.getData()
						this.$emit('afterChanged')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			afterSaved() {
				this.isShowEdit = false
				this.getData()
			},
			afterCancled() {
				this.isShowEdit = false
			},
			add() {
				this.focID = 0
				this.isShowEdit = true
			},
			viewZanList(mdl) {
				this.focID = mdl.ID
				this.isShowZan = true
			},
			viewErrList(mdl) {
				this.focID = mdl.ID
				this.isShowErr = true
			},
			afterChanged() {
				this.$emit('afterChanged')
			},
			viewSimilar(mdl) {
				this.$http.post('Tag/GetSimilarContents', {
					id: mdl.ID,
					content: mdl.Content
				}, true).then(res => {
					if (res.code == 1) {
						this.similars = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
				this.isShowSimilar = true
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.optItem {
		margin: 10px 0px;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.level {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.filter {
		display: flex;
		align-items: center;
		margin: 10px 0px;

		.selType {
			width: 110px;
		}

		>*:nth-child(n+1) {
			margin-right: 10px;
			max-width: 250px;
		}
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	.tags {
		display: flex;
		align-items: center;
		max-width: none !important;
	}

	.tag {
		// background-color: #67C23A;
		// border-radius: 5px;
		// padding: 5px 10px;
		// color: white;
		// position: relative;
		border-radius: 5px;
		padding: 1px 5px;
		position: relative;
		border: 1px solid #67C23A;
	}

	.tag:nth-child(n+1) {
		margin-right: 10px;
	}

	.addMore {
		cursor: pointer;
		margin-left: 0px;
	}

	.removeTag {
		position: absolute;
		right: -9px;
		top: -8px;
		color: red;
		cursor: pointer;
	}

	.status-waiting {
		color: #E6A23C;
	}

	.status-passed {
		color: #67C23A;
	}

	.other {
		position: absolute;
		right: 5px;
		top: 2px;
		margin-bottom: 2px;
		// width:fit-content;
		display: flex;

		>*{
			margin-left: 5px;
		}
	}

	.zan {
		img {
			width: 15px;
			margin-right: 2px;
		}
	}

	.error {
		color: red;
	}

	:deep(.cell .el-button) {
		padding: 5px 5px;
	}

	.content {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.similars {
		margin: 0px;
		padding: 0px 10px;
		color: red;
		font-size: 12px;
		line-height: 1.8;
	}

	.similar-val {
		color: #409EFF;
		margin: 0px 5px;
	}

	.person {
		color: #409EFF;
	}
</style>