<!---传一个院校对象 显示院校的基本标签-->
<template>
	<span class="tags">
		<el-tooltip v-if="schooldata.Department!=null&&schooldata.Department!=''" class="item" effect="dark" content="主管部门" placement="top">
			<span class="tag cj_tag">
				{{schooldata.Department}}
			</span>
		</el-tooltip>
		<span class="tag cj_tag" v-if="schooldata.SchTypeName!=null&&schooldata.SchTypeName!=''">
			{{schooldata.SchTypeName}}
		</span>
		<span :class="'tag cj_tag '+(schooldata.LevelName=='本科'?'gltag':'mbtag')"
			v-if="schooldata.LevelName!=null && schooldata.LevelName!=''">{{schooldata.LevelName}}</span>

		<span :class="'tag cj_tag '+(schooldata.FoundTypeName=='公立'?'gltag':'mbtag')"
			v-if="schooldata.FoundTypeName!=null&&schooldata.FoundTypeName!=''">
			{{schooldata.FoundTypeName}}
		</span>
		<span class="tag cj_tag maintag" v-if="schooldata.YiLiuMajorCount&&schooldata.YiLiuMajorCount>0">双一流</span>
		<span class="tag cj_tag maintag" v-if="schooldata.Is985">985</span>
		<span class="tag cj_tag maintag" v-if="schooldata.Is211">211</span>
		<span class="tag cj_tag maintag" v-if="schooldata.IsXiao211">小211</span>
		<span class="tag cj_tag maintag" title="研究生院" v-if="schooldata.IsGraduateSch">研</span>
		<span class="tag cj_tag maintag" title="高水平学校建设单位（A档）" v-if="schooldata.GaoSchool==1">双高A</span>
		<span class="tag cj_tag maintag" title="高水平学校建设单位（B档）" v-if="schooldata.GaoSchool==2">双高B</span>
		<span class="tag cj_tag maintag" title="高水平学校建设单位（C档）" v-if="schooldata.GaoSchool==3">双高C</span>
			
		<span class="tag cj_tag maintag" title="高水平专业群建设单位（A档）" v-if="schooldata.GaoMajor==1">双高A</span>
		<span class="tag cj_tag maintag" title="高水平专业群建设单位（B档）" v-if="schooldata.GaoMajor==2">双高B</span>
		<span class="tag cj_tag maintag" title="高水平专业群建设单位（C档）" v-if="schooldata.GaoMajor==3">双高C</span>
		
		<span class="tag cj_tag maintag" title="国家示范性高职院校" v-if="schooldata.ShiFan">示范</span>
		<span class="tag cj_tag maintag" title="国家骨干高职院校" v-if="schooldata.GuGan">骨干</span>
		<span class="tag cj_tag maintag" title="高职创新计划(优质校)" v-if="schooldata.YouZhi">优质</span>
		<span class="tag cj_tag maintag" title="1+X证书制度试点" v-if="schooldata.ZhengShu1X">1+X</span>
		<span class="tag cj_tag maintag" title="现代学徒制试点" v-if="schooldata.XueTu">学徒</span>
		<span class="tag cj_tag maintag" title="定向培养士官试点" v-if="schooldata.ShiGuan">士官</span>
		<span class="tag cj_tag maintag" :title="'保研率:'+schooldata.BaoYan" v-if="schooldata.BaoYan">保研</span>
	</span>
	<!-- <div class="schooladdress">{{ schooldata.ShengShi }}</div> -->
</template>

<script>
	export default {
		props: ['schooldata'],
		data() {
			return {
				baoyantitle:"保研率 "
			}
		},
		created() {
			//console.log(this.schooldata);
		}
	}
</script>

<style scoped="scope" lang="less">
	.tags {
		display: inline-block;
	}

	.tag {
		display: inline-block;
		height: 16px;
		line-height: 16px;
		font-size: 12px;
		border-radius: 2px;
		border: 1px solid #DCDFE6;
		color: white;
		background-color: #E6A23C;
		margin-right: 5px;
		padding: 0px 2px;
	}

	.maintag {
		background-color: #67C23A;
		color: white;
	}

	.gltag {
		background-color: #409EFF;
		color: white;
	}

	.mbtag {
		background-color: #F56C6C;
		color: white;
	}
	.schooladdress{
		color: #999999;
		padding-top: 10px;
	}
</style>
