<template>
	<div class="item">
		<span class="name">{{name}}</span>
		<span class="num">{{num}}</span>
	</div>
</template>
<script>
	import api from './api.js'
	export default {
		emits: [],
		props: {
			type: {
				type: Number,
				default: 0
			},
			fangAnID: {
				type: String,
				default: ''
			},
			areaID: {
				type: String,
				default: ''
			},
			name: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				list: []
			}
		},
		watch: {},
		computed: {
			num() {
				let ary = api.getStoreCompareDetails(this.type)
				return ary.length
			}
		},
		created() {
			this.GetLogSimple()
		},
		methods: {
			GetLogSimple() {
				this.$http.get('Compare/GetLogSimple', {
					type: this.type,
					fangAnID: this.fangAnID,
					areaID: this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.list = res.data.Details
						this.synStoreDetails(this.list)
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			synStoreDetails(details) {
				api.ClearStoreCompareDetails(this.type)
				for (var i = 0; i < details.length; i++) {
					let detail = details[i];
					api.SynStoreCompareDetail(this.type, detail.RefID, true)
				}
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.item
	{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.name {
		writing-mode: vertical-lr;
		font-size: 14px;
	}

	.num {
		writing-mode: horizontal-tb;
		font-size: 14px;
	}
</style>