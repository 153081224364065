<template>
	<div>
		<div class="filter">
			<el-select v-model="isDealed" placeholder="请选择状态" class="selType" @change="filter" width="80px">
				<el-option key="-1" label="全部" :value="-1" />
				<el-option key="0" label="未处理" :value="0" />
				<el-option key="1" label="已处理" :value="1" />
			</el-select>
			<el-input v-model="keyWord" placeholder="关键字" @keyup="onKeyup" clearable></el-input>
			<el-button type="primary" style="margin-left:0px;" @click="filter">搜 索</el-button>
		</div>
		<el-table :data="list" stripe style="width: 100%;" border>
			<el-table-column type="index" label="序" width="70" />
			<el-table-column prop="Content" align="left" label="错误描述">
				<template #default="scope">
					<span>{{scope.row.Error}}</span>
				</template>
			</el-table-column>
			<el-table-column v-if="!this.isOnlyMine || (this.isOnlyMine && this.isToMe)" prop="AddMngName" label="上报人"
				width="120px">
			</el-table-column>
			<el-table-column prop="TagContent.Content" align="left" label="相关解读">
				<template #default="scope">
					<el-link v-if="scope.row.TagContent"
						@click="viewContent(scope.row.ContentID)">{{scope.row.TagContent.Content}}</el-link>
				</template>
			</el-table-column>
			<el-table-column v-if="!this.isOnlyMine || (this.isOnlyMine && !this.isToMe)" prop="TagContent.AddMngName"
				label="解读人" width="120px">
			</el-table-column>
			<el-table-column prop="AddDt" label="添加日期" width="200px">
			</el-table-column>
			<el-table-column align="center" label="操作" width="180">
				<template #default="scope">
					<template v-if="isToMe || !isOnlyMine">
						<el-button v-if="scope.row.IsDealed==0" type="primary" text @click="ChgStatus(scope.row,1)"
							title="">设为已处理</el-button>
						<el-button v-else type="plain" text @click="ChgStatus(scope.row,0)" title="">设为未处理</el-button>
						<el-button v-if="!isOnlyMine" type="danger" text @click="del(scope.row)">删除</el-button>
					</template>
					<template v-else>
						<span v-if="scope.row.IsDealed==0" style="color: red;">未处理</span>
						<span v-else style="color: green;">已处理</span>
						<el-button type="danger" text @click="del(scope.row)">删除</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10,15,20,30]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
		</el-pagination>
	</div>
	<el-drawer v-model="isShowDrawer" title="选择标签" size="900">
		<chooseDic :selIDs="selIDs" @afterAdd="afterAdd" @afterDel="afterDel"></chooseDic>
	</el-drawer>
	<el-dialog v-model="isShowEdit" :title="title" width="850px" :close-on-click-modal="true"
		:close-on-press-escape="true">
		<editContent :id="focID" :isDisabled="isOnlyMine && !isToMe" @afterSaved="afterSaved"
			@afterCancled="afterCancled"></editContent>
	</el-dialog>
</template>
<script>
	import editContent from "./cp_editContent.vue";
	export default {
		emits: ['afterChanged'],
		components: {
			editContent
		},
		props: {
			isOnlyMine: {
				Type: Boolean,
				default: true
			},
			isToMe: {
				Type: Boolean,
				default: false
			},
			contentID: {
				Type: Number,
				default: 0
			},
			// -1 全部;0 未处理;1 已处理;
			errorStatus:{
				Type: Number,
				default: -1
			},
			
		},
		data() {
			return {
				keyWord: '',
				currentPage: 1, // 当前页码
				pageSize: 15, // 每页的数据条数
				totalCount: 0, // 总条数
				sidx: 'adddt',
				sord: 'desc',
				list: [],
				ids: [],
				isDealed: -1,
				isShowEdit: false,
				focID: 0
			}
		},
		watch: {
			errorStatus()
			{
				this.isDealed=this.errorStatus
			}
		},
		computed: {
			title() {
				let val = ''
				if (this.focID > 0)
					val = '编辑内容'
				else
					val = '新增内容'
				return val
			}
		},
		created() {
			this.isDealed=this.errorStatus
			this.filter()
		},
		methods: {
			onKeyup(e) {
				if (e.keyCode == 13)
					this.filter()
			},
			getData() {
				let url = ''
				if (this.isOnlyMine)
					url = 'Tag/GetMyErrorList'
				else
					url = 'Tag/GetErrorList'
				this.$http.get(url, {
					isDealed: this.isDealed,
					keyWord: this.keyWord,
					rows: this.pageSize,
					page: this.currentPage,
					sidx: this.sidx,
					sord: this.sord,
					isToMe: this.isToMe ? 1 : 0,
					contentID: this.contentID
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data.list
						this.totalCount = res.data.TotalRows;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			//每页条数改变时触发 选择一页显示多少行
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.$store.commit("set_PageRows", val);
				this.getData();
			},
			//当前页改变时触发 跳转其他页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			filter() {
				this.currentPage = 1;
				this.getData();
			},
			viewContent(contentID) {
				this.focID = contentID
				this.isShowEdit = true
			},
			del(mdl) {
				this.$confirm("确认要删除吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.get('Tag/DelError', {
						id: mdl.ID
					}, true).then(res => {
						if (res.code == 1) {
							this.getData()
							this.$emit('afterChanged')
						} else {
							this.$message.error(res.msg)
						}
					})
				})
			},
			ChgStatus(mdl, isDealed) {
				this.$http.get('Tag/ChgErrorStatus', {
					id: mdl.ID,
					isDealed: isDealed
				}, true).then(res => {
					if (res.code == 1) {
						this.getData()
						this.$emit('afterChanged')
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			afterSaved() {
				this.isShowEdit = false
				this.getData()
			},
			afterCancled() {
				this.isShowEdit = false
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.optItem {
		margin: 10px 0px;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.level {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.filter {
		display: flex;
		align-items: center;
		margin: 10px 0px;

		.selType {
			width: 110px;
		}

		>*:nth-child(n+1) {
			margin-right: 10px;
			max-width: 250px;
		}
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	.tags {
		display: flex;
		align-items: center;
		max-width: none !important;
	}

	.tag {
		// background-color: #67C23A;
		// border-radius: 5px;
		// padding: 5px 10px;
		// color: white;
		// position: relative;
		border-radius: 5px;
		padding: 1px 5px;
		position: relative;
		border: 1px solid #67C23A;
	}

	.tag:nth-child(n+1) {
		margin-right: 10px;
	}

	.addMore {
		cursor: pointer;
		margin-left: 0px;
	}

	.removeTag {
		position: absolute;
		right: -9px;
		top: -8px;
		color: red;
		cursor: pointer;
	}

	.status-waiting {
		color: #E6A23C;
	}

	.status-passed {
		color: #67C23A;
	}

	.zan {
		position: absolute;
		right: 5px;
		top: 0px;

		img {
			width: 10px;
			margin-right: 2px;
		}
	}

	:deep(.cell .el-button) {
		padding: 5px 5px;
	}
</style>