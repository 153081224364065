<template>
	<div style="padding:0 60px 0 80px;">
		<div style="margin-top: 180px; line-height: 40px; color: #f00; font-weight: bold; font-size: 18px;">
			你有赠送的福利待领取，数量有限，先到先得！
		</div>
		<div class="btnArea">
			<el-button type="info" @click="NotGetGift">暂不领取</el-button>
			<el-button type="primary" @click="GetGift">我要领取</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			
		},
		data() {
			return {
				
			}
		},
		methods: {
			//暂不领取
			NotGetGift(){
				this.$emit("onNotGetGift");
			},
			//领取福利
			GetGift(){
				this.$emit("onGetGift");
			},
		},
		created() {
			
		}
	}
</script>

<style>
	.btnArea{
		margin-top: 60px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
</style>
