<template>
	<div class="root">
		<el-row :gutter="30">
			<el-col :span="12">
				<div class="introduce">
					以下热门省份，是根据<span class="light"> {{AreaName}} </span>报考大数据统计得到,<span class="light"> 不是已保存的倾向
					</span>。可以辅助你快速设置喜欢的地区。
				</div>
				<div ref="map" id="map" />
			</el-col>
			<el-col :span="12">
				<div class="total">
					<div>
						极喜欢<span class="light">{{favTotal(3)}}个</span>
					</div>
					<div>
						很喜欢<span class="light">{{favTotal(2)}}个</span>
					</div>
					<div>
						喜欢<span class="light">{{favTotal(1)}}个</span>
					</div>
					<div>
						不考虑<span class="light">{{favTotal(-1)}}个</span>
					</div>
				</div>
				<div ref="items" class="items">
					<el-collapse style="padding: 5px;" v-model="activeHot">
						<template v-for="area in defaultAreas">
							<el-collapse-item :id="area.name" :name="area.id" :class="area.id==focID?'foc':''"
								class="item" style="position: relative;" @mouseover="itemMouseOver(area)"
								@mouseout="itemMouseOut(area)">
								<template #title>
									<el-badge style="margin-top:8px;" :value="getSettedCount(area.cities)"
										:hidden="isHidden(area.cities)" class="item">
										<span style="font-size: 16px;margin-left: 5px;">{{area.name}}</span>
									</el-badge>
									<el-icon class="check-icon el-icon-error remove" @click.stop="remove(area)"
										title="移除,对该地区的不做设置">
										<CircleClose />
									</el-icon>
								</template>
								<div class="cities">
									<template v-for="city in area.cities">
										<div class="city">
											<span>{{city.CityName}}</span>
											<favrate :Readonly="false" :Val="city.FavValue"
												@OnChange="(val)=>{onChange(val,city,area)}">
											</favrate>
										</div>
									</template>
									<div v-if="!area.isLoaded" class="city">
										<el-link @click="loadMoreCities(area)">更多></el-link>
									</div>
								</div>
							</el-collapse-item>
						</template>
					</el-collapse>
				</div>
				<div class="optBar" v-if="total>0">
					<el-radio-group v-if="isShowOptType" v-model="optType">
						<el-radio :label="1" title="如果和【已保存】有重合,则以当前设置为准">已保存的不做调整</el-radio>
						<el-radio :label="2" title="保存前会清空之前的设置">已保存的全部清除</el-radio>
					</el-radio-group>
					<el-button type="primary" @click="save">保 存({{total}})</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import '@/util/china.js'
	export default {
		emits: ["afterSaved"],
		props: {
			isShowOptType: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				AreaName: '',
				echart1: null,
				hotAreas: [],
				activeHot: [],
				focID: '',
				selectedName: '',
				optType: 1
			}
		},
		computed: {
			total() {
				var num = this.favTotal(3) + this.favTotal(2) + this.favTotal(1) + this.favTotal(-1);
				return num
			},
			defaultAreas() {
				let ary = this.hotAreas.filter(c => c.isDefault == true)
				return ary
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.$refs.map.style.height = this.$refs.map.offsetWidth + 'px'

			})

			this.AreaName = this.$store.getters.getUserInfo().AreaName
			this.$http.get('/StuArchive/GetDefaultFavAreas', {}, true).then((res) => {
				if (res.code == 1) {
					this.hotAreas = res.data
					this.activeHot = this.hotAreas.map((item) => {
						if (item.value == -1 || item.id == '11' || item.id == '12' || item.id == '31' ||
							item.id == '50')
							item.isLoaded = true
						return item.id
					})
					this.init(res.data)

				} else
					this.$message.error(res.msg)
			})
		},
		beforeUnmount() {
			if (this.echart1 != null) {
				echarts.dispose(this.echart1);
			}
		},
		created() {},
		methods: {
			itemMouseOver(area) {
				this.selProvince(area.name)
			},
			itemMouseOut(area) {
				this.unProvince(area.name)
			},

			remove(area) {
				var idx = this.hotAreas.indexOf(area)
				if (idx > -1) {
					this.$confirm("确定要移除“" + area.name + "”吗?", "警告", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						area.cities = []
						area.isDefault = false
						//this.hotAreas.splice(idx, 1)
						this.init(this.hotAreas)
					})
				}
			},
			favTotal(val) {
				var num = 0
				this.hotAreas.forEach((item, idx) => {
					var ary = item.cities.filter(c => c.FavValue == val)
					num += ary.length
				})
				return num
			},
			getSettedCount(cities) {
				var iNum = 0
				if (cities) {
					var ary = cities.filter(c => c.FavValue != 0)
					iNum = ary.length
				}
				return iNum
			},
			isHidden(cities) {
				var iNum = this.getSettedCount(cities)
				if (iNum > 0)
					return false
				else
					return true
			},
			onChange(val, city, area) {
				city.FavValue = val
				this.calcVale(area)
				this.init(this.hotAreas)
			},
			selProvince(name) {
				// console.log(name);				
				this.echart1.dispatchAction({
					type: "highlight",
					// 可选，系列 index，可以是一个数组指定多个系列
					seriesIndex: 0,
					// 可选，系列名称，可以是一个数组指定多个系列
					// seriesName: string|Array,
					// 数据的 index，如果不指定也可以通过 name 属性根据名称指定数据
					// dataIndex: 26,
					// 可选，数据名称，在有 dataIndex 的时候忽略
					name, //省份的名称（不含“省”）
				});
				// alert(26)
				this.selectedName = name
			},
			unProvince(name) {
				// console.log(name);				
				this.echart1.dispatchAction({
					type: "downplay",
					// 可选，系列 index，可以是一个数组指定多个系列
					seriesIndex: 0,
					// 可选，系列名称，可以是一个数组指定多个系列
					// seriesName: string|Array,
					// 数据的 index，如果不指定也可以通过 name 属性根据名称指定数据
					// dataIndex: 26,
					// 可选，数据名称，在有 dataIndex 的时候忽略
					name, //省份的名称（不含“省”）
				});
				// alert(26)
			},
			init(data) {
				if (this.echart1 != null) {
					echarts.dispose(this.echart1);
					this.echart1 = null
				}
				const selectedBorderColor = "#f7ba2a"; //被选中地图省份边框颜色
				// const selectedAreaColor = ["#1D99F5", "#1D99F5"]; //被选中地图省份填充色（径向渐变：内，外）
				const selectedAreaColor = ["#f7ba2a", "#EEEEEE"]; //被选中地图省份填充色（径向渐变：内，外）

				// 基于准备好的dom，初始化echarts实例
				this.echart1 = echarts.init(document.getElementById("map"));
				window.onresize = echarts.resize; // 窗口或框架被调整大小时执行echart.resize
				this.echart1.setOption({
					tooltip: {
						show: false,
						trigger: 'item',
						formatter: function(e, t, n) {
							return e.value == 'NaN' ? e.name + "：" + '0' : e.name + "：" + e.value
						}
					},
					legend: {
						orient: 'vertical',
						left: 'left',
					},
					visualMap: {
						min: 0,
						max: 3,
						left: 10,
						bottom: 10,
						showLabel: !0,
						text: ["高", "低"],
						textStyle: {
							color: "rgba(0,0,0,0.7)",
						},
						show: !0
					},
					toolbox: {
						show: false,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							dataView: {
								readOnly: false
							},
							restore: {},
							saveAsImage: {}
						},
					},
					geo: {
						map: "china",
						roam: !1,
						scaleLimit: {
							min: 1,
							max: 2
						},
						zoom: 1.23,
						// top: 120,
						label: {
							normal: {
								show: 1,
								fontSize: "14",
								color: "rgba(0,0,0,0.7)"
							}
						},
						itemStyle: {
							normal: {
								borderColor: "rgba(0, 0, 0, 0.2)"
							},
							emphasis: {
								areaColor: "#f7ba2a",
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								borderWidth: 0
							}
						},
						// 被选中状态下的地图省份样式
						select: {
							label: {
								show: false, //1.国内业务布局：地图上省份的文字 最好不显示 和设计稿一致
								color: "rgba(f,f,f,0.7)",
								textBorderColor: selectedBorderColor,
								textBorderWidth: 1,
							},
							itemStyle: {
								// areaColor: areaColor,
								// 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
								areaColor: {
									type: "radial",
									x: 0.5,
									y: 0.5,
									r: 1.5,
									colorStops: [{
											offset: 0,
											color: selectedAreaColor[0], // 0% 处的颜色
										},
										{
											offset: 1,
											color: selectedAreaColor[1], // 100% 处的颜色
										},
									],
									global: false, // 缺省为 false
								},
								borderColor: selectedBorderColor,
								borderWidth: 2,
							},
						},
					},
					series: [{
						type: 'map',
						mapType: 'china',
						roam: false,
						geoIndex: 0,
						label: {
							normal: {
								show: true
							},
							emphasis: {
								show: true
							}
						},
						data: data
					}],

					dataRange: {
						min: -1,
						max: 3,
						x: 'left',
						y: 'bottom',
						text: ['高', '低'], // 文本，默认为数值文本
						calculable: true,
						inRange: {
							color: ['#EEEEEE', '#ff0000'],
							// symbolSize: [10, 100]
						}
					},
				})
				var _this = this
				this.echart1.on('click', this.chartClick)
				this.echart1.on('mouseover', this.chartMouseOver)
				this.echart1.on('mouseout', this.chartMouseOut)
				//
			},
			chartClick(params) {
				console.log(params)
				if (params.componentType == 'series') {
					{
						this.selectedName = name
						if (params.data == null || params.data.isDefault == false)
							this.addArea(params.name)
						else {
							var offsetTop = document.getElementById(params.name).offsetTop - 100
							this.$refs.items.scrollTop = offsetTop
						}
					}
				}
			},
			chartMouseOver(params) {
				if (params.componentType == 'series') {
					{
						var area = params.data
						if (area != null && area.isDefault == true) {
							this.focID = area.id
						}
					}
				}
			},
			chartMouseOut(params) {
				if (params.componentType == 'series') {
					{
						var area = params.data
						this.focID = ''
						this.unProvince(this.selProvince)
					}
				}
			},
			addArea(name) {
				if (!name) return
				this.$http.get('/StuArchive/GetArea', {
					name: name
				}, true).then((res) => {
					if (res.code == 1) {
						res.data.isLoaded = true
						//this.hotAreas.splice(0, 0, res.data)
						var area = this.hotAreas.find(c => c.name == name)
						area.FavValue = 0
						area.cities = res.data.cities
						area.isLoaded = true
						area.isDefault = true
						let idx = this.hotAreas.indexOf(area)
						this.hotAreas.splice(idx, 1)
						this.hotAreas.splice(0, 0, area)


						this.init(this.hotAreas)
						this.activeHot.push(res.data.id)
						this.focID = res.data.id
						this.$refs.items.scrollTop = 0
					} else
						this.$message.error(res.msg)
				})
			},
			loadMoreCities(area) {
				this.$http.get('/StuArchive/GetAllCities', {
					id: area.id
				}, true).then((res) => {
					if (res.code == 1) {
						res.data.forEach((item, idx) => {
							var elm = area.cities.find((obj, i) => {
								return obj.CityID == item.CityID ? true : false
							})
							if (!elm) {
								area.cities.push(item)
							}
						})
						area.isLoaded = true
					} else
						this.$message.error(res.msg)
				})
			},
			calcVale(area) {
				var total = 0
				var num = 0
				area.cities.forEach((item, idx) => {
					if (item.FavValue != 0) {
						total += item.FavValue
						num++
					}
				})
				if (num > 0) {
					area.value = total * 1.0 / num;
				}
			},
			save() {
				this.$confirm("确定执行保存吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					var cities = []
					this.hotAreas.forEach((area, i) => {
						area.cities.forEach((city, k) => {
							if (city.FavValue != 0)
								cities.push(city)
						})
					})

					this.$http.post('/StuArchive/BatchSaveCityFav', {
						OptType: this.optType,
						Cities: cities
					}, true).then((res) => {
						if (res.code == 1) {
							this.$message.success('保存成功')
							this.$emit('afterSaved')
						} else
							this.$message.error(res.msg)
					})

				})
			}
		}

	}
</script>
<style lang="less" scoped>
	.root {
		background-color: white;
		padding: 10px;
		font-size: 16px;
		height: 100%;
	}

	.items {
		height: calc(100vh - 250px);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.item :deep(.el-collapse-item__content) {
		padding-bottom: 5px;
	}

	.total {
		padding-top: 5px;
		display: flex;
		height: 40px;
		font-weight: 600;

		.light {
			font-weight: 600;
			color: #f56c6c;
			margin-left: 10px;
		}

		div {
			margin-right: 20px;
		}
	}

	.introduce {
		// background-color: #ecf5ff;
		margin: 0px 0px;
		padding: 5px;
		font-size: 16px;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.cities {
		display: flex;
		flex-wrap: wrap;
		padding: 5px;
	}

	.city {
		display: flex;
		margin: 2px;
	}

	.item:hover .remove {
		display: block;
	}

	.remove {
		display: none;
		margin-left: 12px;
		// margin-top: 5px;
		color: red;
		font-size: 18px;
	}

	.root :deep(.el-badge__content.is-fixed) {
		top: 9px;
		// padding-left: 15px;
		right: calc(1px + var(--el-badge-size)/ 2 - 5px);
	}

	.foc,
	.item:hover {
		border: 1px dashed skyblue;
	}

	.foc,
	.foc :deep(.el-collapse-item__header),
	.foc :deep(.el-collapse-item__wrap) {}

	.optBar {
		display: flex;
		justify-content: right;
		padding: 20px 20px 10px 20px;

		>* {
			margin-left: 20px;
		}
	}
</style>