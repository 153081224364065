import {
	ElMessage
} from 'element-plus';
import http from './http.js'
import ext from '@/../src/api/apiExt.js'
function showSucessMessage(msg, dur, callback, res) {
	vue.$message({
		showClose: true,
		position: 'bottom',
		duration: dur,
		message: msg,
		type: 'success',
		onClose: function() {
			if (callback != undefined && typeof(callback) != undefined) {
				callback(res);
			}
		}
	});
}

function showErrorMessage(msg, dur, callback, res) {
	vue.$message({
		showClose: true,
		position: 'bottom',
		duration: dur,
		message: msg,
		type: 'error',
		onClose: function() {
			if (callback != undefined && typeof(callback) != undefined) {
				callback(res);
			}
		}
	});
}


export default Object.assign({
	// **************业务接口api封装开始******************
	showSucessMessage(msg, dur) {
		showSucessMessage(msg, dur);
	},
	showErrorMessage(msg, dur) {
		showErrorMessage(msg, dur);
	},	
}, ext)