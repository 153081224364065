import {
	ElMessage
} from 'element-plus';
import http from '@/api/http.js'
import store from '@/store/index.js'
export default {
	//追加
	addDetail(logID, refIDs, type, areaID, fangAnID, zhiYuanID, succCallBack) {
		http.get('Compare/AddLogDetail', {
			logID: logID,
			refIDs: refIDs,
			type: type,
			areaID: areaID,
			fangAnID: fangAnID,
			zhiYuanID: zhiYuanID
		}, true).then(res => {
			if (res.code == 1) {
				ElMessage.success("添加成功")
				this.SynStoreCompareDetail(type, refIDs, true)
				if (succCallBack)
					succCallBack(res.data)
			} else {
				ElMessage.error(res.msg)
			}
		})
	},
	//移除
	delDetail(id, refID, type, areaID, fangAnID, zhiYuanID, succCallBack) {
		http.get('Compare/DelLogDetail', {
			id: id,
			refID: refID,
			type: type,
			areaID: areaID,
			fangAnID: fangAnID,
			zhiYuanID: zhiYuanID
		}, true).then(res => {
			if (res.code == 1) {
				ElMessage.success("移除成功")
				this.SynStoreCompareDetail(type, refID, false)
				if (succCallBack)
					succCallBack(res.data)
			} else {
				ElMessage.error(res.msg)
			}
		})
	},
	//清空
	clear(logID, type, areaID, succCallBack) {
		http.get('Compare/clear', {
			logID: logID,
			areaID: areaID
		}, true).then(res => {
			if (res.code == 1) {
				ElMessage.success("清空成功")
				this.ClearStoreCompareDetails(type)
				if (succCallBack)
					succCallBack(res.data)
			} else {
				ElMessage.error(res.msg)
			}
		})
	},
	isShowGuess(type) {
		let isShow = false
		//添加第一個后弹出
		let details = this.getStoreCompareDetails(type)
		if (details && details.length == 1)
			isShow = true
		return isShow
	},
	//同步store状态
	SynStoreCompareDetail(type, refIDs, isAdd) {
		let ary = refIDs.split(',')
		ary.forEach((item, index) => {
			store.commit("set_CompareDetail", {
				type: type,
				refID: item,
				isAdd: isAdd
			})
		})

	},
	ClearStoreCompareDetails(type) {
		store.commit("clear_CompareDetails", type)
	},

	getStoreCompareDetails(type) {
		let ary = store.getters.getCompareDetails(type)
		return ary
	},

	getCompareDetail(type, refID) {
		let obj = store.getters.getCompareDetail(type, refID)
		return obj
	},
}