<template>
	<div class="guess fade-in" :style="{opacity:opacity}" @mouseenter="mouseenter" @mouseleave="mouseleave"
		@click.stop="">
		<div class="remark">
			<b>是否要比对以下内容？</b>
			<el-icon class="close" @click="closeClick">
				<Close />
			</el-icon>
		</div>
		<div class="groups">
			<template v-for="group in groups">
				<div>
					{{group.Name}}
					<el-link type="primary" @click="batchAdd(group)">批量添加({{getCheckCount(group)}})</el-link>
				</div>
				<ul>
					<template v-for="item in group.Details">
						<li>
							<template v-if="item.IconUrl">
								<el-checkbox v-model="item.IsChecked" label="">
								</el-checkbox>
								<img :src="item.IconUrl" class="icon">
								<span class="text-ellipsis">
									{{item.Name}}
								</span>
							</template>
							<el-checkbox v-else v-model="item.IsChecked" label="" :title="item.Name">
								{{item.Name}}
							</el-checkbox>
						</li>
					</template>
				</ul>
			</template>
		</div>
	</div>
</template>
<script>
	import api from './api.js'
	export default {
		emits: ['afterAdded,onClose'],
		components: {

		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			logID: {
				type: Number,
				default: 0
			},			
			areaID:{
				type: String,
				default: ''
			},
			stuID:{
				type: String,
				default: ''
			},
			fangAnID: {
				type: String,
				default: ''
			},			
			zhiYuanID: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				groups: [],
				checkList: [],
				opacity: 1,
				tm: null,
				isMouseEnter: false
			}
		},
		watch: {
			groups: {
				handler() {},
				deep: true
			}
		},
		computed: {

		},
		created() {
			this.GetGuessGroups()
			setTimeout(() => {
				if (!this.isMouseEnter)
					this.startInterval()
			}, 5000)

		},
		methods: {
			GetGuessGroups() {
				this.$http.get('Compare/GetGuessGroups', {
					logID: this.logID,
					type: this.type,
					stuID:this.stuID,
					areaID:this.areaID,
					fangAnID:this.fangAnID
				}, false).then(res => {
					if (res.code == 1) {
						this.groups = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			getCheckCount(group) {
				let ary = group.Details.filter(c => c.IsChecked == true)
				return ary.length
			},
			batchAdd(group) {
				let refIDs = group.Details.filter(c => c.IsChecked == true).map(c => c.RefID).join()
				api.addDetail(this.logID, refIDs, this.type,this.areaID,this.fangAnID,this.zhiYuanID,data => {
					this.$emit('afterAdded')
				})
			},
			startInterval() {
				this.opacity = 1
				if (this.tm) {
					clearInterval(this.tm)
					this.tm = null
				}
				this.tm = setInterval(() => {
					this.opacity -= 0.020
					if (this.opacity <= 0.1) {
						clearInterval(this.tm)
						this.tm = null
						this.$emit('onClose')
					}
				}, 100)
			},
			mouseenter() {
				clearInterval(this.tm)
				this.tm = null
				this.opacity = 1
				this.isMouseEnter = true
			},
			mouseleave() {
				this.startInterval()
			},
			closeClick() {
				clearInterval(this.tm)
				this.tm = null
				this.$emit('onClose')
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.guess {
		width: 320px;
		padding: 10px;
		border: 1px solid #ddd;
		position: fixed;
		left: 0px;
		top: 120px;
		background-color: white;
		border-radius: 10px;
		z-index: 9999;
		box-shadow: 4px 4px 4px #c6e2ff;
		height: calc(100vh - 200px) !important;
		font-size: 14px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
		position: relative;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.icon {
		width: 50px;
		margin: 10px 0px;
	}

	ul li {
		list-style: none;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.close {
		color: red;
		position: absolute;
		right: 5px;
		top: 15px;
		font-size: 16px;
		cursor: pointer;
	}

	.groups {
		height: calc(100% - 62px);
		overflow-x: hidden;
		overflow-y: auto;
		margin-bottom: 5px;
	}

	/* 定义一个渐隐的动画 */
	@keyframes fadeOut {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* 应用渐隐动画 */
	.fade-in {
		animation: fadeIn 1s ease-in;
		/* 动画时长2秒，缓进缓出，动画完成后元素保持最后状态 */
	}

	/* 滚动条整体部分 */
	::-webkit-scrollbar {
		width: 6px;
		/* 对垂直滚动条有效 */
		height: 6px;
		/* 对水平滚动条有效 */
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 10px;
		/* 让滑块边角圆滑 */
		border: 2px solid transparent;
		/* 可选，给滑块添加边框 */
		background-clip: content-box;
		/* 让边框不被圆角裁切 */
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		/* 轨道的颜色 */
		border-radius: 10px;
		/* 圆角轨道 */
		box-shadow: inset 0 0 5px grey;
		/* 轨道内阴影 */
	}

	/* 滚动条滑块:hover状态 */
	::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	}

	/* 滚动条滑块:active状态 */
	::-webkit-scrollbar-thumb:active {
		background-color: #000000;
	}

	/* 滚动条轨道的边角（如果支持的话） */
	/* ::-webkit-scrollbar-corner {} */

	/* 滚动条按钮（大多数浏览器不支持自定义） */
	/* ::-webkit-scrollbar-button {} */
	:deep(.el-checkbox__label) {
		overflow: hidden;
		text-overflow: ellipsis;
		width: calc(100% - 40px);
	}
</style>