<template>
	<div class="arrow" @click="onClick" :title="title">
		<el-icon v-if="_sort=='asc'" class="lightIcon">
			<CaretLeft />
		</el-icon>
		<el-icon v-else>
			<CaretLeft />
		</el-icon>
		<el-icon v-if="_sort=='desc'" class="lightIcon">
			<CaretRight />
		</el-icon>
		<el-icon v-else>
			<CaretRight />
		</el-icon>
	</div>
</template>
<script>
	export default {
		emits: ['sortChanged'],
		components: {

		},
		props: {
			uniqueFlag: {
				type: String,
				default: ''
			},
			sort: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				_sort: ''
			}
		},
		watch: {
			sort() {
				this._sort = this.sort
				console.log(this._sort)
			}
		},
		computed: {
			title() {
				let val = ''
				if (this._sort == 'asc')
					val = '升序'
				else if (this._sort == 'desc')
					val = '降序'
				else
					val = '默认顺序'
				return val
			}

		},
		created() {
			this._sort = this.sort
			console.log(this._sort)
		},
		methods: {
			onClick() {
				if (this._sort == 'asc') {
					this._sort = 'desc'
				} else if (this._sort == 'desc') {
					this._sort = ''
				} else if (this._sort == '') {
					this._sort = 'asc'
				}
				this.$emit('sortChanged', this.uniqueFlag, this._sort)
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.arrow {
		font-size: 16px;
		cursor: pointer;
		color: #CDD0D6;

		>*:nth-child(2) {
			margin-left: -10px;
		}
	}

	.lightIcon {
		color: #303133 !important;
	}
</style>