<template>
	<div>
		<el-alert title="提示" type="warning" :closable="false">
			<div>
				请先选择一个省份，请注意选择后不能修改。
			</div>
		</el-alert>
		<br/>
		<el-radio-group v-model="areaId">
			<el-radio style="width:75px;margin-bottom: 10px;" size="small" border v-for="(item,n) in allAreas" :label="item.Key" :key="item.Key">{{item.Value}}</el-radio>
		</el-radio-group>
		<br/>
		<br/>
		<div style="text-align: center;">
			<el-button @click="onClose">关闭</el-button>
			<el-button type="success" :disabled="!areaId||areaId==''" @click="SaveCardArea">确定</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			cardId:String
		},
		data() {
			return {
				areaId:'',
				allAreas:[],
			}
		},
		methods: {
			onClose(){
				this.$emit("onSetAreaClose");
			},
			SaveCardArea(){
				this.$http.get("CardManage/SaveCardArea", {
					cardId:this.cardId,
					areaId:this.areaId
				}, true).then((res) => {
					if (res.code == 1) {
						this.$emit("onSetAreaFinished");
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getCardUserArea() {
				this.$http.get("CardManage/getCardUserArea", {
					cardId:this.cardId
				}, true).then((res) => {
					if (res.code == 1) {
						this.allAreas = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
		},
		created() {
			this.$nextTick(function() {
				this.getCardUserArea();
			});
		}
	}
</script>

<style>
</style>
