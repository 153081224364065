<template>
	<div class="sxt-footer">
		<div class="inner-footer">

			<el-row :gutter="20" align="top">
				<el-col :span="18">
					<div style="margin-top: 5px;margin-bottom: 5px;">
						<template v-for="(item,intex) in footerData.FooterNavis">
							<router-link target="_blank"
								:to="item.TargPath+(item.TargPath.indexOf('?')>=0?'&name='+item.Name:'?name='+item.Name)">
								<span class="sxt-copyright">{{item.Name}}</span>
							</router-link>
							<span style="font-size: 12px;"
								v-if="intex<(footerData.FooterNavis.length-1)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</template>
					</div>
					<a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">
						<span class="sxt-copyright">Copyright © &nbsp; {{footerData.Copyright}} &nbsp;
							{{footerData.BeiAn}}</span>
					</a>
					<div class="logo" v-if="footerData.IsShowLogo==1">
						<img src="/imgs/gby.png" style="height:46px;width:auto;" />
						<span>全方位支持</span>
					</div>
				</el-col>
				<el-col :span="6" v-if="footerData.lstLinks&&footerData.lstLinks.length>0">
					<div style="margin-top: 10px;text-align: left;">
						<div style="font-size: 12px;color: #bbb;">友情链接</div>
						<a :href="item.Link" v-for="(item,n) in footerData.lstLinks" class="frend_link">{{item.Name}}</a>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				footerData: {
					FooterNavis: []
				}
			}
		},
		methods: {
			getOrgFooterModel() {
				this.$http.get("OrgExt/GetOrgFooterModel", {
					domain: window.location.host
				}, true).then((res) => {
					if (res.code == 1) {
						if (res.data.Code == 1) {
							this.$nextTick(function() {
								this.$router.push({
									path: '/OrgEnabled'
								});
							})
							return;
						}
						this.footerData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		created() {
			this.getOrgFooterModel();
		}
	}
</script>

<style scoped="scoped">
	.frend_link{
		color: #fff;
		text-decoration: none;
		display: inline-block;
		margin-right: 20px;
		font-size: 12px;
		margin-top: 5px;
	}
	.sxt-footer {
		bottom: 0px;
		left: 0px;
		width: 100%;
		min-height: 60px;
		background-color: #333;
		text-align: center;
		border-top: 1px solid #e0e0e0;
		color: #fff;
		z-index: 100;
		display: flex;
	}

	.inner-footer {
		width: 1200px;
		margin: auto;
		position: relative;
	}

	.sxt-copyright {
		color: #fff !important;
		font-size: 12px;
		display: inline-block;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-items: center;
		justify-content: center;
		margin-right: 30px;
		font-size: 12px;
	}
</style>