<template>
	<div class="root-container">
		<div class="introduce">
			以下为分类整理的<span class="light">常选</span>专业，<span class="light">不是已保存的倾向</span>。
		</div>
		<div class="total">
			<div>
				极喜欢<span class="light">{{favTotal(3)}}个</span>
			</div>
			<div>
				很喜欢<span class="light">{{favTotal(2)}}个</span>
			</div>
			<div>
				喜欢<span class="light">{{favTotal(1)}}个</span>
			</div>
			<div>
				不考虑<span class="light">{{favTotal(-1)}}个</span>
			</div>
		</div>
		<div>
			<el-checkbox v-model="isLi">偏理</el-checkbox>
			<el-checkbox v-model="isWen">偏文</el-checkbox>
		</div>
		<div class="items">
			<el-collapse accordion v-model="activeHot" @change="expandHot">
				<template v-for="item in groups">
					<el-collapse-item v-if="(item.LCount>0 && isLi) || (item.WCount>0 && isWen)" :title="item.Name"
						:name="item.ID">
						<template #title>
							<el-badge style="margin-top:8px;" :value="getSettedCount(item.children)"
								:hidden="isHidden(item.children)" class="item">
								<span style="font-size: 16px;margin-left: 5px;">{{item.Name}}</span>
							</el-badge>
						</template>
						<template v-if="item.children!=undefined">
							<div class="batchSet">
								<span style="float: left;">批量设置：</span>
								<favrate :Readonly="false" @OnChange="(val)=>{groupChanged(val,item)}">
								</favrate>
							</div>
							<div class="majors">
								<template v-for="major in item.children">
									<div v-if="(isLi && major.WLType=='114') || (isWen && major.WLType=='112')"
										class="major">
										<span>{{major.Name}}</span>
										<favrate :Readonly="false" :Val="major.FavValue"
											@OnChange="(val)=>{onChange(val,major)}">
										</favrate>
									</div>
								</template>
							</div>
						</template>
					</el-collapse-item>
				</template>
			</el-collapse>
		</div>
		<div class="optBar" v-if="total>0">
			<el-checkbox v-model="isNotOther" title="请谨慎勾选" @change="otherChged" style="margin-right: 20px;">其它专业列为不考虑</el-checkbox>
			<el-radio-group v-if="isShowOptType && !isNotOther" v-model="optType">
				<el-radio :label="1" title="如何和【已保存】有重合,则以当前设置为准">已保存的不做调整</el-radio>
				<el-radio :label="2" title="保存前会清空之前的设置">已保存的全部清除</el-radio>
			</el-radio-group>
			<el-button type="primary" @click="save">保 存({{total}})</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isShowOptType: {
				type: Boolean,
				default: true
			},
			level: {
				type: String,
				default: '106'
			}
		},
		data() {
			return {
				AreaName: this.$store.getters.getUserInfo().AreaName,
				groups: [],
				activeHot: '',
				optType: 1,
				isNotOther: false,
				isWen: true,
				isLi: true
			}
		},
		computed: {
			total() {
				var num = this.favTotal(3) + this.favTotal(2) + this.favTotal(1) + this.favTotal(-1);
				return num
			}
		},
		methods: {
			otherChged() {
				if (this.isNotOther) {
					this.$alert('请谨慎选择，如果勾选，申请的专家方案可能无法填满', '提醒', {
						confirmButtonText: 'OK',
						callback: () => {
						},
					})
				}
			},
			expandHot(v, bForce) {
				this.groups.forEach(item => {
					if (item.ID == v) {
						if (item.children == undefined || bForce) {
							this.$http.get("/StuArchive/GetHotMajorList2?groupID=" + v + "&level=" + this.level, {},
								true).then((res) => {
								if (res.code == 1) {
									item.children = res.data;
								} else {
									this.$message.error(res.msg);
								}
							});
						}
					}
				})
			},

			getGroups() {
				this.$http.get("/StuArchive/GetHotMajorGroupList?level=" + this.level, {}, true).then((res) => {
					if (res.code == 1) {
						this.groups = res.data;
						this.activeHot = res.data[0].ID
						this.expandHot(this.activeHot, true)
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			groupChanged(val, group) {
				group.children.forEach((item, i) => {
					item.FavValue = val
				})
			},
			favTotal(val) {
				var num = 0
				this.groups.forEach((item, idx) => {
					if (item.children) {
						var ary = item.children.filter(c => c.FavValue == val)
						num += ary.length
					}
				})
				return num
			},
			getSettedCount(majors) {
				var iNum = 0
				if (majors) {
					var ary = majors.filter(c => c.FavValue != 0)
					iNum = ary.length
				}
				return iNum
			},
			isHidden(majors) {
				var iNum = this.getSettedCount(majors)
				if (iNum > 0)
					return false
				else
					return true
			},
			onChange(val, major) {
				major.FavValue = val
			},
			getSettedMajors() {
				var majors = []
				this.groups.forEach((item, i) => {
					if (item.children)
						item.children.forEach((major, k) => {
							if (major.FavValue != 0) {
								var obj = majors.find((a, m) => {
									if (a.Code == major.Code)
										return true
									else
										return false
								})
								if (!obj)
									majors.push(major)
							}
						})
				})
				return majors
			},
			save() {
				var majors = this.getSettedMajors()				
				this.$confirm("确定执行保存吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {					
					this.$http.post('/StuArchive/BatchSaveMajorFav', {
						OptType: this.optType,
						NotOther:this.isNotOther,
						Majors: majors,
					}, true).then((res) => {
						if (res.code == 1) {
							this.$message.success('保存成功')
							this.$emit('afterSaved')
						} else
							this.$message.error(res.msg)
					})

				})
			}
		},
		created() {
			this.getGroups()
		}
	}
</script>

<style scoped="scoped" lang="less">
	.root-container {
		background-color: white;
		padding: 10px;
		font-size: 16px;
		height: 100%;

		:deep(.el-badge__content.is-fixed) {
			top: 9px;
			// padding-left: 15px;
			right: calc(1px + var(--el-badge-size)/ 2 - 5px);
		}
	}

	.introduce {
		margin: 0px 0px;
		padding: 0px 0px 10px 0px;
		font-size: 16px;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.items {
		height: calc(100vh - 250px);
		overflow-x: hidden;
		overflow-y: auto;
	}
	

	.total {
		padding-top: 5px;
		display: flex;
		height: 40px;
		font-weight: 600;

		.light {
			font-weight: 600;
			color: #f56c6c;
			margin-left: 10px;
		}

		div {
			margin-right: 20px;
		}
	}

	.optBar {
		display: flex;
		justify-content: right;
		padding: 20px 20px 10px 20px;

		>* {
			margin-left: 20px;
		}
	}

	.batchSet {
		padding: 5px;
		display: flex;
		justify-items: left;
	}

	.favratescss {
		display: block !important;
	}

	.majors {
		display: flex;
		flex-wrap: wrap;
		padding: 5px;
	}

	.major {
		display: flex;
		margin: 2px;
	}
</style>