<template>
	<div>
		<table border="1" rules="all" cellspacing="0">
			<thead style="background-color: #e0e0e0;">
				<tr>
					<th style="width: 80px;">代号</th>
					<th style="width: 300px;">专业名称</th>
					<th style="width: 80px;">选科</th>
					<th style="width: 80px;">排名</th>
					<th style="width: 80px;">等级</th>
					<th style="width: 80px;">计划</th>
					<th style="width: 540px;">
				<tr>
					<th colspan="3" style="border: 0px;">
						<span style="color: #E6A23C;">最低排名</span>
						<span>-最低分数-</span>
						<span style="color: #409EFF;">招生计划</span>
					</th>
				</tr>
				<tr>
					<th style="width: 180px;border-left: 0px;border-bottom: 0px;">
						{{tableData[0].MajorScoreYears!=undefined?tableData[0].MajorScoreYears[0]:'--'}}
					</th>
					<th style="width: 180px;border-bottom: 0px;">
						{{tableData[0].MajorScoreYears!=undefined?tableData[0].MajorScoreYears[1]:'--'}}
					</th>
					<th style="width: 180px;border-right: 0px;border-bottom: 0px;">
						{{tableData[0].MajorScoreYears!=undefined?tableData[0].MajorScoreYears[2]:'--'}}
					</th>
				</tr>
				</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="major in tableData">
					<td style="text-align: center;">{{major.DaiHao}}</td>
					<td style="text-align: center;" class="major">
						<el-icon v-if="majorIsSelect(major.ID)"><CircleCheck /></el-icon>
						{{major.MajorName}}
						<el-rate v-if="major.StarMajor>0" v-model="major.StarMajor" disabled text-color="#ff9900"
							:max="3">
						</el-rate>
						<el-tooltip v-if="major.LimitError.length>0" class="item" effect="dark"
							:content="major.LimitError" placement="right">
							<el-icon color="#F56C6C"><CircleClose /></el-icon>
							
						</el-tooltip>
						<el-tooltip v-if="major.LimitWarning.length>0" class="item" effect="dark"
							:content="major.LimitWarning" placement="right">
							<el-icon color="#F56C6C"><Warning /></el-icon>
						</el-tooltip>
						<div class="major_operate">
							<el-button size="mini" type="primary"
								@click="SaveZhiYuan(1,major.ID,major.DaiHao,major.MajorName)">荐
							</el-button>
							<!-- <el-button size="mini" type="success" @click="SaveZhiYuan(2,major.ID)">备
							</el-button> -->
						</div>
					</td>
					<td style="text-align: center;">{{major.Subjects}}</td>
					<td style="text-align: center;">{{major.Ranking}}</td>
					<td style="text-align: center;">{{major.Grade}}</td>
					<td style="text-align: center;">{{major.PlanCount}}</td>
					<td>
						<table>
							<tbody>
								<tr>
									<td style="width: 180px;text-align: center;"
										v-for="score in getNewArray(major.LuQuScores,2)">
										<div v-for="s in score">
											<!-- <template v-if="s.PlanCount>0">
												<span style="font-size: 14px;">{{s.WLType}}&nbsp;</span>
												<span style="color: #E6A23C;">{{s.LOrder}}</span>
												<span>-{{s.LScore}}-</span>
												<span style="color: #0D84FF;">{{s.PlanCount}}</span>
											</template> -->
											<span style="font-size: 14px;">{{s.WLType}}&nbsp;</span>
											<span style="color: #E6A23C;">{{s.LOrder}}</span>
											<span>-{{s.LScore}}-</span>
											<span style="color: #0D84FF;">{{s.PlanCount}}</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		props: ['majors', 'tableData', 'zhiyuanID', 'zySchID', 'stuID', 'SchName', 'majorSeq'],
		data() {
			return {
				seq: 0
			}
		},
		methods: {
			majorIsSelect: function(majorID) {
				let temp = false;
				this.majors.forEach((item) => {
					if (item.majorPlanID == majorID) {
						temp = true;
						return;
					}
				});
				return temp;
			},
			getNewArray(array, length) {
				let index = 0;
				let newArray = [];
				while (index < array.length) {
					let temp = index + length;
					newArray.push(array.slice(index, temp));
					index = temp;
				}
				return newArray;
			},
			SaveZhiYuan(flag, majorPlanID, daihao, name) {
				this.$http.get("/FangAn/SaveZyMajor", {
					stuID: this.stuID,
					zhiYuanID: this.zhiyuanID,
					zySchID: this.zySchID,
					majorPlanID: majorPlanID,
					seq: this.seq,
				}, true).then((res) => {
					if (res.code == 1) {
						this.$emit('addSchMajor', {
							ID: res.data.ZyMajorID,
							DaiHao: daihao,
							Seq: this.seq,
							MajorName: name
						});
						this.seq = this.seq + 1;
						if (this.majors.filter(x => x.majorPlanID == majorPlanID).length == 0) {
							this.majors.push({
								majorPlanID: majorPlanID
							});
						}
						this.$message.success('已添加');
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		created() {
			this.seq = this.majorSeq;
		}
	}
</script>

<style scoped="scoped">
	.major {
		position: relative;
		padding: 10px;
	}

	.major_operate {
		display: none;
		position: absolute;
		top: 3px;
		right: 0px;
		width: 60px;
		height: 30px;
	}

	.major_operate button {
		font-size: 18px;
	}

	.major:hover .major_operate {
		display: inline-block;
	}
</style>
