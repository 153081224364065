<template>
	<div class="compareIcon" :class="isChecked?'checked':''" title="对比">
		<img v-if="isChecked" src="/imgs/compare_checked.png" @click.stop="onChanged" />
		<img v-else src="/imgs/compare_unchecked.png" @click.stop="onChanged" />
		<guess v-if="isShowGuess" :type="type" :stuID="stuID" :areaID="areaID" :fangAnID="fangAnID" :zhiYuanID="zhiYuanID"  @afterAdded="afterGuessAdded" @onClose="onGuessClose"></guess>
	</div>
</template>
<script>
	import guess from './cp_guess.vue'
	import api from './api.js'
	export default {
		emits: [],
		components: {
			guess
		},
		props: {
			type: {
				type: Number,
				default: 0
			},
			refID: {
				type: String,
				default: ''
			},
			areaID: {
				type: String,
				default: ''
			},
			stuID:{
				type: String,
				default: ''
			},
			fangAnID: {
				type: String,
				default: ''
			},
			zhiYuanID: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				isChecked: false,
				isShowGuess: false
			}
		},
		watch: {
			_isChecked() {
				this.isChecked = this._isChecked
			}
		},
		computed: {
			_isChecked() {
				let obj = api.getCompareDetail(this.type, this.refID)
				if (obj)
					return true
				else
					return false
			}
		},
		created() {
			this.isChecked = this._isChecked
		},
		methods: {
			onChanged() {
				this.isChecked = !this.isChecked
				if (this.isChecked) {
					this.add()
				} else {
					this.del()
				}
			},
			add() {
				api.addDetail(0, this.refID, this.type,this.areaID,this.fangAnID,this.zhiYuanID,data => {
					this.isShowGuess = api.isShowGuess(this.type)
				})
			},
			del() {
				api.delDetail(0, this.refID, this.type,this.areaID,this.fangAnID,this.zhiYuanID,data => {

				})
			},
			onGuessClose() {
				this.isShowGuess = false
			},
			afterGuessAdded() {}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.compareIcon {
		display: none;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		cursor:pointer;
		img {
			width: 20px;
			height: 20px;
		}
	}

	.checked {
		display: inline-block;
	}
</style>