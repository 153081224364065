        /// <summary>
        /// 选科卡
        /// </summary>
        // let CARDTYPE_XUANKE = 0;
        /// <summary>
        /// 志愿卡
        /// </summary>
        let CARDTYPE_VIP = 1;
        /// <summary>
        /// 标准服务
        /// </summary>
        let CARDTYPE_VVIP = 2;
        /// <summary>
        /// 高端服务
        /// </summary>
        let CARDTYPE_VVVIP = 3;
        /// <summary>
        /// 校园卡
        /// </summary>
        let CARDTYPE_SCHCARD = 4;
        export default {
        	// CARDTYPE_XUANKE,
        	CARDTYPE_VIP,
        	CARDTYPE_VVIP,
        	CARDTYPE_VVVIP,
        	CARDTYPE_SCHCARD
        }