<template>
	
	<el-dialog v-model="showDialog" append-to-body destroy-on-close custom-class="forDelDiaologTitle" :width="530" @close="OnClose()" :show-close="false">
		<template v-for="(item,n) in areas">
			<el-button v-if="isCurrArea(item)" style="width: 60px;margin: 5px;" size="mini" type="success" @click="SelectArea(item)">{{item.Name}}</el-button>
			<el-button v-else-if="isOrgArr(item)"  size="mini" style="width: 60px;margin: 5px;" @click="SelectArea(item)">{{item.Name}}</el-button>
			<el-button v-else="" size="mini" disabled style="width: 60px;margin: 5px;" @click="SelectArea(item)">{{item.Name}}</el-button>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				showDialog:false,
				orgAreas:[],
				areas:[],
			}
		},
		watch:{
			isShowAreas(){
				this.showDialog = this.isShowAreas;
			}
		},
		props:{
			areaid:String,
			isShowAreas:Boolean
		},
		methods:{
			OnClose(){
				this.$emit("OnClose")
			},
			SelectArea(item){
				this.$emit("ClickArea",item);
			},
			SetUserArea(areaid,areaname){
				let obj = {};
				obj.AreaID = areaid;
				obj.AreaName = areaname;
				this.$store.commit("set_UserSetArea", obj);
			},
			isOrgArr(item){
				let yes = false;
				this.orgAreas.forEach(function(item,n){
					if(item.ID==item.ID){
						yes = true;
						return false;
					}
				})
				return yes;
			},
			isCurrArea(item){
				return item.ID == this.areaid;
			},
			getOrgAreas(){
				this.$http.get("AreaExt/GetDomainProvincelist", {
					
				}, true).then((res) => {
					if (res.code == 1) {
						this.orgAreas = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getAreas(){
				this.$http.get("Sys/Area/GetProvinceList", {
					
				}, true).then((res) => {
					if (res.code == 1) {
						this.areas = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			province(callback) {
				let _this_ = this;
				let mdl = this.$store.getters.getUserSetArea();
				if (mdl != null && mdl != '') {
					callback(mdl.AreaName,mdl.AreaID);
				} else {
					this.GetIpArea(function(data) {
						_this_.$store.commit("set_UserSetArea", data);
						callback(data.AreaName,data.AreaID);
					});
				}
			},
			GetIpArea(callback) {
				this.$http.get("Student/GetIpArea", {

				}, true).then((res) => {
					if (res.code == 1) {
						callback(res.data);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
		},
		created() {
			this.getAreas();
			this.getOrgAreas();
		}
	}
</script>

<style scoped='scoped' lang="less">

</style>
