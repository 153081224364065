<template>
	<div class="addTag">
		<div>
			<el-link v-if="isTxtModel" @click="isTxtModel=!isTxtModel" class="mark" @mouseover="mourseOver"
				@mouseleave="mourseLeave" title="点击文字，可进行编辑">
				{{mark.Note}}
			</el-link>
			<el-input v-else v-model="mark.Note" type="textarea" rows="6" maxlength="500" placeholder="最大长度500个字符"
				@change="updateMark"></el-input>
		</div>
		<!-- <div class="footer">
			<span v-if="showNote">点击文字，可进行编辑</span>
			<el-link type="danger" @click="del">
				<el-icon>
					<Delete />
				</el-icon>
			</el-link>
		</div> -->
	</div>
</template>
<script>
	export default {
		emits: ['afterDeleted', 'afterChanged'],
		components: {},
		props: {
			mark: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				isTxtModel: true,
				isOver: false
			}
		},
		watch: {
			'mark.Note': {
				handler() {
					if (!this.mark.Note)
						this.isTxtModel = false
				}
			}
		},
		computed: {
			showNote() {
				if (this.isTxtModel && this.isOver)
					return true
				else
					return false
			}
		},
		created() {
			if (this.mark && !this.mark.Note)
				this.isTxtModel = false
		},
		methods: {
			updateMark() {
				this.isTxtModel = true
				this.$emit('afterChanged', this.mark)
			},

			del() {
				this.$confirm("确认删除吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.mark.Note = ''
					this.$emit('afterDeleted')
				})
			},
			mourseOver() {
				this.isOver = true
			},
			mourseLeave() {
				this.isOver = false
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.mark {
		cursor: pointer;
	}

	.footer {
		color: #666;
		display: flex;
		align-items: center;
		justify-content: right;
		margin: 10px 0px;

		>* {
			margin-left: 10px;
		}
	}
</style>