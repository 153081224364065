import axios from 'axios'
import store from '../store/index'
import {
	useRouter,
	useRoute
} from 'vue-router'
const router = useRouter();
axios.defaults.timeout = 50000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

import {
	ElLoading as Loading,
	ElMessage as Message
} from 'element-plus';

let loadingInstance;
let loadingStatus = false;


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

let baseURL = process.env.VUE_APP_BASE_URL;
// if (process.env.NODE_ENV == 'development') {
// 	axios.defaults.baseURL = "https://localhost:44374/";
// 	baseURL = "https://localhost:44374/";
// 	// axios.defaults.baseURL = "https://localhost:5001/"; 
// 	// baseURL = "https://localhost:5001/";
// } else if (process.env.NODE_ENV == 'alpha') {
// 	axios.defaults.baseURL = 'http://devapi.gaobaoyun.com/';
// 	baseURL = 'http://devapi.gaobaoyun.com/';
// } else if (process.env.NODE_ENV == 'production') {
// 	axios.defaults.baseURL = 'http://api.gaobaoyun.com/';
// 	baseURL = 'http://api.gaobaoyun.com/';
// }
let ipAddress = axios.defaults.baseURL;
axios.interceptors.request.use((config) => {
	msg = null;
	return config;
}, (error) => {
	return Promise.reject(error);
});
let msg = null;
axios.interceptors.response.use((res) => {

	closeLoading();

	checkResponse(res);

	return Promise.resolve(res);
}, (error) => {
	closeLoading();
	let httpMessage = '';
	if (error.response) {
		if (error.response.status == '401') {
			if (error.response.data && error.response.data.code == 401) {
				if (!localStorage.getItem('user')) {
					// Message.error({
					// 	showClose: true,
					// 	message: '登陆已过期',
					// 	type: 'error'
					// });
				}
				toLogin();
				return;
			}

		}
		if (error.response.status == '404') {
			httpMessage = "未找到请求地址";
		} else if (error.response.data && error.response.data.message) {
			httpMessage = error.response.data.message;
		}
		if (error.response.status == '409') {
			if (msg == null) {
				msg = Message.error({
					duration: 5000,
					showClose: false,
					message: '该账号已在其它地方登录，请重新登录！',
					type: 'error'
				});
				toLogin();
			}
			// return
		}
	} else {
		httpMessage = '服务器处理异常'
	}
	if (msg != null) {
		return
	}
	redirect(httpMessage);
	return Promise.reject(error.response || {}, httpMessage);
});

function closeLoading() {
	if (loadingInstance) {
		loadingInstance.close();
	}
	if (loadingStatus) {
		loadingStatus = false;
		if (loadingInstance) {
			loadingInstance.close();
		}
	}

}

function checkResponse(res) {
	//刷新token
	if (!res.headers) {
		if (res.getResponseHeader("sxt_exp") == "1") {
			replaceToken();
		}
	} else if (res.headers.sxt_exp != null && res.headers.sxt_exp == "1") {
		replaceToken();
	}
}

const _Authorization = 'Authorization';

function showLoading(loading) {
	if (!loading || loadingStatus) {
		return;
	}
	loadingInstance = Loading.service({
		target: '#loading-container',
		customClass: "el-loading",
		text: typeof loading == "string" ? loading : '正在处理.....',
		spinner: 'el-icon-loading',
		background: 'rgba(58, 61, 63, 0.32)'
	});
}

function getToken() {
	return "Bearer " + store.getters.getToken();
}

function getMngID() {
	return store.getters.getMngID();
}

/*
  url
  params请求后台的参数,如：{name:123,values:['a','b','c']}
  loading是否显示遮罩层,可以传入true.false.及提示文本
  config配置信息,如{timeout:3000,headers:{token:123}}
*/
function post(url, params, loading, config) {
	// debugger
	showLoading(loading);
	axios.defaults.headers[_Authorization] = getToken()
	var refer = encodeURIComponent(window.location.href)
	axios.defaults.headers["fullreferer"] = refer
	axios.defaults.headers["mngid"] = getMngID()
	return new Promise((resolve, reject) => {
		axios.post(url, params, config)
			.then(response => {
				if (response) {
					resolve(response.data)
				}
			}, err => {
				reject(err && err.data && err.data.message ? err.data.message : '服务器处理异常');
			})
			.catch((error) => {
				reject(error)
			})
	})
}

//=true异步请求时会显示遮罩层,=字符串，异步请求时遮罩层显示当前字符串
function get(url, param, loading, config) {
	showLoading(loading);
	axios.defaults.headers[_Authorization] = getToken();
	var refer = encodeURIComponent(window.location.href)
	axios.defaults.headers["fullreferer"] = refer
	axios.defaults.headers["mngid"] = getMngID()
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: param
		}, config)
			.then(response => {
				if (response) {
					resolve(response.data)
				}
			}, err => {
				reject(err)
			})
			.catch((error) => {
				reject(error)
			})
	})
}




function createXHR() {
	if (XMLHttpRequest) {
		return new XMLHttpRequest();
	}
	if (ActiveXObject) {
		if (typeof arguments.callee.activeXString != "string") {
			var versions = [
				"MSXML2.XMLHttp.6.0",
				"MSXML2.XMLHttp",
				"MSXML2.XMLHttp.3.0"
			];
			for (var i = 0; i < versions.length; i++) {
				try {
					new ActiveXObject(versions[i]);
					arguments.callee.activeXString = versions[i];
					break;
				} catch (e) {
					console.log(e);
				}
			}
		}
		return new ActiveXObject(arguments.callee.activeXString);
	}
}

function redirect(responseText, message) {
	try {
		let responseData = typeof responseText == 'string' ? JSON.parse(responseText) : responseText;
		if ((responseData.hasOwnProperty('code') && responseData.code == 401) ||
			(responseData.data && responseData.data.code == 401)) {
			closeLoading();
			toLogin();
		} else {
			if (message) {
				Message.error({
					showClose: true,
					message: message,
					type: 'error'
				});
			}
		}
	} catch (error) {
		console.log(error);
		Message.error({
			showClose: true,
			message: responseText,
			type: 'error'
		});
	}
}


function toLogin() {
	store.commit("clearUserInfo");
	// debugger
	var params = ''
	var val = getQueryString(window.location.href, 'orgid')
	if (val)
		params = '?orgid=' + val;
	if (window.location.hash) {
		store.commit("clearUserInfo");
		window.location.href = window.location.origin + '/#/home' + params
		return
	}
	window.location.href = window.location.origin + '/home' + params
}

function getQueryString(fullPath, name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(fullPath) || [, ""])[1]
		.replace(/\+/g, '%20')) || null
}

//动态刷新token
function replaceToken() {
	ajax({
		url: "/Account/replaceToken",
		param: {},
		json: true,
		success: function (x) {
			if (x.code == 1) {
				store.commit('set_token', x.data.token);
			} else {
				console.log(x.message);
				toLogin();
			}
		},
		errror: function (ex) {
			console.log(ex);
			toLogin();
		},
		type: "post",
		async: false
	});


}

function ajax(param) {
	let httpParam =
		Object.assign({
			url: '',
			headers: {},
			param: {},
			json: true,
			success: function () { },
			errror: function () { },
			type: 'post',
			async: true
		}, param);

	httpParam.url = axios.defaults.baseURL + httpParam.url.replace(/\/?/, '');
	httpParam.headers[_Authorization] = getToken();
	var xhr = createXHR();
	xhr.onreadystatechange = function () {
		if (xhr.status == 403 || xhr.status == 401) {
			redirect(xhr.responseText);
			return;
		}
		checkResponse(xhr);
		if (xhr.readyState == 4 && xhr.status == 200) {
			httpParam.success(httpParam.json ? JSON.parse(xhr.responseText) : xhr.responseText);
			return;
		}
		if (xhr.status != 0 && xhr.readyState != 1) {
			httpParam.errror(xhr);
		}
	};
	//初始化请求
	xhr.open(
		httpParam.type,
		httpParam.url,
		httpParam.async
	);
	xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
	for (const key in httpParam.headers) {
		xhr.setRequestHeader(key, httpParam.headers[key]);
	}
	let dataStr = '';
	for (const key in httpParam.param) {
		dataStr += key + "=" + httpParam.param[key];
	}
	try {
		xhr.send(dataStr);
	} catch (error) {
		toLogin();
	}
}
ajax.post = function (url, param, success, errror) {
	ajax({
		url: url,
		param: param,
		success: success,
		error: errror,
		type: 'post'
	})
}
ajax.get = function (url, param, success, errror) {
	ajax({
		url: url,
		param: param,
		success: success,
		error: errror,
		type: 'get'
	})
}


let ajax2 = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	//axios.defaults.headers[_Authorization] = getToken();
});

ajax2.interceptors.request.use(config => {
	config.headers = {
		Authorization: getToken(),
	}
	return config
},
	err => {
		return Promise.reject(err)
	})

let downloadFile = async (url, formData, options) => {
	await ajax2.post(url, formData, {
		responseType: 'arraybuffer'
	}).then(resp => download(resp, options))
}

let getFile = async (url, options) => {
	await ajax2.get(url, {
		responseType: 'blob'
	}).then(resp => download(resp, options))
}
let download = (resp, options) => {
	let blob = new Blob([resp.data], {
		type: options.fileType ? options.fileType : 'application/octet-binary'
	})
	//创建下载的链接
	let href = window.URL.createObjectURL(blob)
	downloadBlob(href, options.fileName)
}

let downloadBlob = (blobUrl, fileName, revokeObjectURL) => {
	let downloadElement = document.createElement('a')
	downloadElement.href = blobUrl
	//下载后文件名
	downloadElement.download = fileName
	document.body.appendChild(downloadElement)
	//点击下载
	downloadElement.click()
	//下载完成移除元素
	document.body.removeChild(downloadElement)
	if (revokeObjectURL == null || revokeObjectURL) {
		//释放掉blob对象
		window.URL.revokeObjectURL(blobUrl)
	}
}

let getDownloadFileUrl = async (url, fileType) => {
	let blob
	await ajax2.get(url, {
		responseType: 'blob'
	}).then(resp => {
		blob = new Blob([resp.data], {
			type: fileType ? fileType : 'application/octet-binary'
		});
	})
	return window.URL.createObjectURL(blob);
}

let getDownloadFileUrlByPost = async (url, data, fileType) => {
	let blob
	await ajax2.post(url, data, {
		responseType: 'blob'
	}).then(resp => {
		blob = new Blob([resp.data], {
			type: fileType ? fileType : 'application/octet-binary'
		});
	})
	return window.URL.createObjectURL(blob);
}

let getDownloadFileBlob = async (url, fileType) => {
	let blob
	await ajax2.get(url, {
		responseType: 'blob'
	}).then(resp => {
		blob = new Blob([resp.data], {
			type: fileType ? fileType : 'application/octet-binary'
		});
	})
	return blob;
}

export default {
	post,
	get,
	ajax,
	ipAddress,
	getFile,
	downloadFile,
	getDownloadFileUrl,
	getDownloadFileUrlByPost,
	getDownloadFileBlob,
	downloadBlob,
	baseURL
}