<template>
	<div>
		<div style="margin-bottom: 15px;">
			<el-radio-group v-model="level" @change="changelevel" style="display: inline-block;float: left;">
				<el-radio-button label="106">本科</el-radio-button>
				<el-radio-button label="107">专科</el-radio-button>
			</el-radio-group>
			<el-button plain type="success" style="margin-left: 10px;float: left;"
				@click="onExpand">
				{{expandAll?"折叠":"全部展开"}}
			</el-button>
			<br>
			<br>
		</div>
		<div>
			<el-input v-model="keyWord" placeholder="请输入院校关键字" class="input-with-select" @keyup="keyUp">
				<template #append>
					<el-button icon="Search" @click="search()" />
				</template>
			</el-input>
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="所有院校" name="所有院校">
				<el-tree ref="tree1" :filter-node-method="filterNode" :data="schooltree" node-key="ID"
					:expand-on-click-node="false" :props="defaultProps" :default-expand-all="expandAll">
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span>
								<span :class="!data.IsInherit&&data.PID!=''?'color4':''">{{ node.label }}</span>
								<span class="color3" v-if="data.HasFavChild">（{{data.FavChildCount}}）</span>
								<el-rate :max="3" v-model="data.FavValue" @change="schoolchange(data)"
									v-if="data.PID!=''">
								</el-rate>
								<el-icon class="el-icon-s-release" :class="data.FavValue==-1?'color3':''"
									@click="data.FavValue=-1;schoolchange(data);" :disabled="data.FavValue==-1"
									v-if="data.PID!=''" title="设为不考虑">
									<Failed />
								</el-icon>

								<el-icon class="el-icon-s-release" title="清空设置" :disabled="data.FavValue==0"
									@click="data.FavValue=0;schoolchange(data);" v-if="data.PID!=''">
									<Refresh />
								</el-icon>
							</span>
							<!-- <span>

							</span> -->
						</span>
					</template>
				</el-tree>
			</el-tab-pane>
			<el-tab-pane label="喜欢的院校" name="喜欢的院校">
				<el-table :data="schoolfavs" style="width: 100%">
					<el-table-column label="院校" width="250" :formatter="handle1">
					</el-table-column>
					<el-table-column label="极喜欢⭐⭐⭐">
						<template #default="scope">
							<div v-if="scope.row.Value==3">
								<!-- <span class="seq">{{scope.row.Seq}}</span>
								<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
									@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
								<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
										class="el-icon-bottom"></i></el-button> -->
								<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
									<Check />
								</el-icon>
							</div>
							<div v-else>
								<el-icon :size="18" class="caca" @click="schoolchange2(scope.row,3);"><Check /></el-icon>
							</div>
						</template>
					</el-table-column>
					<el-table-column #default="scope" label="很喜欢⭐⭐">
						<div v-if="scope.row.Value==2">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="schoolchange2(scope.row,2);"><Check /></el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="喜欢⭐️">
						<div v-if="scope.row.Value==1">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="schoolchange2(scope.row,1);"><Check /></el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18"><Refresh /></el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="不考虑的院校" name="不考虑的院校">
				<el-table :data="schoolnothinks" style="width: 100%">
					<el-table-column prop="FullName" label="院校" width="350" />
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18"><Refresh /></el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				level: '106',
				activeName: '所有院校',
				size: 3,
				schooltree: [],
				schoolfavs: [],
				schoolnothinks: [],
				defaultProps: {
					IP: 'ID',
					children: 'Children',
					label: 'Name',
				},
				keyWord: '',
				expandAll: false
			}
		},
		methods: {
			onExpand() {
				this.expandAll = !this.expandAll;
				// 改变每个节点的状态
				this.changeTreeNodeStatus(this.$refs.tree1.store.root);
			},
			// 改变节点的状态
			changeTreeNodeStatus(node) {
				node.expanded = this.expandAll
				for (let i = 0; i < node.childNodes.length; i++) {
					// 改变节点的自身expanded状态
					node.childNodes[i].expanded = this.expandAll
					// 遍历子节点
					if (node.childNodes[i].childNodes.length > 0) {
						this.changeTreeNodeStatus(node.childNodes[i])
					}
				}
			},
			changelevel(val) {
				this.getschooltree();
				this.getschoolfavs();
			},
			handleClick(tab) {
				if (tab.index != 0) {
					this.getschoolfavs();
				}
			},
			changeseq(ID, state) {
				this.$http.get("/StuArchive/ChangeFavSeq?ID=" + ID + "&IsIncrease=" + state, {}, true).then((res) => {
					if (res.code == 1) {
						this.getschoolfavs();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			up(index, row) {
				this.changeseq(row.ID, true);
			},
			down(index, row) {
				this.changeseq(row.ID, false);
			},
			remove(index, row) {
				this.$http.get("/StuArchive/CancleSchoolFavs?Key=" + row.Key, {}, true).then(
					(res) => {
						if (res.code == 1) {
							this.schoolfavs.filter(x => {
								if (x.Key == row.Key) {
									this.schoolfavs.splice(this.schoolfavs.indexOf(x), 1);
								}
							});
							this.schoolnothinks.filter(x => {
								if (x.Key == row.Key) {
									this.schoolnothinks.splice(this.schoolnothinks.indexOf(x), 1);
								}
							})
						} else {
							this.$message.error(res.msg);
						}
					});
			},
			handle1(row, column, cellValue, index) {
				if (row.Value == 1) {
					return row.FullName + "⭐️";
				} else if (row.Value == 2) {
					return row.FullName + "⭐⭐";
				} else if (row.Value == 3) {
					return row.FullName + "⭐⭐⭐️";
				} else {
					return row.FullName;
				}
			},
			schoolchange(data) {
				this.saveSchoolFavs({
					Key: data.ID,
					Value: data.FavValue,
					Type: data.Type,
					SubType: data.SubType,
				}, data);
			},
			schoolchange2(data, value) {
				this.$http.post("/StuArchive/SaveSchoolFavs", {
					Key: data.ID,
					Value: value,
					Type: data.Type,
					SubType: data.SubType
				}, true).then((res) => {
					if (res.code == 1) {
						this.schoolfavs.forEach(x => {
							if (x.ID == data.ID) {
								x.Value = value;
							}
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			saveSchoolFavs(data, src) {
				this.$http.post("/StuArchive/SaveSchoolFavs", data, true).then((res) => {
					if (res.code == 1) {
						this.updateStatus(src.Children, src.FavValue);
						this.synCount(src)
						this.synParentCount(src)
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			updateStatus(children, value) {
				if (children == null) return;
				for (var i = 0; i < children.length; i++) {
					children[i].FavValue = value
					this.updateStatus(children[i].Children, value)
				}
			},
			synCount(node) {
				var iFavChildCount = 0;
				if (node.Children != null) {
					node.Children.forEach((item, index) => {
						if (item.Children != null && item.Children.length > 0) {
							iFavChildCount += this.synCount(item)
						} else if (item.FavValue != 0)
							iFavChildCount += 1
					})
					node.FavChildCount = iFavChildCount
					if (node.FavChildCount > 0)
						node.HasFavChild = true
					else
						node.HasFavChild = false
				}
				return iFavChildCount
			},
			synParentCount(node) {
				if (node.parent != null) {
					var iFavChildCount = 0;
					node.parent.Children.forEach((item, index) => {
						if (item.Children != null && item.Children.length > 0) {
							iFavChildCount += item.FavChildCount
						} else if (item.FavValue != 0) {
							iFavChildCount += 1
						}
					})
					node.parent.FavChildCount = iFavChildCount
					if (node.parent.FavChildCount > 0)
						node.parent.HasFavChild = true
					else
						node.parent.HasFavChild = false
					if (node.parent.parent != null)
						this.synParentCount(node.parent)
				}
			},
			getschoolfavs() {
				//获取城市倾向
				this.$http.get("/StuArchive/GetSchoolFavs?Level=" + this.level, {
					level: this.level,
					keyWord: this.keyWord
				}, true).then((res) => {
					if (res.code == 1) {
						this.schoolfavs = res.data.Favs;
						this.schoolnothinks = res.data.NoThinks;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getschooltree() {
				//获取城市树
				this.$http.get("/StuArchive/GetSchoolTree?level=" + this.level, {

				}, true).then((res) => {
					if (res.code == 1) {
						this.schooltree = res.data;

						this.schooltree.forEach((item, idx) => {
							this.setParent(item)
						})
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			setParent(node) {
				if (node.Children != null)
					node.Children.forEach((item, index) => {
						item.parent = node
						this.setParent(item)
					})
			},
			init() {
				this.getschooltree();
			},
			keyUp(e) {
				if (e.keyCode == 13) {
					this.search()
				}
			},
			search() {
				this.$refs.tree1.filter(this.keyWord);
				if (this.activeName != "所有院校")
					this.getschoolfavs();
			},
			filterNode(value, data) {
				if (!value) return true;
				return data.Name.indexOf(value) !== -1;
			}
		},
		created() {
			this.init()
		}
	}
</script>

<style scoped="scoped">
	.el-table__cell:hover .caca {
		display: inline-block;
	}

	.caca {
		display: none;
		color: #409EFF;
		font-weight: 700;
		cursor: pointer;
	}

	.seq {
		color: red;
		font-weight: 600;
		border: 1px solid red;
		border-radius: 8px;
		width: 16px;
		height: 16px;
		line-height: 16px;
		display: inline-block;
		text-align: center;
		margin-right: 10px;
	}

	.el-icon-s-release,
	.el-icon-refresh {
		margin-left: 5px;
		margin-right: 10px;
		font-size: 17px;
		float: left;
	}

	.el-icon-s-release:hover,
	.el-icon-refresh:hover {
		color: #F56C6C;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-rate {
		margin-left: 20px;
		float: left;
	}

	.color1 {
		color: #409EFF;
	}

	.color2 {
		color: #E6A23C;
	}

	.color3 {
		color: #F56C6C;
	}

	.color4 {
		color: #67C23A;
	}

	.input-with-select {
		width: 40%;
	}
</style>
