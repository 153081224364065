<template>
	<div class="compareIcon" :style="getStyle()" :class="types && types.length>1?'':'allowClick'"
		@click.stop="clickType()">
		<!-- 对比 -->
		<template v-for="(item,i) in types">
			<span v-if="i>0" class="hsplit"></span>
			<num class="num" :areaID="areaID" :type="item" :fangAnID="fangAnID" :title="getTypeTitle(item)"
				:name="getTypeName(item)" @click.stop="clickType(item)">
			</num>
		</template>
		<el-dialog v-model="isShowDlg" :title="title" width="1400px" destroy-on-close append-to-body>
			<compare :areaID="areaID" :stuID="stuID" :fangAnID="fangAnID" :type="type"></compare>
		</el-dialog>
	</div>
</template>
<script>
	import compare from './cp_compare.vue'
	import api from './api.js'
	import num from './cp_num.vue'
	export default {
		emits: [],
		components: {
			compare,
			num
		},
		props: {
			types: {
				type: Array,
				default: []
			},
			fangAnID: {
				type: String,
				default: ''
			},
			areaID: {
				type: String,
				default: ''
			},
			stuID: {
				type: String,
				default: ''
			},
			offSetTop: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				isShowDlg: false,
				list: [],
				type: 0,
				title: ''
			}
		},
		watch: {},
		computed: {},
		created() {},
		methods: {
			clickType(type) {
				if (!this.types || this.types.length == 0)
					return
				if (!type && this.types.length > 1)
					return
				if (!type)
					type = this.types[0]
				this.type = type
				this.title = this.getTypeTitle(this.type)
				this.isShowDlg = !this.isShowDlg
			},
			getTypeTitle(type) {
				let val = ''
				switch (type) {
					case 1:
						val = '比较院校'
						break
					case 5:
						val = '比较专业'
						break
				}
				return val
			},
			getTypeName(type) {
				let val = ''
				switch (type) {
					case 1:
						val = '比院校'
						break
					case 5:
						val = '比专业'
						break
				}
				return val
			},
			getStyle() {
				let val = {}
				if (this.offSetTop > 0) {
					val.top = this.offSetTop + "px"
				}

				return val
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.compareIcon {
		box-sizing: border-box;
		width: 35px;
		border: 1px solid #ddd;
		padding: 10px 5px;
		// width: fit-content;
		// writing-mode: vertical-lr;
		position: fixed;
		right: 2px;
		top: 45%;
		background-color: #409EFF;
		color: white;
		border-radius: 5px;
		cursor: default;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: 100;
	}

	.allowClick {
		cursor: pointer;
	}

	.num {
		cursor: pointer;
		padding: 3px 0px;
	}

	// .num:nth-child(n+2) {
	// 	border-top: 1px solid white;
	// }
	.hsplit {
		display: block;
		border-top: 1px solid white;
		margin: 5px 0px;
		width: 100%;
	}
</style>