<template>
	<div>
		<div>
			<el-input v-model="keyWord" placeholder="请输入城市关键字" class="input-with-select" @keyup="keyUp">
				<template #append>
					<el-button icon="Search" @click="search()" />
				</template>
			</el-input>
			<el-button plain type="success" style="height: 40px;margin-left: 20px;" @click="onExpand">
				{{ expandAll ? "折叠" : "全部展开" }}
			</el-button>

			<!-- <el-button @click="onKeyClick" type="warning" style="height: 40px;margin-left: 10px;">
				一键设置热门地区
			</el-button>

			<el-button @click="dialogVisible=true;" type="warning" style="height: 40px;margin-left: 10px;">
				快速设置热门城市
			</el-button>
			 -->
			<el-button @click="this.isDrawOnKey = true" type="warning" style="height: 40px;margin-left: 10px;">
				一键设置
			</el-button>

		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="所有城市" name="所有城市">
				<div :class="(isFixed ? 'param1 is_fixed' : 'param1')">
					<span>院校总数</span>
					<span>本科</span>
					<span>专科</span>
					<span>211</span>
					<span>985</span>
					<span>研究生院</span>
					<span>双一流</span>
					<span>城市等级</span>
				</div>
				<el-tree ref="tree1" :filter-node-method="filterNode" :data="citytree" node-key="ID"
					:default-expand-all="expandAll" :expand-on-click-node="false" :props="defaultProps">
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span>
								<!-- <favrate ShowTitle="城市倾向设置" :Val="data.FavValue" :Readonly="false"
									@OnChange="(val)=>(val,data)"></favrate> -->
								<span :class="data.IsInherit || data.PID == '0' ? '' : 'color4'">{{ node.label }}</span>
								<span class="color3" v-if="data.HasFavChild">（{{ data.FavChildCount }}）</span>
								<el-rate :max="3" v-model="data.FavValue" @change="citychange(data)">
								</el-rate>

								<el-icon :size="18" class="el-icon-s-release" :class="data.FavValue == -1 ? 'color3' : ''"
									title="设为不考虑" style="float: left;" :disabled="data.FavValue == -1"
									@click="data.FavValue = -1; citychange(data)">
									<Failed />
								</el-icon>

								<el-icon :size="18" class="el-icon-refresh" title="清空设置" style="float: left;"
									:disabled="data.FavValue == 0" @click="data.FavValue = 0; citychange(data);">
									<Refresh />
								</el-icon>
							</span>
							<span class="param2">
								<span class="color1">{{ data.SchTotal }}</span>
								<span class="color2">{{ data.SchBenTotal }}</span>
								<span class="color3">{{ data.SchZhuanTotal }}</span>
								<span class="color4">{{ data.Sch211 }}</span>
								<span class="color1">{{ data.Sch985 }}</span>
								<span class="color2">{{ data.SchGraduate }}</span>
								<span class="color3">{{ data.DoubleClassic }}</span>
								<span class="color4">{{ data.CityGrade }}</span>
							</span>
						</span>
					</template>
				</el-tree>
			</el-tab-pane>
			<el-tab-pane label="喜欢的城市" name="喜欢的城市">
				<el-table :data="cityfavs" style="width: 100%">
					<el-table-column label="城市" width="250" :formatter="handle1">
					</el-table-column>
					<el-table-column label="极喜欢⭐⭐⭐">
						<template #default="scope">
							<div v-if="scope.row.Value == 3">
								<!-- <span class="seq">{{scope.row.Seq}}</span>
								<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
									@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
								<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
										class="el-icon-bottom"></i></el-button> -->
								<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
									<Check />
								</el-icon>
							</div>
							<div v-else>
								<el-icon :size="18" class="caca" @click="citychange2(scope.row, 3);">
									<Check />
								</el-icon>
							</div>
						</template>
					</el-table-column>
					<el-table-column #default="scope" label="很喜欢⭐⭐">
						<div v-if="scope.row.Value == 2">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="citychange2(scope.row, 2);">
								<Check />
							</el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="喜欢⭐">
						<div v-if="scope.row.Value == 1">
							<!-- <span class="seq">{{scope.row.Seq}}</span>
							<el-button v-if="scope.row.Seq!=1" type="text" size="mini"
								@click="up(scope.$index, scope.row)"><i class="el-icon-top"></i></el-button>
							<el-button type="text" size="mini" @click="down(scope.$index, scope.row)"><i
									class="el-icon-bottom"></i></el-button> -->
							<el-icon :size="18" style="color:#67C23A;font-weight: 700;">
								<Check />
							</el-icon>
						</div>
						<div v-else>
							<el-icon :size="18" class="caca" @click="citychange2(scope.row, 1);">
								<Check />
							</el-icon>
						</div>
					</el-table-column>
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" size="large" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18">
								<Refresh />
							</el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="不喜欢的城市" name="不喜欢的城市">
				<el-table :data="citynothinks" style="width: 100%">
					<el-table-column prop="FullName" label="城市" width="250" />
					<el-table-column #default="scope" label="移出列表">
						<el-button type="text" size="large" @click="remove(scope.$index, scope.row)">
							<el-icon :size="18">
								<Refresh />
							</el-icon>
						</el-button>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<el-dialog v-model="dialogVisible" title="快速设置热门城市">
			<div class="demo-collapse">
				<el-collapse accordion v-model="activeHot" @change="expandHot">
					<el-collapse-item v-for="item in hotCitys" :title="item.Value" :name="item.Key">
						<div v-if="item.childList != undefined">
							<span style="float: left;">批量设置：</span>
							<el-rate :max="3" v-model="item.FavValue" @change="groupChanged(item)">
							</el-rate>
							<el-icon :size="18" :class="item.FavValue == -1 ? 'color3' : ''" style="float: left;"
								:disabled="item.FavValue == -1" @click="item.FavValue = -1; groupChanged(item);"
								title="设为不考虑">
								<Failed />
							</el-icon>

							<el-icon :size="18" style="float: left;" @click="item.FavValue = 0; groupChanged(item);"
								title="清空设置">
								<Refresh />
							</el-icon>

							<br>
							<span
								style="display: inline-block;padding: 5px 10px;margin-top: 10px; margin-right: 5px;margin-bottom: 5px;border: 1px solid #DCDFE6;"
								v-for="child in item.childList">
								<span>{{ child.CityName }}</span>
								<span title="地区认可度" style="color: #E6A23C;">👍{{ child.Idx }}</span>
								<el-rate :max="3" v-model="child.FavValue" @change="saveCityFavs2(child)">
								</el-rate>

								<el-icon :size="18" :class="child.FavValue == -1 ? 'color3' : ''" style="float: left;"
									:disabled="child.FavValue == -1" @click="child.FavValue = -1; saveCityFavs2(child);"
									title="设为不考虑">
									<Failed />
								</el-icon>

								<el-icon :size="18" style="float: left;" :disabled="child.FavValue == 0"
									@click="child.FavValue = 0; saveCityFavs2(child);" title="清空设置">
									<Refresh />
								</el-icon>

							</span>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<!-- <template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitHot">保存</el-button>
				</span>
			</template> -->
		</el-dialog>
		<el-dialog v-model="isShowOnKeyDlg" title="一键设置热门地区">
			<div class="introduce">
				以下热门省份，是根据本省<span class="light">报考大数据</span>统计得到。如果和你的偏好相同，可一键<span class="light">批量设置</span>相应的城市偏好。
			</div>
			<div v-for="item in this.hotAreaGroups">
				<div class="subTag">{{ item.Name }}</div>
				<div>
					<span v-for="child in item.Areas" class="hotArea">
						{{ child.SchAreaName }}
					</span>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="this.isShowOnKeyDlg = false">取消</el-button>
					<el-button type="primary" @click="setHotArea">设置</el-button>
				</span>
			</template>
		</el-dialog>
		<el-drawer class="drawer" :destroy-on-close="true" size="70%" :modal="true" :lock-scroll="true"
			v-model="isDrawOnKey" title="一键设置">
			<cpOnkeySet @afterSaved="afterSaved" :isShowOptType="isShowOptType"></cpOnkeySet>
		</el-drawer>
	</div>
</template>

<script>
import cpOnkeySet from '@/views/hotArea/cp_OnkeySet.vue'
export default {
	components: {
		cpOnkeySet
	},
	data() {
		return {
			activeName: '所有城市',
			size: 3,
			citytree: [],
			cityfavs: [],
			citynothinks: [],
			defaultProps: {
				IP: 'ID',
				children: 'Children',
				label: 'Name',
			},
			keyWord: '',
			isFixed: false,
			dialogVisible: false,
			hotCitys: [],
			activeHot: '',
			expandAll: false,
			isShowOnKeyDlg: false,
			hotAreaGroups: null,
			isDrawOnKey: false,
			isShowOptType: true
		}
	},
	computed: {
		// ShowVal(val) {

		// 	return val;
		// },
	},
	methods: {
		afterSaved() {
			this.isDrawOnKey = false
			this.init()
		},
		submitHot() {
			this.getcitytree();
		},
		expandHot(v, bForce) {
			this.hotCitys.forEach(item => {
				if (item.Key == v) {
					if (item.childList == undefined || bForce) {
						this.$http.get("/StuArchive/GetHotCityList?cityType=" + v, {

						}, true).then((res) => {
							if (res.code == 1) {
								item.childList = res.data;
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				}
			})
		},
		onExpand() {
			this.expandAll = !this.expandAll;
			// 改变每个节点的状态
			this.changeTreeNodeStatus(this.$refs.tree1.store.root);
		},
		// 改变节点的状态
		changeTreeNodeStatus(node) {
			node.expanded = this.expandAll
			for (let i = 0; i < node.childNodes.length; i++) {
				// 改变节点的自身expanded状态
				node.childNodes[i].expanded = this.expandAll
				// 遍历子节点
				if (node.childNodes[i].childNodes.length > 0) {
					this.changeTreeNodeStatus(node.childNodes[i])
				}
			}
		},
		handleClick(tab) {
			if (tab.index != 0) {
				this.getcityfavs();
			}
		},
		changeseq(ID, state) {
			this.$http.get("/StuArchive/ChangeFavSeq?ID=" + ID + "&IsIncrease=" + state, {}, true).then((res) => {
				if (res.code == 1) {
					this.getcityfavs();
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		up(index, row) {
			this.changeseq(row.ID, true);
		},
		down(index, row) {
			this.changeseq(row.ID, false);
		},
		remove(index, row) {
			this.$http.get("/StuArchive/CancleCityFavs?Key=" + row.Key, {}, true).then((res) => {
				if (res.code == 1) {
					let arr = this.cityfavs;
					this.cityfavs.filter(x => {
						if (x.Key == row.Key) {
							arr.splice(arr.indexOf(x), 1);
						}
					});
					this.cityfavs = arr;
					this.citynothinks.filter(x => {
						if (x.Key == row.Key) {
							this.citynothinks.splice(this.citynothinks.indexOf(x), 1);
						}
					})

					let node = this.getNodeByAreaID(row.Key);
					node.FavValue = '';
					this.updateStatus(node.Children, node.FavValue);
					this.synCount(node)
					this.synParentCount(node)
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		handle1(row, column, cellValue, index) {
			if (row.Value == 1) {
				return row.FullName + "⭐️️";
			} else if (row.Value == 2) {
				return row.FullName + "⭐⭐️";
			} else if (row.Value == 3) {
				return row.FullName + "⭐⭐⭐️";
			} else {
				return row.FullName;
			}
		},
		citychange(data) {
			this.saveCityFavs({
				Key: data.Key ? data.Key : data.ID,
				Value: data.FavValue
			}, data);
		},
		getNodeByAreaID(areaID) {
			let node;
			this.citytree.forEach((item, idx) => {
				item.Children.forEach((citem, idx) => {
					citem.Children.forEach((ccitem, idx) => {
						if (ccitem.ID == areaID) {
							node = ccitem;
						}
					})
				})
			})
			return node;
		},
		citychange2(data, value) {
			this.$http.post("/StuArchive/SaveCityFavs", {
				Key: data.Key,
				Value: value
			}, true).then((res) => {
				if (res.code == 1) {
					this.cityfavs.forEach(x => {
						if (x.ID == data.ID) {
							x.Value = value;
						}
					});
					let node = this.getNodeByAreaID(data.Key);
					node.FavValue = value;
					this.updateStatus(node.Children, node.FavValue);
					this.synCount(node)
					this.synParentCount(node)
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		saveCityFavs(data, src) {
			this.$http.post("/StuArchive/SaveCityFavs", data, true).then((res) => {
				if (res.code == 1) {
					this.updateStatus(src.Children, src.FavValue);
					this.synCount(src)
					this.synParentCount(src)
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		saveCityFavs2(data) {
			this.$http.post("/StuArchive/SaveCityFavs", {
				Key: data.CityID,
				Value: data.FavValue,
			}, true).then((res) => {
				if (res.code == 1) {

				} else {
					this.$message.error(res.msg);
				}
			});
		},
		synCount(node) {
			var iFavChildCount = 0;
			if (node.Children != null) {
				node.Children.forEach((item, index) => {
					if (item.Children != null && item.Children.length > 0) {
						iFavChildCount += this.synCount(item)
					} else if (item.FavValue != 0)
						iFavChildCount += 1
				})
				node.FavChildCount = iFavChildCount
				if (node.FavChildCount > 0)
					node.HasFavChild = true
				else
					node.HasFavChild = false
			}
			return iFavChildCount
		},
		synParentCount(node) {
			//debugger
			if (node.parent != null) {
				var iFavChildCount = 0;
				node.parent.Children.forEach((item, index) => {
					if (item.Children != null && item.Children.length > 0) {
						iFavChildCount += item.FavChildCount
					} else if (item.FavValue != 0) {
						iFavChildCount += 1
					}
				})
				node.parent.FavChildCount = iFavChildCount
				if (node.parent.FavChildCount > 0)
					node.parent.HasFavChild = true
				else
					node.parent.HasFavChild = false
				if (node.parent.parent != null)
					this.synParentCount(node.parent)
			}
		},
		updateStatus(children, value) {
			if (children == null) return;
			for (var i = 0; i < children.length; i++) {
				children[i].FavValue = value
				this.updateStatus(children[i].Children, value)
			}
		},
		getcityfavs() {
			//获取城市倾向
			this.$http.get("/StuArchive/GetCityFavs", {
				keyWord: this.keyWord
			}, true).then((res) => {
				if (res.code == 1) {
					this.cityfavs = res.data.Favs;
					this.citynothinks = res.data.NoThinks;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		getcitytree() {
			//获取城市树
			this.$http.get("/StuArchive/GetCityTree", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.citytree = res.data;
					var num = 0
					this.citytree.forEach((item, idx) => {
						this.setParent(item)
						num += item.FavChildCount
					})
					if (num == 0) {
						this.isDrawOnKey = true
						this.isShowOptType = false
					} else {
						this.isShowOptType = true
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},



		setParent(node) {
			if (node.Children != null)
				node.Children.forEach((item, index) => {
					item.parent = node
					this.setParent(item)
				})
		},
		init() {
			this.getcitytree();
			// this.getHotCitys();
		},
		keyUp(e) {
			if (e.keyCode == 13) {
				this.search()
			}
		},
		search() {
			this.$refs.tree1.filter(this.keyWord);
			if (this.activeName != "所有城市")
				this.getcityfavs();
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.Name.indexOf(value) !== -1;
		},
		getHotCitys() {
			this.$http.get("/StuArchive/GetHotCityTypeList", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.hotCitys = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		groupChanged(group) {
			this.$http.post("/StuArchive/BatchSetCityFavs", {
				CityType: group.Key,
				Value: group.FavValue
			}, true).then((res) => {
				if (res.code == 1) {
					this.expandHot(group.Key, true)
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		onKeyClick() {
			this.isShowOnKeyDlg = true
			if (this.hotAreaGroups == null) {
				this.$http.get("/StuArchive/GetHotAreaGroup", {}, true).then((res) => {
					if (res.code == 1) {
						this.hotAreaGroups = res.data
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		setHotArea() {
			this.$http.get("/StuArchive/BatchSetHotArea", {}, true).then((res) => {
				if (res.code == 1) {
					this.$message.success("设置成功");
					this.isShowOnKeyDlg = false
					this.init()
				} else {
					this.$message.error(res.msg);
				}
			});
		},

	},
	created() {
		this.init()
	}
}
</script>

<style scoped="scoped" lang="less">
.el-table__cell:hover .caca {
	display: inline-block;
}

.caca {
	display: none;
	color: #409EFF;
	font-weight: 700;
	cursor: pointer;
}

.param1 {
	text-align: right;
	background-color: #409EFF;
	margin-bottom: 5px;
	padding: 7px;

	span {
		display: inline-block;
		width: 70px;
		text-align: center;
		border-right: 1px solid #fff;
		color: #fff;
	}
}

.param2 {
	span {
		display: inline-block;
		width: 70px;
		text-align: center;
	}
}

.seq {
	color: red;
	font-weight: 600;
	border: 1px solid red;
	border-radius: 8px;
	width: 16px;
	height: 16px;
	line-height: 16px;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.el-icon-s-release,
.el-icon-refresh {
	margin-left: 5px;
	margin-right: 10px;
	font-size: 17px;
}

.el-icon-s-release:hover,
.el-icon-refresh:hover {
	color: #F56C6C;
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}

.el-rate {
	float: left;
	margin-top: -7px;
}

.color1 {
	color: #409EFF;
}

.color2 {
	color: #E6A23C;
}

.color3 {
	color: #F56C6C;
}

.color4 {
	color: #67C23A;
}

.input-with-select {
	width: 40%;
}

.introduce {
	background-color: #ecf5ff;
	margin: 0px 0px;
	padding: 15px;
	// font-size: 16px;
}

.light {
	font-weight: 600;
	color: #409EFF;
}

.hotArea {
	display: inline-block;
	padding: 5px 10px;
	margin-top: 10px;
	margin-right: 15px;
	margin-bottom: 5px;
	border: 1px solid #DCDFE6;
	border-radius: 5px;
}

.subTag {
	border-left: 3px solid #409EFF;
	padding-left: 5px;
	// font-size: 15px;
	// font-weight: 600;
	margin: 10px 0px;
}

* :deep(.el-drawer__header) {
	margin-bottom: 5px;
}
</style>