<template>
	<table border="1" rules="all" cellspacing="0">
		<thead style="background-color: #e0e0e0;">
			<tr>
				<th style="width: 660px;">
					<el-radio-group v-model="by" size="large" @change='orderby'>
						<el-radio-button label="0">按录取难度排序<el-icon><CaretBottom /></el-icon></el-radio-button>
						<el-radio-button label="1">按综合实力排序<el-icon><CaretBottom /></el-icon>
						</el-radio-button>
					</el-radio-group>
				</th>
				<th style="width: 80px;">
					{{tableData.PlanYear}}计划
				</th>
				<th style="width: 450px;">
			<tr>
				<th colspan="3" style="border: 0px;">
					<span style="color: #E6A23C;">最低排名</span>
					<span>-最低分数-</span>
					<span style="color: #409EFF;">招生计划</span>
				</th>
			</tr>
			<tr>
				<th style="width: 150px;border-left: 0px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[0]:'--'}}
				</th>
				<th style="width: 150px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[1]:'--'}}
				</th>
				<th style="width: 150px;border-right: 0px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[2]:'--'}}
				</th>
			</tr>
			</th>
			</tr>
		</thead>
		<tbody>
			<template v-for="item in tableData.List">
				<tr>
					<td>
						<div class="school">
							<el-icon v-if="schoolIsSelect(item.ID)"><CircleCheck /></el-icon>
							<router-link target="_self"
								:to="'http://119.45.101.116:8088/#/schoolSecond?SchID='+item.SchID">
								<span>
									<span
										style="color: #F56C6C;">{{item.RefOrder}}</span>({{item.DaiHao}}){{item.SchName}}
								</span>
								<el-rate v-if="item.StarSch>0" v-model="item.StarSch" disabled text-color="#ff9900"
									:max="3">
								</el-rate>
								<el-tooltip v-if="item.LimitError.length>0" class="item" effect="dark"
									:content="item.LimitError" placement="right">
									<el-icon color="#F56C6C"><CircleClose /></el-icon>
								</el-tooltip>
								<el-tooltip v-if="item.LimitWarning.length>0" class="item" effect="dark"
									:content="item.LimitWarning" placement="right">
									<el-icon color="#F56C6C"><Warning /></el-icon>
								</el-tooltip>
							</router-link>
							<span class="fold">
								<el-button size="small" :icon="item.IsExpanded?'Minus':'Plus'" circle
									@click="item.IsExpanded=!item.IsExpanded;expand(item,item.IsExpanded)"></el-button>
							</span>
							<span class="schoolInfo">
								<span style="margin-right: 5px;">{{item.Address}}</span>
								<span
									style="margin-right: 20px;">全国：{{item.COrder}}&nbsp;&nbsp;省内：{{item.POrder}}</span>
								<schoolTag :schooldata="item"></schoolTag>
							</span>
						</div>
					</td>
					<td style="text-align: center;">
						{{item.PlanCount}}
					</td>
					<td>
						<table>
							<tbody>
								<tr>
									<td style="width: 150px;text-align: center;"
										v-for="item in getNewArray(item.LuQuScores,tableData.SchScoreYears)">
										<div v-for="lu in item">
<!-- 											<template v-if="lu.PlanCount>0">
												<span style="font-size: 14px;">{{lu.WLType}}&nbsp;</span>
												<span style="color: #E6A23C;">{{lu.LOrder}}</span>
												<span>-{{lu.LScore}}-</span>
												<span style="color: #0D84FF;">{{lu.PlanCount}}</span>
											</template> -->
											<span style="font-size: 14px;">{{lu.WLType}}&nbsp;</span>
											<span style="color: #E6A23C;">{{lu.LOrder}}</span>
											<span>-{{lu.LScore}}-</span>
											<span style="color: #0D84FF;">{{lu.PlanCount}}</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td colspan="3" v-if="item.IsExpanded">
						<!-- <div style="padding: 10px;">
							<span>院校备注：</span><span style="color: #F56C6C;">{{item.SchRemark}}</span><br>
							<span>专业录取依据：</span><span style="color: #F56C6C;">{{item.EnrollBy}}</span><br>
							<span>专业录取规则：</span><span style="color: #F56C6C;">{{item.EnrollRule}}</span><br>
							<span>大类招生分流：</span><span style="color: #F56C6C;">{{item.Divide}}</span><br>
							<span>转专业规定：</span><span style="color: #F56C6C;">{{item.SwitchMajor}}</span><br>
							<span>外语语种限报：</span><span style="color: #F56C6C;">{{item.Language}}</span><br>
							<span>单科成绩要求：</span><span style="color: #F56C6C;">{{item.SbjScoreReq}}</span><br>
						</div> -->
						<div style="padding: 10px;">
							<table border="1" rules="all" cellspacing="0">
								<thead style="background-color: #e0e0e0;">
									<tr>
										<th style="width: 80px;">代号</th>
										<th style="width: 300px;">专业名称</th>
										<th style="width: 80px;">选科</th>
										<th style="width: 80px;">排名</th>
										<th style="width: 80px;">等级</th>
										<th style="width: 80px;">计划</th>
										<th style="width: 540px;">
									<tr>
										<th colspan="3" style="border: 0px;">
											<span style="color: #E6A23C;">最低排名</span>
											<span>-最低分数-</span>
											<span style="color: #409EFF;">招生计划</span>
										</th>
									</tr>
									<tr>
										<th style="width: 180px;border-left: 0px;border-bottom: 0px;">
											{{tableData.MajorScoreYears!=undefined?tableData.MajorScoreYears[0]:'--'}}
										</th>
										<th style="width: 180px;border-bottom: 0px;">
											{{tableData.MajorScoreYears!=undefined?tableData.MajorScoreYears[1]:'--'}}
										</th>
										<th style="width: 180px;border-right: 0px;border-bottom: 0px;">
											{{tableData.MajorScoreYears!=undefined?tableData.MajorScoreYears[2]:'--'}}
										</th>
									</tr>
									</th>
				</tr>
				</thead>
		<tbody>
			<tr v-for="major in item.SchMajorPlanList">
				<td style="text-align: center;">{{major.DaiHao}}</td>
				<td style="text-align: center;" class="major">
					<el-icon v-if="majorIsSelect(item.ID,major.ID)"><CircleCheck /></el-icon>
					<span style="color: #F56C6C;">{{major.RefOrder}}</span>
					{{major.MajorName}}
					<el-rate v-if="major.StarMajor>0" v-model="major.StarMajor" disabled text-color="#ff9900" :max="3">
					</el-rate>
					<el-tooltip v-if="major.LimitError.length>0" class="item" effect="dark" :content="major.LimitError"
						placement="right">
						<el-icon color="#F56C6C"><CircleClose /></el-icon>
					</el-tooltip>
					<el-tooltip v-if="major.LimitWarning.length>0" class="item" effect="dark"
						:content="major.LimitWarning" placement="right">
						<el-icon color="#F56C6C"><Warning /></el-icon>
					</el-tooltip>
					<div class="major_operate">
						<el-button size="mini" type="primary" @click="SaveZhiYuan(1,item.ID,major.ID)">荐
						</el-button>
						<!-- <el-button size="mini" type="success" @click="SaveZhiYuan(2,item.ID,major.ID)">备
												</el-button> -->
					</div>
				</td>
				<td style="text-align: center;">{{major.Subjects}}</td>
				<td style="text-align: center;">{{major.Ranking}}</td>
				<td style="text-align: center;">{{major.Grade}}</td>
				<td style="text-align: center;">{{major.PlanCount}}</td>
				<td>
					<table>
						<tbody>
							<tr>
								<td style="width: 180px;text-align: center;"
									v-for="score in getNewArray(major.LuQuScores,tableData.SchScoreYears)">
									<div v-for="s in score">
										<!-- <template v-if="s.PlanCount>0">
											<span style="font-size: 14px;">{{s.WLType}}&nbsp;</span>
											<span style="color: #E6A23C;">{{s.LOrder}}</span>
											<span>-{{s.LScore}}-</span>
											<span style="color: #0D84FF;">{{s.PlanCount}}</span>
										</template> -->
										<span style="font-size: 14px;">{{s.WLType}}&nbsp;</span>
										<span style="color: #E6A23C;">{{s.LOrder}}</span>
										<span>-{{s.LScore}}-</span>
										<span style="color: #0D84FF;">{{s.PlanCount}}</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table>
	</div>
	</td>
	</tr>
</template>
</tbody>
</table>
</template>

<script>
	export default {
		props: {
			zhiyuan: {
				type: Array,
				default: {}
			},
			tableData: {
				type: Object,
				default: {}
			},
			zhiyuanID: {
				type: String,
				default: ''
			},
			piciID: {
				type: String,
				default: ''
			},
			stuID: {
				type: String,
				default: ''
			},
			seq: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				by: '0'
			}
		},
		methods: {
			majorIsSelect: function(schID, majorID) {
				let temp = false;
				this.zhiyuan.Schools.forEach((item) => {
					if (item.schPlanID == schID) {
						item.Majors.forEach((major) => {
							if (major.majorPlanID == majorID) {
								temp = true;
								return;
							}
						});
					}
				});
				return temp;
			},
			schoolIsSelect: function(schID) {
				let temp = false;
				this.zhiyuan.Schools.forEach((item) => {
					if (item.schPlanID == schID) {
						temp = true;
						return;
					}
				});
				return temp;
			},
			orderby() {
				this.$emit("orderby", this.by);
			},
			getNewArray(array, years) {
				const arr = [];
				years.forEach(year => {
					const arr2 = [];
					array.forEach(item => {
						if (item.Year == year) {
							arr2.push(item);
						}
					});
					arr.push(arr2);
				})
				return arr;
			},
			// getNewArray(array, length) {
			// 	let index = 0;
			// 	let newArray = [];
			// 	while (index < array.length) {
			// 		let temp = index + length;
			// 		newArray.push(array.slice(index, temp));
			// 		index = temp;
			// 	}
			// 	return newArray;
			// },
			expand(item, expand) {
				if (expand) {
					this.$http.get("/Plan/GetPlanListBySch", {
						stuID: this.stuID,
						schPlanID: item.ID,
						zhiYuanID: this.zhiyuanID,
						moBanPiCiID: this.piciID
					}, true).then((res) => {
						if (res.code == 1) {
							this.tableData.List.forEach((data) => {
								if (data == item) {
									data.SchMajorPlanList = res.data;
								}
							});
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			SaveZhiYuan(flag, schPlanID, majorPlanID) {
				let zySch= this.zhiyuan.Schools.filter(item => item.schPlanID == schPlanID);
				if (this.zhiyuan.Schools != undefined && zySch.length > 0) {
					this.$http.get("/FangAn/SaveZyMajor", {
						stuID: this.stuID,
						zhiYuanID: this.zhiyuanID,
						zySchID: schPlanID,
						zySchID: zySch[0].zySchID,
						majorPlanID: majorPlanID,
						seq: this.seq
					}, true).then((res) => {
						if (res.code == 1) {
							this.$message.success('已添加');
							this.zhiyuan.Schools.forEach((item) => {
								if (item.schPlanID == schPlanID) {
									item.Majors.push({
										majorPlanID: majorPlanID
									});
								}
								return;
							});
						} else {
							this.$message.error(res.msg);
						}
					});
				} else {
					this.$http.get("/FangAn/SaveZhiYuan", {
						stuID: this.stuID,
						schPlanID: schPlanID,
						zhiYuanID: this.zhiyuanID,
						piCiID: this.piciID,
						majorPlanID: majorPlanID,
						flag: flag,
						seq: this.seq
					}, true).then((res) => {
						if (res.code == 1) {
							this.$message.success('已添加');
							this.zhiyuan.Schools.push({
								schPlanID: schPlanID,
								zySchID: res.data.ZySchID,
								Majors: [{
									majorPlanID: majorPlanID
								}]
							});
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			}
		}
	}
</script>

<style scoped="scoped">
	a {
		text-decoration-line: none;
	}

	.el-tag {
		margin-right: 10px;
	}

	.major {
		position: relative;
		padding: 10px;
	}

	.major_operate {
		display: none;
		position: absolute;
		top: 3px;
		right: 0px;
		width: 60px;
		height: 30px;
	}

	.major_operate button {
		font-size: 18px;
	}

	.major:hover .major_operate {
		display: inline-block;
	}

	.el-icon-circle-check {
		font-size: 18px;
		margin-right: 10px;
	}

	.school {
		padding: 10px 10px 0px 10px;
		position: relative;
	}

	.schoolInfo {
		display: inline-block;
		width: 100%;
		height: 50px;
		margin-top: 10px;
		font-size: 14px;
	}

	.fold {
		width: 30px;
		position: absolute;
		top: 10px;
		right: 10px;
	}
</style>
