<template>
	<div>
		<div style="text-align: center;margin-bottom: 20px;">
			<el-radio-group v-model="code" style="display: contents;" @change="getdata">
				<el-radio label="本科"></el-radio>
				<el-radio label="专科"></el-radio>
			</el-radio-group>
			<el-input v-model="keyword" placeholder="请输入专业" @keydown="selectMajor" style="width: 200px;margin-right: 10px;margin-left: 30px;">
			</el-input>
			<el-button type="primary" @click="selectMajor">查找</el-button>
			<el-button @click="reset" style="margin-right: 100px;">重置</el-button>
			<el-button type="success" @click="OpenFold"
				:icon="openOrFold?'CirclePlus':'Remove'">{{openOrFold?'全部展开':'全部折叠'}}
			</el-button>
			<el-button type="primary" @click="majorConfirm">确认选择</el-button>
		</div>
		<div v-for="item in majors" v-if="Filter.length==0">
			<div class="header" @click="if(item.PCode==''){item.PCode='show'}else{item.PCode=''};">
				<el-button style="float: left; margin-right:50px;" type="text"
					:icon="item.PCode==''?'CirclePlus':'Remove'">{{item.PCode==''?'展开此类':'折叠此类'}}
				</el-button>
				<span class="title">{{item.Name}}（{{item.Code}}）</span>



				<span v-if="getSelectedCount(item)>0" style="float: right;margin-right:400px;" class="cj_color">已选
					{{getSelectedCount(item)}} 个</span>
			</div>
			<el-row style="margin-bottom: 20px; margin-left: 130px;" v-for="child in item.Children" v-show="item.PCode!=''">
				<el-col :span="5" style="text-align: left;">
					<span style="font-size: 16px;">
						<el-checkbox v-model="child.Checked" @change="ChangeCheckAll($event,child)"
							style="font-weight: bold;" size="small"
							:label="child.Name+'（'+child.Code+'）'"></el-checkbox>
					</span>
				</el-col>
				<el-col :span="19" style="padding-left: 20px;">
					<el-space wrap>
						<el-checkbox-group v-model="majorids">
							<el-checkbox v-for="(child2,index) in child.Children" :label="child2.Code+'|'+child2.Name">
								{{child2.Name}}
							</el-checkbox>
						</el-checkbox-group>
					</el-space>
				</el-col>
			</el-row>
		</div>
		<div v-if="Filter.length>0" style="border: 1px solid #e0e0e0;padding: 20px;">
			<el-space wrap>
				<el-checkbox-group v-model="majorids">
					<el-checkbox v-for="item in Filter" :label="item.Code+'|'+item.Name">
						{{item.Name}}
					</el-checkbox>
				</el-checkbox-group>
			</el-space>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				maxSelectCnt: 1000,	//专业选择的最大数量
				code: '本科',
				majors: [],
				majorids: [],
				keyword: '',
				Filter: [],
				openOrFold: true
			}
		},
		methods: {
			ChangeCheckAll(checked, child) {
				let childs = child.Children;
				
				//专业选择不能超过指定的数量
				if((this.majorids.length + childs.length) > this.maxSelectCnt){
					this.majors.forEach(itemA =>{
						itemA.Children.forEach(itemB => {
							if(itemB.ID == child.ID){
								itemB.Checked = false;
							}
						})
					})
					this.$message.error('专业选择的数量不能超过【' + this.maxSelectCnt + '】个!');
					return;
				}
				
				for (let i = 0; i < childs.length; i++) {
					let code = childs[i].Code + '|' + childs[i].Name;
					let index = this.majorids.indexOf(code);
					if (checked) {
						if (index < 0) {
							this.majorids.push(code);
						}
					} else {
						if (index >= 0) {
							this.majorids.splice(index, 1);
						}
					}
				}
			},
			getSelectedCount(item) {
				if (this.majorids.length == 0) {
					return;
				}
				let count = 0;
				for (let i = 0; i < item.Children.length; i++) {
					let arr = item.Children[i];
					for (let j = 0; j < arr.Children.length; j++) {
						let obj = arr.Children[j];
						if (obj && this.majorids.indexOf(obj.Code + '|' + obj.Name) >= 0) {
							count++;
						}
					}
				}
				return count;
			},
			setMajorids(ids,isClearAll) {
				this.majorids = ids;
				if(isClearAll){
					this.majors.forEach(itemA =>{
						itemA.Children.forEach(itemB => {
							itemB.Checked = false;
						})
					})
				}
			},
			reset() {
				this.keyword = '';
				this.Filter = [];
			},
			majorConfirm() {
				this.$emit("majorConfirm", this.majorids);
			},
			getdata() {
				this.majorids = [];
				this.openOrFold = true;
				this.$http.get("/major/GetAllMajors?Level=" + this.code, {

				}, true).then((res) => {
					if (res.code == 1) {
						this.majors = res.data;
						this.majors.forEach(itemA =>{
							itemA.Children.forEach(itemB => {
								itemB.Checked = false;
							})
						})
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			selectMajor() {
				if (this.keyword == '') {
					this.Filter = [];
					return;
				}
				this.$http.get("/major/GetMajorsByKeyword", {
					Level: this.code,
					Keyword: this.keyword
				}, true).then((res) => {
					if (res.code == 1) {
						this.Filter = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			OpenFold() {
				this.openOrFold = !this.openOrFold;
				if (this.openOrFold) {
					this.fold();
				} else {
					this.open();
				}
			},
			open() {
				this.keyword = '';
				this.Filter = [];
				this.majors.forEach((item) => {
					item.PCode = 'show';
				});
			},
			fold() {
				this.keyword = '';
				this.Filter = [];
				this.majors.forEach((item) => {
					item.PCode = '';
				});
			}
		},
		watch:{
			majorids(val){
				//专业选择不能超过指定的数量
				if(val.length > this.maxSelectCnt){
					this.majorids = val.slice(0, this.maxSelectCnt);
					this.$message.error('专业选择的数量不能超过【' + this.maxSelectCnt + '】个!');
				}
			}
		},
		created() {
			this.getdata();
		},
		mounted() {}
	}
</script>

<style scoped="scoped">
	.header {
		margin-bottom: 20px;
		height: 30px;
		line-height: 30px;
		border-bottom: 1px solid #DCDFE6;
		cursor: pointer;
	}

	.title {
		font-size: 16px;
		font-weight: 600;
		display: inline-block;
		padding-left: 20px;
		border-left: 4px solid #F56C6C;
	}

	.major {
		display: inline-block;
		width: 220px;
		text-align: right;
		cursor: pointer;
	}

	.major:hover {
		color: #F56C6C;
	}
</style>