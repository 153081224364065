<template>
	<div class="sxt-view-header">
		<span class="sxt-view-header-title">{{title}}</span>
		<slot></slot>
	</div>
</template>

<script>
	export default{
		props:['title'],
	}
</script>

<style scoped="scoped">
	.sxt-view-header {
		border-bottom: 0.0625rem solid #e0e0e0;
		margin-bottom: 0.625rem;
	}
	
	.sxt-view-header-title {
		font-weight: bold;
		margin: 0.3125rem 0.625rem 0.625rem 0.625rem;
		display: inline-block;
	}
</style>
