<template>
	<router-view v-slot="{ Component }">
		<transition>
			<keep-alive>
				<component :is="Component" />
			</keep-alive>
		</transition>
	</router-view>
	<el-dialog v-model="showDialogSbj" title="提醒" width="500">
		<span style="font-size: 16px;">您的选科/文理信息未填写，请完善信息后再使用该功能！<br /></span>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="this.showDialogSbj = false">取消</el-button>
				<el-button type="primary" @click="GoToSbj">
					去完善
				</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script>
	import router from './router';
	export default {
		name: 'App',
		data() {
			return {
				showDialogSbj: false,
			}
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			GoToSbj() {
				//前往个人中心完善选科/文理
				this.showDialogSbj = false;
				router.push("base");
			},
		},
		watch: {
			"$store.state.showCheckSbj": {
				handler: function(newVal, oldVal) {
					if (newVal == true)
						this.showDialogSbj = newVal;
				}
			},
		},
		mounted() {
			let href = window.location.href;
			if(href.indexOf('orgpub')>=0){
				return;
			}

			if (this._isMobile()) {

				var a = document.createElement("a");
				//获取手机端
				var url = "";
				this.$http.get("OrgExt/GetOrgMobileOrPCDomain", {
					domain: window.location.host,
					type: "mobile"
				}, true).then((res) => {
					if (res.code == 1) {
						// url = "http://" + res.data;
						url=window.location.href.replace(window.location.host,res.data)
						
						// a.setAttribute("href", url);
						// a.setAttribute("target", "_blank");
						// a.click();
						window.location.href = url;
					} else {
						this.$message.error(res.msg);
					}
				});
			}
			// this.$cookies.config('30d');
		},
		created() {
			// //在页面刷新时将vuex里的信息保存到localStorage里
			// window.addEventListener("beforeunload", () => {
			// 	localStorage.setItem("messageStore", JSON.stringify(this.$store.state))
			// })

			// //在页面加载时读取localStorage里的状态信息
			// localStorage.getItem("messageStore") && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(
			// 	localStorage.getItem("messageStore"))));
		}
	}
</script>