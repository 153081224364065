<template>
	<div class="addTag">
		<!-- <div style="color:deeppink;">预置、自定义标签展示区？</div> -->
		<div>
			<span style="margin-right: 10px;">备注</span>
		</div>
		<el-input v-model="note" type="textarea" rows="6" maxlength="500" placeholder="最大长度500个字符"></el-input>
		<div class="footer">
			<el-button @click="cancel">取消</el-button>
			<el-button type="primary" @click.once="Save">确定</el-button>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['afterAdd','afterCancled'],
		components: {},
		props: {
			logID: {
				type: Number,
				default: 1
			},
			detailID: {
				type: Number,
				default: 1
			},
			propertyID: {
				type: Number,
				default: 1
			},
		},
		data() {
			return {
				_logID: '',
				note: ''
			}
		},
		watch: {
			logID() {
				this._logID = this.logID
			}
		},
		computed: {

		},
		created() {
			this._logID = this.logID
		},
		methods: {
			Save() {
				this.$emit('afterAdd',this.note)						
			},
			cancel() {
				this.$emit('afterCancled')
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.addTag {
		line-height: 1.6;
	}

	.footer {
		padding: 10px 0px 0px 0px;
	}
</style>