<template>
	<div class="root-container">
		<div class="introduce">
			以下为<span class="light"> {{AreaName}} </span>考生热门报考的城市，<span class="light"> 不是已保存的倾向
			</span>。
		</div>
		<div class="total">
			<div>
				极喜欢<span class="light">{{favTotal(3)}}个</span>
			</div>
			<div>
				很喜欢<span class="light">{{favTotal(2)}}个</span>
			</div>
			<div>
				喜欢<span class="light">{{favTotal(1)}}个</span>
			</div>
			<div>
				不考虑<span class="light">{{favTotal(-1)}}个</span>
			</div>
		</div>

		<div class="items">
			<el-collapse accordion v-model="activeHot" @change="expandHot">
				<el-collapse-item v-for="item in cityTypes" :title="item.Value" :name="item.Key">
					<template #title>
						<el-badge style="margin-top:8px;" :value="getSettedCount(item.cities)"
							:hidden="isHidden(item.cities)" class="item">
							<span style="font-size: 16px;margin-left: 5px;">{{item.Value}}</span>
						</el-badge>
					</template>
					<template v-if="item.cities!=undefined">
						<div class="batchSet">
							<span style="float: left;">批量设置：</span>
							<favrate :Readonly="false" @OnChange="(val)=>{groupChanged(val,item)}">
							</favrate>
						</div>
						<div class="cities">
							<div v-for="city in item.cities" class="city">
								<span>{{city.CityName}}</span>
								<favrate :Readonly="false" :Val="city.FavValue" @OnChange="(val)=>{onChange(val,city)}">
								</favrate>
							</div>
						</div>
					</template>
				</el-collapse-item>
			</el-collapse>
		</div>
		<div class="optBar" v-if="total>0">
			<el-radio-group v-if="isShowOptType" v-model="optType">
				<el-radio :label="1" title="如果和【已保存】有重合,则以当前设置为准">已保存的不做调整</el-radio>
				<el-radio :label="2" title="保存前会清空之前的设置">已保存的全部清除</el-radio>
			</el-radio-group>
			<el-button type="primary" @click="save">保 存({{total}})</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isShowOptType: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				AreaName: this.$store.getters.getUserInfo().AreaName,
				cityTypes: [],
				activeHot: '',
				optType: 1
			}
		},
		computed: {
			total() {
				// var num = this.favTotal(3) + this.favTotal(2) + this.favTotal(1) + this.favTotal(-1);
				// return num
				var cities = this.getSettedCities()
				return cities.length
			}
		},
		methods: {
			expandHot(v, bForce) {
				this.cityTypes.forEach(item => {
					if (item.Key == v) {
						if (item.cities == undefined || bForce) {
							this.$http.get("/StuArchive/GetHotCityList2?cityType=" + v, {}, true).then((res) => {
								if (res.code == 1) {
									
									res.data.forEach((city,i)=>{
										var obj=this.getSameCity(city.CityID)
										if(obj!=null)
											city.FavValue=obj.FavValue
									})
									item.cities = res.data;
									// debugger
								} else {
									this.$message.error(res.msg);
								}
							});
						}
					}
				})
			},

			getHotCitys() {
				this.$http.get("/StuArchive/GetHotCityTypeList", {

				}, true).then((res) => {
					if (res.code == 1) {
						this.cityTypes = res.data;
						this.activeHot = res.data[0].Key
						this.expandHot(this.activeHot, true)
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			groupChanged(val, group) {
				group.cities.forEach((item, i) => {
					item.FavValue = val
					this.synFavValue(val,item.CityID)
				})
			},
			synFavValue(val, cityID) {
				this.cityTypes.forEach((item, i) => {
					if (item.cities)
						item.cities.forEach((city, k) => {
							if (city.CityID == cityID) {
								city.FavValue = val
							}
						})
				})
			},
			getSameCity(cityID) {
				var obj=null;
				this.cityTypes.forEach((item, i) => {
					if (item.cities)
						item.cities.forEach((city, k) => {
							if (city.CityID == cityID) {
								obj=city
								return
							}
						})
				})
				return obj
			},
			favTotal(val) {
				var num = 0
				var cities = this.getSettedCities()
				var ary = cities.filter(c => c.FavValue == val)
				return ary.length
			},
			getSettedCount(cities) {
				var iNum = 0
				if (cities) {
					var ary = cities.filter(c => c.FavValue != 0)
					iNum = ary.length
				}
				return iNum
			},
			isHidden(cities) {
				var iNum = this.getSettedCount(cities)
				if (iNum > 0)
					return false
				else
					return true
			},
			onChange(val, city) {
				city.FavValue = val
				this.synFavValue(val,city.CityID)
			},
			getSettedCities() {
				var cities = []
				this.cityTypes.forEach((item, i) => {
					if (item.cities)
						item.cities.forEach((city, k) => {
							if (city.FavValue != 0) {
								var obj = cities.find((a, m) => {
									if (a.CityID == city.CityID)
										return true
									else
										return false
								})
								if (!obj)
									cities.push(city)
							}
						})
				})
				return cities
			},
			save() {
				this.$confirm("确定执行保存吗?", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					var cities = this.getSettedCities()
					this.$http.post('/StuArchive/BatchSaveCityFav', {
						OptType: this.optType,
						Cities: cities
					}, true).then((res) => {
						if (res.code == 1) {
							this.$message.success('保存成功')
							this.$emit('afterSaved')
						} else
							this.$message.error(res.msg)
					})

				})
			}
		},
		created() {
			this.getHotCitys()
		}
	}
</script>

<style scoped="scoped" lang="less">
	.root-container {
		background-color: white;
		padding: 10px;
		font-size: 16px;
		height: 100%;

		:deep(.el-badge__content.is-fixed) {
			top: 9px;
			// padding-left: 15px;
			right: calc(1px + var(--el-badge-size)/ 2 - 5px);
		}
	}

	.introduce {
		margin: 0px 0px;
		padding: 20px 0px;
		font-size: 16px;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.items {}

	.total {
		padding-top: 5px;
		display: flex;
		height: 40px;
		font-weight: 600;

		.light {
			font-weight: 600;
			color: #f56c6c;
			margin-left: 10px;
		}

		div {
			margin-right: 20px;
		}
	}

	.optBar {
		display: flex;
		justify-content: right;
		padding: 20px 20px 10px 20px;

		>* {
			margin-left: 20px;
		}
	}

	.batchSet {
		padding: 5px;
		display: flex;
		justify-items: left;
	}

	.favratescss {
		display: block !important;
	}

	.cities {
		display: flex;
		flex-wrap: wrap;
		padding: 5px;
	}

	.city {
		display: flex;
		margin: 2px;
	}
</style>