<template>

	<el-dialog v-model="isshowphonecode" title="找回密码" destroy-on-close width="600px">
		<div>
			<el-steps style="width: 100%; margin-bottom: 15px;" :active="0" finish-status="success" align-center>
				<el-step title="手机号验证" />
				<el-step title="设置新密码" />
				<el-step title="完成" />
			</el-steps>
			<el-row class="type">
				<el-col :span="24">
					<div style="padding: 20px 20px;">
						<el-form :model="param" :rules="rules" label-width="100px" ref="phone">
							<el-form-item label="手机号" prop="mobile">
								<el-input size="mini" style="width: 200px;" v-model="param.mobile" />
							</el-form-item>
							<el-form-item label="验证码" prop="code">
								<el-input size="mini" style="width: 200px;" v-model="param.code" />
								<el-button size="mini" type="primary" style="margin-left: 10px;"
									:disabled="countdown > 0" @click="getcode">
									{{ countdown > 0 ? `${countdown}秒后可用` : '获取验证码' }}
								</el-button>
							</el-form-item>
						</el-form>
						<div style="margin-top: 20px;padding-left: 140px;">
							<el-button type="primary" @click="checkcode">下一步
							</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</el-dialog>

	<el-dialog v-model="isshownewpwd" title="找回密码" destroy-on-close width="600px">
		<div>
			<el-steps style="width: 100%; margin-bottom: 15px;" :active="1" finish-status="success" align-center>
				<!-- 				<el-step class="cj_color" title="手机号验证">
					<template class="cj_color" #default>
						<div class="cj_color">
							<el-icon name="Check"></el-icon>
							<span>手机号验证1</span>
						</div>
					</template>
					<template class="cj_color" #title>
						<div class="cj_color">
							<span>手机号验证1</span>
						</div>
					</template>
					<template class="cj_color" #icon>
						<el-icon size="30px">
							<CircleCheck />
						</el-icon>
					</template>
				</el-step> -->
				<el-step title="手机号验证" />
				<el-step title="设置新密码" />
				<el-step title="完成" />
			</el-steps>
			<el-row class="type">
				<el-col :span="24" class="cj_border">
					<div style="padding: 20px 20px;">
						<el-form :model="param" :rules="rules" label-width="100px" ref="pass">
							<el-form-item label="当前卡号">
								{{cardcode}}
							</el-form-item>
							<el-form-item label="新密码" prop="PWD">
								<el-input type="password" size="mini" style="width: 200px;" v-model="param.PWD" />
							</el-form-item>
							<el-form-item label="确认密码" prop="PWD2">
								<el-input type="password" size="mini" style="width: 200px;" v-model="param.PWD2" />
							</el-form-item>
						</el-form>
						<div style="margin-top: 20px; padding-left:140px;">
							<el-button type="primary" @click="submitnewpwd">确定
							</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</el-dialog>

	<el-dialog v-model="isshowsuccessmsg" title="找回密码" destroy-on-close width="600px" style="min-height: 420px;">
		<div style="text-align: center !important;">
			<el-steps style="width: 100%; margin-bottom: 15px;" :active="3" finish-status="success" align-center>
				<el-step title="手机号验证" />
				<el-step title="设置新密码" />
				<el-step title="完成" />
			</el-steps>
			<div style="padding: 60px 60px;">您的密码已设置成功，请重新登录</div>
			<div style="margin-top: 20px;">
				<el-button type="primary" @click="tologin">去登录
				</el-button>
			</div>
		</div>
	</el-dialog>

</template>

<script>
	export default {
		props: {
			fromurl: {
				type: String,
				default: ""
			},
		},
		data() {
			return {
				isshowphonecode: false, //弹出找回密码的手机号与验证码的窗体
				isshownewpwd: false, //弹出找回密码填写新密码窗体
				isshowsuccessmsg: false, //密码设置成功后的提示信息窗口
				cardcode: '',
				param: {
					mobile: '',
					code: '',
					PWD: '',
					PWD2: ''
				},
				rules: {
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						min: 11,
						max: 11,
						message: '手机号格式不对',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					PWD: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'

					}, {
						min: 6,
						message: '密码长度不能少于6位',
						trigger: 'blur'
					}],
					PWD2: [{
						required: true,
						message: '请再次输入新密码',
						trigger: 'blur'

					}, {
						min: 6,
						message: '密码长度不能少于6位',
						trigger: 'blur'
					}],
				},
				countdown: 0, // 初始倒计时秒数
				timer: null, // 计时器
			}
		},
		methods: {
			//找回密码的方法开始
			//点击忘记密码链接
			forgotpwd() {
				this.isshowphonecode = true; //显示手机号和验证码窗口
			},
			//根据手机号获取绑定的卡号
			getcardcodebymobile() {
				this.$http.get("/Student/GetCardCodeByMobile", {
					mobile: this.param.mobile
				}, true).then((res) => {
					if (res.code == 1) {
						this.cardcode = res.data.CardCode;
						this.startCountdown(); //开始发送验证码
					} else {
						this.$message.error(res.msg);
						return;
					}
				});
			},
			//获取验证码倒计时按钮
			startCountdown() {
				if (this.countdown > 0) {
					return; // 倒计时中，不执行点击操作
				}
				// 开始倒计时，设置计时器
				this.countdown = 300; // 倒计时5秒
				//开始发送验证码
				this.$http.get("/Student/SendVerificationCode", {
					mobile: this.param.mobile
				}, true).then((res) => {
					if (res.code == 1) {
						this.$message.success("验证码已发送，注意查收");
					} else {
						this.$message.error(res.msg);
					}
				});
				this.timer = setInterval(() => {
					if (this.countdown > 0) {
						this.countdown -= 1;
					} else {
						clearInterval(this.timer); // 倒计时结束，清除计时器

					}
				}, 1000);
			},
			//发送手机验证码
			getcode(phone) {
				if (this.param.mobile.length == 0) {
					this.$message.error('请输入手机号');
					return;
				}
				this.getcardcodebymobile();

			},
			//验证输入的验证码是否正确
			checkcode() {
				if (this.param.mobile.length == 0) {
					this.$message.error('请输入手机号');
					return;
				}
				if (this.param.code.length == 0) {
					this.$message.error('请输入验证码');
					return;
				}

				this.$http.get("/Student/GetCardCodeByMobile", {
					mobile: this.param.mobile
				}, true).then((res) => {
					if (res.code == 1) {
						this.cardcode = res.data.CardCode;
						//开始验证验证码
						this.$http.get("/Student/VerificationCode", {
							Mobile: this.param.mobile,
							Code: this.param.code
						}, true).then((res) => {
							if (res.code == 1) {
								//验证码验证通过，弹出设置密码的窗口
								this.isshowphonecode = false;
								this.isshownewpwd = true;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					} else {
						this.$message.error(res.msg);
						return;
					}
				});


			},
			//验证手机验证码
			confirm(code) {
				//验证码验证通过，弹出设置密码的窗口
				this.isshowphonecode = false;
				this.isshownewpwd = true;
			},
			//设置新密码
			submitnewpwd() {
				if (this.param.PWD != this.param.PWD2) {
					this.$message.error('两次密码不一致！');
					return;
				}
				if (this.param.PWD.length < 6 || this.param.PWD2.length < 6) {
					this.$message.error('密码长度不能少于6位！');
					return;
				}
				//修改密码
				this.$http.post("/Student/ChangePassword", {
					cardcode: this.cardcode,
					mobile: this.param.mobile,
					pwd: this.param.PWD
				}, true).then((res) => {
					if (res.code == 1) {
						this.isshownewpwd = false;
						this.isshowsuccessmsg = true;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			tologin() {
				if (this.fromurl == "cplogin") {
					location.href = location.origin + '/#/base';
				}
				this.isshowsuccessmsg = false;
			}
			//找回密码的方法结束
		},
		created() {

		}
	}
</script>

<style scoped>
	/deep/.cj_border {
		border-bottom: none !important;
	}

	/deep/.el-step__title {
		font-size: 12px;
		line-height: 48px;
	}

	/* 修改已完成步骤的文本和图标颜色 */
	/deep/.el-step__title.is-finish {
		color: #409EFF;
	}

	/deep/.el-step__title.is-success {
		color: #409EFF;
		border-color: #409EFF;
	}

	/deep/.el-step__head.is-success {
		color: #409EFF;
		border-color: #409EFF;
	}
</style>