<template>
	<div>
		<div style="background-color: #fff;padding-left: calc(50% - 600px);padding-right: calc(50% - 600px);height:64px;"
			@dblclick="ClickLogo">
			<img class="sxt-logo" v-if="isShowLogo" :src="HeaderData.LogoUrl" @click="toHome()"/>
			<img v-if="HeaderData.FlagUrl != undefined && isShowLogo" class="sxt-logo" :src="HeaderData.FlagUrl" @click="toHome()"/>
			<span
				style="float: right;background-color: red;display: inline-block;height: 40px;margin-top: 10px;border-radius: 5px;line-height: 40px;color: #fff;padding: 0px 10px;"
				v-if="isVirtualLogin">虚拟登录</span>
			<template v-if="this.HeaderData.HasAdminEntrance">
				<a target="_blank"
					style="float: right;float: right;display: flex;align-items: center;margin-top: 15px;margin-right:15px;"
					href="http://admin.gaobaoyun.com/" title="点击登录学校管理平台">
					<img style="height: 40px;width: auto;" src="/imgs/schlog.png" />
					<span style="margin-left: 10px;color:#909399;">登录管理平台</span>
				</a>
			</template>
			<template v-else>
				<el-input v-if="HeaderData.ShowSearch"
					style="width: 200px;float: right;margin-right: 20px;margin-top: 16px;" placeholder="请输入内容"
					suffix-icon="Search" v-model="input2" @change="ToSeach();">
				</el-input>
				<span @click="ShowAreas()" v-if="areaName && areaName != ''"
					style="float: right;vertical-align: middle;margin-top: 21px;margin-right: 10px;cursor: pointer;">
					<el-icon class="cj_color" style="margin-right: 10px;">
						<LocationFilled />
					</el-icon>
					<span style="color: #909399;">{{ areaName }}</span>
					<!-- <el-icon style="margin-left: 5px;">
						<ArrowDown />
					</el-icon> -->
					&nbsp;
				</span>
			</template>
			<template v-if="stuTags.Value || stuTags.Key">
				<img v-if="useType==7&&orgExtModel.IsShowTiYanUp==1" src="/imgs/upgrade.png" class="upgrade" @click="toUpgrade()">
				<el-tooltip v-if="stuTags.Value && stuTags.Value != ''" effect="dark" :content="stuTags.Value"
					placement="top-start">
					<span class="box-item">{{ stuTags.Key }}</span>
				</el-tooltip>
				<span v-else-if="stuTags.Key && stuTags.Key != ''" class="box-item">{{ stuTags.Key }}</span>
			</template>
		</div>
		<!-- 录取分查询页面，页面往下滚动一些，再添加包含专业弹出Modal窗体关闭后，菜单与下面数据区域会有80px空隙-->
		<!-- 屏蔽掉el-affix，待详查 -->
		<!-- <el-affix :offset="0" target="body">-->
			<div class="cj_darkBack" style="padding-left: calc(50% - 600px);padding-right: calc(50% - 600px);">
				<el-menu :background-color="webStyle()" text-color="#fff" :active-text-color="activeStyle()"
					:default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
					<template v-for="(item, index) in HeaderData.HeaderNavis">
						<template v-if="item.children.length == 0">
							<el-menu-item
								:index="item.TargPath + (item.TargPath.indexOf('?') >= 0 ? '&name=' + item.Name : '?name=' + item.Name)">

								<!-- <img  src="../assets/imgs/navi/card24.png"/> -->

								{{ item.Name }}
							</el-menu-item>
						</template>
						<el-sub-menu :index="index" v-else>
							<template #title>{{ item.Name }}
								<!-- <img  src="../assets/imgs/navi/card24.png"/> -->
							</template>
							<template v-for="child in item.children">
								<el-menu-item v-if="child.TargPath.indexOf('http')>=0">
									<div style="width: 100%;">
										<a :href="child.TargPath" style="color: #fff;display: block;" target="_blank">
											{{ child.Name }}
										</a>
									</div>
								</el-menu-item>
								<el-menu-item v-else v-show="isShowChild(child)"
									:index="child.TargPath + (child.TargPath.indexOf('?') >= 0 ? '&name=' + child.Name : '?name=' + child.Name)">
									<div style="width: 100%;">
										{{ child.Name }}

										<i v-if="child.Type>0" class="cls_vip"
											v-html="GetHtml(child.Icon)"></i>

									</div>
								</el-menu-item>
							</template>
						</el-sub-menu>
					</template>
				</el-menu>
			</div>
		<!-- </el-affix> -->
		<!-- 虚拟登录进来显示学生的信息 -->
		<div v-if="isVirtualLogin"
			style="font-size: 14px; padding-left: calc(50% - 600px);padding-right: calc(50% - 600px); text-align: right; color:#666;">
			<span v-html="userinfo"></span>
		</div>
		<cpArea ref="cp_area" :isShowAreas="isShowAreas" @OnClose="isShowAreas = false" :areaid="areaid"
			@ClickArea="ClickArea"></cpArea>
	</div>
</template>

<script>
	import cpArea from './cp_area.vue'
	export default {
		components: {
			cpArea
		},
		data() {
			return {
				HeaderData: {},
				activeIndex: this.$store.getters.getCurrentUrl() == '' ? '/home' : this.$store.getters.getCurrentUrl(),
				GKModel: this.$store.getters.getGKModel(),
				input2: '',
				isVirtualLogin: this.$store.getters.get_isVirtualLogin(),
				areaName: '',
				areaid: '',
				isShowAreas: false,
				userinfo: "",
				stuTags: '',
				isShowLogo: this.$store.getters.getIsShowLogo(),
				orgExtModel:{},
			}
		},
		computed: {
			getSubject() {
				return function(sub, type) {
					if (typeof(sub) == "string" && sub.length > 0) {
						let temp = sub.replace("W", "物理，").replace("H", "化学，").replace("S", "生物，").replace("L", "历史，")
							.replace("Z", "政治，").replace("D", "地理，").replace("J", "技术，").replace("w", "物理，").replace(
								"h", "化学，").replace("s", "生物，").replace("l", "历史，")
							.replace("z", "政治，").replace("d", "地理，").replace("j", "技术，");
						let temp2 = temp.substring(0, temp.length - 1);
						return temp2;
					} else {
						return type;
					}
				}
			},
			isSuper() {
				let _this = this;
				return function() {
					let temp = 0;
					if (_this.$store.getters.getUserInfo() != null) {
						temp = _this.$store.getters.getUserInfo().IsSuper;
					}
					return temp;
				}
			},
			//这里需要把store 动态的数据放到computed里面才会同步更新 视图
			token() {
				let _this = this;
				return function() {
					return _this.$store.getters.getToken();
				}
			},
			webStyle() {
				let _this = this;
				return function() {
					let temp = _this.$store.getters.getWebStyle();
					if (temp != null && temp != undefined && temp.length > 0) {
						temp = "#" + temp.slice(1, 7);
					} else {
						temp = "#fff";
					}
					let temp2 = "#fff";
					switch (temp) {
						case "#e84c33":
							temp2 = '#c72f16';
							break;
						case "#ff2c2c":
							temp2 = '#e02929';
							break;
						case "#fa648c":
							temp2 = '#d65a7b';
							break;
						case "#ff9646":
							temp2 = '#dc833f';
							break;
						case "#14b482":
							temp2 = '#0a9c6f';
							break;
						case "#09bb07":
							temp2 = '#0c9c0a';
							break;
						case "#8278f0":
							temp2 = '#746cd4';
							break;
						case "#409EFF":
							temp2 = '#3c8ee3';
							break;
						case "#105cfb":
							temp2 = '#1456e1';
							break;
					}
					return temp2;
				}
			},
			activeStyle() {
				let _this = this;
				return function() {
					let temp = _this.$store.getters.getWebStyle();
					if (temp != null && temp != undefined && temp.length > 0) {
						temp = "#" + temp.slice(1, 7);
					} else {
						temp = "#fff";
					}
					let temp2 = "#fff";
					switch (temp) {
						case "#e84c33":
							temp2 = '#ec9284';
							break;
						case "#ff2c2c":
							temp2 = '#fc9c9c';
							break;
						case "#fa648c":
							temp2 = '#fad7e0';
							break;
						case "#ff9646":
							temp2 = '#fce4d1';
							break;
						case "#14b482":
							temp2 = '#cbf4e7';
							break;
						case "#09bb07":
							temp2 = '#d1f6d0';
							break;
						case "#8278f0":
							temp2 = '#cbc7f8';
							break;
						case "#409EFF":
							temp2 = '#c7e2ff';
							break;
						case "#105cfb":
							temp2 = '#d1dffc';
							break;
					}
					return temp2;
				}
			},
			useType() {
				let val = 0
				if (this.$store.getters.getUserInfo() != null) {
					val = this.$store.getters.getUserInfo().UseType;
				}
				return val
			}

		},
		methods: {
			GetHtml(icon) {
				let cls = "cls_more_animate"
				if (icon == 'vip') {
					cls = 'cls_vip_animate';
				}

				let html = '<span class="' + cls + '">' + icon + '</span>';

				return html;
			},
			cardType() {
				let _this = this;

				let temp = 0;
				if (_this.$store.getters.getUserInfo() != null) {
					temp = _this.$store.getters.getUserInfo().CardType;
				}
				return temp;
			},
			isShowChild(child) {
				let show = true;
				if (child.TargPath) {
					if (child.TargPath.toLowerCase().indexOf('server') >= 0 || child.TargPath.toLowerCase().indexOf(
							'jiangzuo') >= 0) {
						let x = this.cardType();
						if (x != 1) {
							show = false;
						}
					}
				}
				return show;
			},
			ClickLogo() {
				if (this.isShowLogo) {
					this.isShowLogo = false;
				} else {
					this.isShowLogo = true;
				}
				this.$store.commit("SetIsShowLogo", this.isShowLogo);
			},
			ClickArea(item) {
				this.areaid = item.ID;
				this.areaName = item.Name;
				this.isShowAreas = false;

				this.$refs["cp_area"].SetUserArea(item.ID, item.Name);
				// 登录时，清缓存的，存正式的。未登录，存缓存。
				this.$router.go(0)
			},
			ShowAreas() {
				let mdl = this.$store.getters.getUserInfo();
				if (mdl == null)
					this.isShowAreas = true;
			},
			province() {
				let mdl = this.$store.getters.getUserInfo();
				if (!mdl) {
					this.areaName = "";
					this.areaid = "";
					return;
				}
				let _this_ = this;
				this.$refs["cp_area"].province(function(areaname, areaid) {
					_this_.areaName = areaname;
					_this_.areaid = areaid;
				});
			},
			handleOpen(key, keyPath) {

			},
			handleClose(key, keyPath) {

			},
			handleSelect(key, keyPath) {
				if (this.$route.meta.activeIndex != key || this.$route.path != key) {
					this.$router.push(key);
				}
			},
			getOrgExtMdl(){
				this.$http.get("OrgExt/GetOrgModelByDomain", {
					
				}, true).then((res) => {
					if (res.code == 1) {
						this.$store.commit("SetOrgExtModel",res.data);
						this.orgExtModel = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			getOrgHeaderModel() {
				this.$http.get("OrgExt/GetOrgHeaderModel", {
					domain: window.location.host,
					pcOrPhone: 'pc'
				}, true).then((res) => {
					if (res.code == 1) {
						this.HeaderData = res.data; // SetOrgID
						this.$store.commit('set_logoUrl', res.data.LogoUrl);
						this.$store.commit('set_flagUrl', res.data.FlagUrl);
						this.$store.commit('SetIsUpCard', res.data.IsUpCard);
						this.$store.commit('SetOrgID', res.data.OrgID);
						//图标
						this.change_icon(res.data.IconUrl)
						//标题 WebsiteTitle
						if(res.data.SiteName)
							document.title = res.data.SiteName;
						
						
						// 设置meta标签的属性
						if(res.data.WebsiteTitle){
							var meta = document.createElement('meta');
							meta.setAttribute("name", res.data.WebsiteTitle);
							meta.setAttribute("content", res.data.WebsiteMeta);
							document.head.appendChild(meta);
						}
						else{
							var meta = document.createElement('meta');
							meta.setAttribute("name", "description");
							meta.setAttribute("content", "一家专门致力于高考志愿填报、职业发展规划指导的专业教育机构。拥有资深、严谨的专家团队，对高考志愿的规划、填报有深厚的历史经验和理论基础，基于完整的大数据平台，已为一批批全国各地考生提供了精准、满意的高考志愿填报服务。");
							document.head.appendChild(meta);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			ToSeach() {
				this.$router.push("contentList?key=" + this.input2);
			},
			change_icon(link) {
				let favicon = document.querySelector('link[rel="icon"]');
				if (favicon !== null) {
					favicon.href = link;
				} else {
					favicon = document.createElement("link");
					favicon.rel = "icon";
					favicon.href = link;
					document.head.appendChild(favicon);
				}
			},
			ShowUserInfo() {
				let mdl = this.$store.getters.getUserInfo();
				if (mdl) {
					var xueke = mdl.Sbj;
					if (this.$store.getters.getXuankeType() != '' && xueke != "") {
						xueke = " (" + mdl.SbjNames.replace("+", "").replace("+", "") + ")";
					} else {
						if (mdl.WLType == "114") {
							xueke = "(理科)";
						} else if (mdl.WLType == "112") {
							xueke = "(文科)";
						}
					}
					// var sex = "<span style='color:blue;'>♂</span>";
					// if (mdl.SexName == "女") {
					// 	sex = "<span style='color:red;'>♀</span>";
					// }
					if (mdl.Name != "") {
						this.userinfo = mdl.Name + " " + mdl.SexName + " " + xueke;
					}

				}
			},
			getStuTags() {
				this.stuTags = this.$store.getters.getStuTags();
				console.log(this.stuTags);
			},
			toUpgrade() {
				//此处暂时只考虑升级志愿卡
				let cardOption = 2
				let toUrl = encodeURIComponent(this.$route.path)
				this.$router.push('product?tocard=' + cardOption + '&toUrl=' + toUrl)
			},
			toHome()
			{
				this.$router.push('/')
			}
		},
		created() {
			this.ShowUserInfo();
			this.getOrgHeaderModel();
			this.$nextTick(function() {
				this.province();
			})
			this.getStuTags();
			this.getOrgExtMdl();
		}
	};
</script>
<style>
	.cls_more_animate {
		animation-name: ball2;
		animation-duration: 5s;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
		position: absolute;
		left: 0px;
		transform: rotate(0deg);
	}

	@keyframes ball2 {
		0% {
			left: 0px;
		}

		20% {
			left: 0px;
		}

		35% {
			left: 20px;
		}

		38% {
			transform: rotate(0deg);
		}

		42% {
			transform: rotate(-20deg);
		}

		45% {
			transform: rotate(20deg);
		}

		47% {
			transform: rotate(-8deg);
		}

		49% {
			transform: rotate(8deg);
		}

		52% {
			transform: rotate(0deg);
		}

		90% {
			left: 20px;
		}
	}

	.cls_vip_animate {
		animation-name: ball;
		animation-duration: 5s;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
		position: absolute;
		animation-timing-function: linear;
		right: 0;
	}

	@keyframes ball {
		0% {
			transform: scale(0.9, 0.9);
		}

		50% {
			transform: scale(1.1, 1.1);
		}

		100% {
			transform: scale(0.9, 0.9);
		}

		/* 20% {
			text-shadow: 0px 0px 16px #fff;
			font-size: 16px;
		}
		
		30% {
			text-shadow: 0px 0px 16px #fff;
			font-size: 16px;
		}
		50% {
			text-shadow: 0px 0px 14px #fff;
			font-size: 14px;
		}
		
		80% {
			text-shadow: 0px 0px 12px #fff;
			font-size: 12px;
		}
		
		100% {
			text-shadow: 0px 0px 14px gold;
			font-size: 14px;
		} */
	}
</style>
<style scoped="scoped">
	.upgrade {
		float: right;
		margin-top: 16px;
		margin-left: -30px;
		margin-right: 15px;
		cursor: pointer;
	}

	.cls_vip {
		position: relative;
		color: gold !important;
		float: right;
		width: 45px;
		height: 35px;
		display: inline-block;
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	}

	.sxt-logo {
		height: 60px;
		width: auto;
		cursor: pointer;
	}

	.box-item {
		float: right;
		vertical-align: middle;
		margin-top: 18px;
		margin-right: 40px;
		cursor: pointer;
		border: 1px solid #0c9c0a;
		border-radius: 6px;
		padding: 5px 10px;
		color: #0c9c0a;
		font-size: 16px;
		font-weight: bold;
	}
</style>