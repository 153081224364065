<template>
	<div>
		<div class="remark">
			1.联合条件：之间用空格“ ”分割，为“且”关系。搜北京的大学，可输入“<span class="light">北京&nbsp;&nbsp;大学</span>”。
			<br />
			2.多个条件：之间以逗号“,”分隔，为“或”关系。<b>同时搜“复旦大学”、“山东大学”</b>，可输入“<span class="light">复旦大学,山东大学</span>”。
		</div>
		<div class="filter">
			<el-input v-model="keyWord" class="keyWord" placeholder="关键字" @keyup="onKeyup" clearable></el-input>
			<el-button type="primary" @click="filter">搜 索</el-button>
			<el-button type="primary" @click="okClick"
				:disabled="(this.selected && this.selected.length>0)?false:true">确认添加</el-button>
		</div>
		<el-table :data="list" stripe style="width:100%;" border @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" />
			<el-table-column type="index" width="60" align="center" label="序号" :index="indexMethod" />
			<el-table-column prop="Name" align="left" label="名称">
				<template #default="scope">
					<span v-html="scope.row.NameHtml"></span>
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" width="80">
				<template #default="scope">
					<el-link @click="afterSelected(scope.row)" title="点击选择">
						选择
					</el-link>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10,15,20,30]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
		</el-pagination>
	</div>
</template>
<script>
	export default {
		components: {},
		props: {
			type: {
				type: Number,
				default: 0
			},
			areaID:{
				type: String,
				default: ''
			},
			stuID:{
				type: String,
				default: ''
			}
		},
		emits: ['afterSelected'],
		data() {
			return {
				keyWord: '',
				currentPage: 1, // 当前页码
				pageSize: 15, // 每页的数据条数
				totalCount: 0, // 总条数
				sidx: '',
				sord: '',
				list: [],
				tm: null,
				selected: []
			}
		},
		watch: {
			keyWord() {
				if (this.tm != null)
					window.clearTimeout(this.tm)
				this.tm = setTimeout(() => {
					this.filter()
				}, 1000)
			},
		},
		created() {
			this.filter()
		},
		methods: {
			onKeyup(e) {
				if (e.keyCode == 13)
					this.filter()
			},
			getData() {
				this.$http.get('Compare/GetCompareQueryList', {
					type: this.type,
					keyWord: this.keyWord,
					rows: this.pageSize,
					page: this.currentPage,
					sidx: this.sidx,
					sord: this.sord,
					areaID:this.areaID,
					stuID:this.stuID
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data.list
						this.totalCount = res.data.TotalRows;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			//每页条数改变时触发 选择一页显示多少行
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.$store.commit("set_PageRows", val);
				this.getData();
			},
			//当前页改变时触发 跳转其他页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			filter() {
				this.currentPage = 1;
				this.getData();
			},
			afterSelected(mdl) {
				this.$emit('afterSelected', mdl.ID,false)
			},
			okClick() {
				let ary = this.selected.map(c => c.ID)
				let ids = ary.join(',')
				this.$emit('afterSelected', ids)
			},
			handleSelectionChange(val) {
				this.selected = val
				console.log(this.selected)
			},
			indexMethod(index) {
				return (this.currentPage - 1) * this.pageSize + index + 1;
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.optItem {
		margin: 10px 0px;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.bottomBar {
		margin: 10px 0px;
		display: flex;
		justify-content: right;
	}

	.type1 {
		color: #409EFF;
	}

	.type2 {
		color: #67C23A;
	}

	.type3 {
		color: #E6A23C;
	}

	.type4 {
		color: #00008B;
	}

	.type5 {
		color: #DB7093;
	}

	.type6 {
		color: #008080;
	}

	.level {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.filter {
		display: flex;
		margin: 10px 0px;

		.keyWord {
			width: 450px;
		}

		>*:nth-child(n+2) {
			margin-left: 10px;
			max-width: 250px;
		}
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	:deep(.keyword) {
		color: red;
	}

	.addLink {
		font-size: 16px;
	}

	.delLink {
		font-size: 16px;
		color: red;
	}
</style>