<template>
	<div>
		<div class="filter">
			<el-input v-model="keyWord" class="keyWord" placeholder="关键字" @keyup="onKeyup" clearable></el-input>
			<el-button type="primary" @click="getData">搜 索</el-button>
		</div>
		<el-table :data="list" stripe style="width:100%;" border>
			<el-table-column prop="Name" align="left" label="名称">
				<template #default="scope">
					<span v-if="scope.row.Name">{{scope.row.Name}}</span>
					<span v-else>[草稿]</span>
				</template>
			</el-table-column>
			<el-table-column prop="EditDt" label="修改日期" width="180">
			</el-table-column>
			<el-table-column align="center" label="操作" width="180">
				<template #default="scope">
					<el-link v-if="scope.row.ID!=this.logID" @click="afterSelected(scope.row)" title="点击切换"
						type="primary">
						选择
					</el-link>
					<span v-else style="color: green;">当前</span>
					<el-link @click="del(scope.row)" title="删除" type="primary" style="margin-left: 10px;">
						删除
					</el-link>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		components: {},
		props: {
			logID: {
				type: Number,
				default: 0
			},
			areaID: {
				type: String,
				default: ''
			},
		},
		emits: ['afterSelected'],
		data() {
			return {
				keyWord: '',
				list: [],
			}
		},
		watch: {
			logID() {
				this.getData()
			},
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.$http.get('Compare/GetLogs', {
					logID: this.logID,
					keyWord: this.keyWord,
					areaID:this.areaID
				}, true).then(res => {
					if (res.code == 1) {
						this.list = res.data
						this.list = this.list.filter(c => c.ID != this.logID)
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			afterSelected(mdl) {
				this.$emit('afterSelected', mdl.ID)
			},
			onKeyup(e) {
				if (e.keyCode == 13)
					this.getData()
			},
			del(mdl) {
				this.$confirm("确认删除吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http.get('Compare/DelLog', {
						ID: mdl.ID,
						areaID:this.areaID
					}, true).then(res => {
						if (res.code == 1) {
							this.getData()
							this.$message.success('删除成功')
						} else {
							this.$message.error(res.msg)
						}
					})
				})
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.optItem {
		margin: 10px 0px;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.bottomBar {
		margin: 10px 0px;
		display: flex;
		justify-content: right;
	}

	.type1 {
		color: #409EFF;
	}

	.type2 {
		color: #67C23A;
	}

	.type3 {
		color: #E6A23C;
	}

	.type4 {
		color: #00008B;
	}

	.type5 {
		color: #DB7093;
	}

	.type6 {
		color: #008080;
	}

	.level {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.filter {
		display: flex;
		margin: 10px 0px;

		.keyWord {
			width: 250px;
		}

		>*:nth-child(n+2) {
			margin-left: 10px;
			max-width: 250px;
		}
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	:deep(.keyword) {
		color: red;
	}

	.addLink {
		font-size: 16px;
	}

	.delLink {
		font-size: 16px;
		color: red;
	}
</style>