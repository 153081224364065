<template>
	<el-dialog 
		destroy-on-close 
		v-model="showDialog" 
		:close-on-press-escape="false" 
		:close-on-click-modal="false" 
		:before-close="onClose"
		:width="360"
		:append-to-body = "true"
		>
		<div style="display:flex;align-items:center;width:300px;margin:0 auto;padding-bottom:20px;">
			<SlideVerify ref="slideblock" @again="onAgain" @fulfilled="onFulfilled" @success="onSuccess" @fail="onFail"
				:imgs="puzzleImgList" @refresh="onRefresh" :accuracy="accuracy" :slider-text="text"></SlideVerify>
		</div>
		<div :class="cls">{{ msg }}</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			isShowVerify:Boolean,
		},
		watch:{
			isShowVerify(){
				this.showDialog = this.isShowVerify;
			},
		},
		data() {
			return {
				showDialog:false,
				puzzleImgList: [
					require('../lib/verify/img.jpg'),
					require('../lib/verify/img1.jpg'),
					require('../lib/verify/img2.jpg'),
					require('../lib/verify/img3.jpg'),
					require('../lib/verify/img4.jpg'),
					require('../lib/verify/img5.jpg'),
				],
				msg: "",
				text: "向右滑",
				// 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
				accuracy: 3,
				cls:'cls_error'
			};
		},
		created() {
			this.lower();
		},
		methods: {
			onClose(){
				this.$emit("onClose");
				this.$refs.slideblock.removeListener();
			},
			// 验证成功，触发该方法
			onSuccess(times) {
				let ms = (times / 1000).toFixed(1);
				this.showContextSecond("验证通过",1500);
				this.$emit("onSuccess")
				this.cls = 'cls_success';
				this.$refs.slideblock.removeListener();
			},
			onFail() {
				this.showContextSecond("验证不通过",1500);
				this.cls = 'cls_error';
			},
			showContextSecond(text,second){
				this.msg = text;
				let _this_ = this;
				setTimeout(function(){
					_this_.msg = "";
				},second);
			},
			onRefresh() {
				this.msg = "";
			},
			onFulfilled() {
			},
			onAgain() {
				this.showContextSecond("请重试",1500);
				// 刷新
				this.$refs.slideblock.reset();
			},
			handleClose() {
				// console.log("val", val);
			},
			lower() {
				//console.log("我是home文件的方法");
			},
		},
	};
</script>
<style>
	.cls_error{
		text-align: center;
		color: orangered;
	}
	.cls_success{
		text-align: center;
		color:seagreen;
	}
</style>