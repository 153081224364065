<template>
	<span class="school" @click="toSchoolSecond">
		<el-image :src="schooldata.Logo">
			<template #error>
				<div class="image-slot">
					<el-icon>
						<Picture />
					</el-icon>
				</div>
			</template>
		</el-image>
		<span class="name">
			<span>
				{{schooldata.Name}}
			</span>
			<jieDuNumber v-if="schooldata.ID" class="jieDu" :key="schooldata.ID" :refID="schooldata.ID"
				:refType="schooldata.DicType" :num='schooldata.JieDuNum'></jieDuNumber>
			<compareIcon v-if="isLogin" type="1" :refID="schooldata.ID" class="compare"></compareIcon>
		</span>
		<span class="theTag">
			<schoolTag :schooldata="schooldata"></schoolTag>
			<div style="margin-right: 5px;color: #999;padding-top: 5px;">{{ schooldata.ShengShi }}</div>
		</span>

	</span>
</template>

<script>
	export default {
		props: ['schooldata'],
		data() {
			return {

			}
		},
		computed: {
			isLogin() {
				let val = false
				let uInfo = this.$store.getters.getUserInfo()
				if (uInfo)
					val = true
				return val
			}
		},
		methods: {
			toSchoolSecond() {
				this.$emit("toParent");
			}
		}
	}
</script>

<style scoped="scoped">
	span {
		display: inline-block;
	}

	.school {
		position: relative;
		width: 100%;
		height: 100px;
		display: inline-block;
		cursor: pointer;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.school .el-image {
		width: 100px;
		height: 100px;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.name {
		position: absolute;
		top: 20px;
		left: 120px;
		height: 30px;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	/* .name>*:nth-child(1) {
		margin-right: 5px;
	} */

	.name>*:nth-child(n+1) {
		margin-right: 5px;
	}

	.theTag {
		position: absolute;
		top: 60px;
		left: 120px;
		height: 50px;
	}

	.name:hover .jieDu,
	.name:hover .compare {
		display: inline-block !important;
	}

	/* .compare {
		margin-top: 8px;
	} */
</style>