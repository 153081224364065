<template>
	<div class="sxt-navi1">
		<el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect"
			:default-active="defaultActive" unique-opened="true">
			<el-menu-item-group v-for="item in menus">
				<el-menu-item v-if="item.SubMenus.length==0" :index="item.Url">
					<i :class="item.Icon"></i>
					<span slot="title">{{item.Name}}</span>
				</el-menu-item>
				<el-sub-menu :index="item.Name" v-else-if="item.SubMenus.length>0">
					<template #title>
						<i :class="item.Icon"></i>
						<span>{{item.Name}}</span>
					</template>
					<el-menu-item :index="child.Url" v-for="child in item.SubMenus">
						<i :class="child.Icon"></i>
						<span slot="title">{{child.Name}}</span>
					</el-menu-item>
				</el-sub-menu>
			</el-menu-item-group>
		</el-menu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isCollapse: false,
				menus: [],
				defaultActive: this.$store.getters.getCurrentUrl()
			}
		},
		methods: {
			handleOpen(key, keyPath) {

			},
			handleClose(key, keyPath) {

			},
			handleSelect(key, keyPath) {
				if (this.$route.meta.activeIndex != key || this.$route.path != key) {
					this.$router.push(key);
				}
			}
		},
		created() {
			this.$http.get('/Module/GetMenu?OrgID=' + this.$store.getters.getCurrentOrgID()).then((response) => {
				if (response.code == 1) {
					this.menus = response.data.Menus;
					this.$store.commit('set_flatmenus', response.data.FlatMenus);
					this.$store.commit('set_menus', response.data.Menus);
					this.$store.commit('set_permission_Operate', response.data.Permission_Operate);
					this.$store.commit('set_permission_Data', response.data.Permission_Data);
				} else {
					this.$message.info(response.msg);
				}
			});
		}
	}
</script>

<style scoped="scoped">
	.sxt-navi1 {
		position: fixed;
		top: 60px;
		left: 0px;
		bottom: 40px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 100%;
	}
</style>
