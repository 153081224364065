<template>
	<div>
		<el-table :data="list" stripe style="width: 100%;" border>
			<el-table-column type="index" label="序" width="70" />
			<el-table-column prop="AddMngName" label="点赞人">
			</el-table-column>
			<el-table-column prop="OrgNames" align="left" label="机构">
			</el-table-column>			
			<el-table-column prop="AddDt" label="添加日期" width="200px">
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10,15,20,30]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
		</el-pagination>
	</div>	
</template>
<script>
	export default {
		props: {
			contentID: {
				Type:String,
				default: ''
			},
		},
		data() {
			return {
				keyWord: '',
				currentPage: 1, // 当前页码
				pageSize: 15, // 每页的数据条数
				totalCount: 0, // 总条数
				sidx: 'adddt',
				sord: 'desc',
				list: [],				
			}
		},
		created() {
			this.filter()
		},
		methods: {			
			getData() {
				let url ='Tag/GetZans'
				this.$http.get(url, {
					keyWord: this.keyWord,
					rows: this.pageSize,
					page: this.currentPage,
					sidx: this.sidx,
					sord: this.sord,
					contentID: this.contentID
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data.list
						this.totalCount = res.data.TotalRows;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			//每页条数改变时触发 选择一页显示多少行
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.$store.commit("set_PageRows", val);
				this.getData();
			},
			//当前页改变时触发 跳转其他页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			filter() {
				this.currentPage = 1;
				this.getData();
			},			
		},
	}
</script>
<style scoped="scoped" lang="less">
	:deep(.is-left .cell) {
		text-align: left;
	}
</style>