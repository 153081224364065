<template>
	<el-dialog v-model="showDialog" width="940px" :before-close="BeforeClose" :show-close="false"
		custom-class="dialog_transparent_content">
		<div class="login-container">
			<img class="loginImg" src="../../public/imgs/login.png" />

			<div class="login_close" @click="BeforeClose">
				&times;
			</div>
			<div class="logo">
				<img class="logoImg" :src="logoUrl" />
				<span v-if="orgExtModel.TypeTag">
					<div class="type_name">{{orgExtModel.TypeName}}</div>
					<div class="type_tag">{{orgExtModel.TypeTag}}</div>
				</span>
				<span v-else>{{orgExtModel.TypeName}}</span>
			</div>
			<el-form :model="ruleForm2" :rules="rules2" status-icon ref="ruleForm2" label-position="left"
				label-width="0px" class="demo-ruleForm login-page">
				<el-form-item prop="CardCode">
					<el-input @keyup.enter.native="shiftFocus" type="Content" v-model="ruleForm2.CardCode"
						auto-complete="off" placeholder="帐号、手机号"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input @keyup.enter.native="handleSubmit" ref="PWD" type="password" v-model="ruleForm2.password"
						auto-complete="off" placeholder="密码"></el-input>
				</el-form-item>
				<el-form-item style="width: 100%;">
					<div style="width: 100%;display: flex;justify-content: space-between !important;">
						<el-checkbox v-model="checked" class="rememberme">记住密码</el-checkbox>
						<span @click="forgotpwd()"><el-link style="cursor: pointer; ">忘记密码</el-link></span>
					</div>
				</el-form-item>
				<el-form-item style="width: 100%">
					<el-button type="primary" style="width: 100%" @click="toLogin" :loading="logining">登录
					</el-button>
				</el-form-item>
				<el-form-item class="gobuybox" v-if="orgExtModel.IsCanLineSell==1">
					<span style="float: right; display:inline-block;"> <el-link style="color:#409eff"
							@click="gotobuy()">去开通账号</el-link> </span>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
	<loginVerify :isShowVerify="isShowVerify" @onSuccess="onVerifySuccess" @onClose="onClose"></loginVerify>

	<el-dialog v-model="isShowSetArea" title="卡号绑定省份" destroy-on-close width="700px">
		<setArea :cardId="cardInfo.ID" @onSetAreaClose="onSetAreaClose" @onSetAreaFinished="onSetAreaFinished">
		</setArea>
	</el-dialog>

	<el-dialog v-model="showGiftTip" title="福利提醒" destroy-on-close width="600px" @close="NotGetGift" class="diaGift">
		<giftTip @onGetGift="GetGift" @onNotGetGift="NotGetGift">
		</giftTip>
	</el-dialog>
	<ForgotPwd ref="forgotpassword" fromurl="cplogin"></ForgotPwd>
</template>

<script>
	import loginVerify from './cp_loginVerify.vue'
	import setArea from './cp_cardSetArea.vue';
	import giftTip from './cp_gifttip.vue';
	import ForgotPwd from './cp_forgotpwd.vue';

	export default {
		components: {
			loginVerify,
			setArea,
			giftTip,
			ForgotPwd
		},
		props: ['type', 'fatherMethod', 'loginShow'],
		watch: {
			loginShow() {
				this.showDialog = this.loginShow;
			},
		},
		data() {
			return {
				showDialog: false,
				logoUrl: this.$store.getters.getLogoUrl(),
				isUpCard: this.$store.getters.getIsUpCard(),
				OrgID: this.$store.getters.getOrgID(),
				rootOrgList: [],
				orgThow: false,
				logining: false,
				vefPwd: false,
				ruleForm2: {
					CardCode: "",
					password: ""
				},
				rules2: {
					CardCode: [{
							required: true,
							message: "请输入账号",
							trigger: "blur"
						},
						{
							validator: this.verificationUid,
							trigger: "blur"
						},
					],
					password: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							validator: this.verificationPwd,
							trigger: "blur"
						},
					]
					// telephone: [
					//   {
					//     pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/,
					//     required: true,
					//     message: "手机号格式不对",
					//     trigger: "blur"
					//   }
					// ]
				},
				checked: false,
				isShowVerify: false,
				cardInfo: {},
				isShowSetArea: false,
				orgExtModel: this.$store.getters.getOrgExtModel(),
				showGiftTip: false,
			};
		},
		methods: {
			onSetAreaFinished() {
				this.onSetAreaClose();
				this.login();
			},
			onSetAreaClose() {
				this.isShowSetArea = false;
			},
			onClose() {
				this.isShowVerify = false;
			},
			onVerifySuccess() {
				this.login();
				this.isShowVerify = false;
			},
			AddLoginCount() {
				let count = this.$store.getters.get_loginCount();
				count++;
				this.$store.commit("set_loginCount", count);
			},
			toLogin() {
				let count = this.$store.getters.get_loginCount()
				if (count > 1) {
					this.isShowVerify = true;
				} else {
					this.isShowVerify = false;
					this.login();
				}
			},
			BeforeClose() {
				this.$emit("BeforeClose")
			},
			login() {
				this.$http.get("/Student/LoginByPwd", {
					CardCode: this.ruleForm2.CardCode,
					Pwd: this.ruleForm2.password
				}, true).then((res) => {
					if (res.code == 2) { //首次登录,未设置省份
						this.cardInfo = res.data;
						this.isShowSetArea = true;
						return;
					}
					if (res.code == 1) { //非首次登录，已设置省份
						this.$message.success("登录成功");
						if (this.checked) { //如果选中了记住密码的选项
							this.setCookie(this.ruleForm2.CardCode, this.ruleForm2.password, "1",
								7); //则去设置cookie,username是要提交的用户名，password是密码，7是要存储的天数
						} else {
							this.clearCookie() //如果没有选中记住密码，那就清除cookie
						}
						this.userInfo = res.data.UserInfo;
						this.token = res.data.Token;
						this.$store.commit("setUserInfo", res.data.UserInfo);
						this.$store.commit("set_token", res.data.Token);
						this.$store.commit("set_XuanKeType", res.data.XuanKeType);
						this.$store.commit("set_GKModel", res.data.Model);

						this.$store.commit("set_StuTags", res.data.Tags);

						this.$store.commit("set_isVirtualLogin", false);

						//弹窗登录
						//登录后，检查是否有福利领取
						if (this.userInfo.WLType != '' || this.userInfo.Sbj != '') {
							//非首次登录，已设置基本信息
							this.GiveStuGift();
						} else {
							//首次登录，未设置基本信息
							this.loginAfter();
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			loginAfter() {
				if (this.type != 2) {
					this.redirect();
					this.$router.go(0);
					return;
				} else {
					//从专业和院校来
					if (this.fatherMethod) {
						this.fatherMethod();
						this.$router.go(0);
						return;
					}
				}

				let obj = {};
				obj.AreaID = this.userInfo.AreaID;
				obj.AreaName = this.userInfo.AreaName;
				this.$store.commit("set_UserSetArea", obj);
				this.$router.go(0);
			},
			handleSubmit() {
				this.vefPwd = true;
				this.$refs["ruleForm2"].validate((valid) => {
					if (valid) {
						this.login();
						this.logining = false;
					} else {
						console.log("error submit!");
						return false;
					}
				});
			},
			setCookie(c_name, c_pwd, c_cheack, exdays) {
				var exdate = new Date(); //获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
				//字符串拼接cookie
				window.document.cookie =
					"CardCode" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();

				//expires是设置cookie的过期时间，toGMTString是将日期转为GMT的字符串进行拼接
				window.document.cookie =
					"password" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();

				window.document.cookie =
					"ischecked" + "=" + c_cheack + ";path=/;expires=" + exdate.toGMTString();

			},
			//读取cookie  将用户名和密码回显到input框中
			getCookie() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split("; "); //这里显示的格式需要切割一下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split("="); //再次切割
						//判断查找相对应的值
						if (arr2[0] == "CardCode") {
							this.ruleForm2.CardCode = arr2[1]; //保存到保存数据的地方（data中）
						} else if (arr2[0] == "password") {
							this.ruleForm2.password = arr2[1];
						} else if (arr2[0] == "ischecked") {
							if (arr2[1] == "1") {
								this.checked = true;
							} else {
								this.checked = false;
							}
						}
					}
				}
			},
			//清除cookie
			clearCookie: function() {
				this.setCookie("", "", "", -1);
			},
			shiftFocus() {
				this.$refs.PWD.focus();
			},
			redirect() {
				// this.$router.push({
				// 	path: this.$store.getters.getLoginToPath()
				// });
			},
			gotobuy() {
				let byurl = "/productlist";
				let lianxi = '/orgServer';

				if (this.orgExtModel.OnlineSellType == '联系方式') {
					this.$router.push({
						path: lianxi
					});
				} else {
					this.$router.push({
						path: byurl
					});
				}

				// this.$http.get("Base/GetIsJumpToQrCode", {

				// }, true).then(res => {
				// 	if (res.code == 1) {
				// 		if (res.data) {
				// 			this.$router.push({
				// 				path: lianxi
				// 			});
				// 		} else {
				// 			this.$router.push({
				// 				path: byurl
				// 			});
				// 		}
				// 	}
				// });

				// this.$router.push({ path: '/productlist'})
			},
			//赠送福利
			GiveStuGift() {
				this.$http.get("Gift/GiveStuGift", {
					triggerType: 1,
				}, true).then((res) => {
					if (res.code == 1) {
						let resData = res.data;
						if (resData > 0) {
							this.showGiftTip = true;
						} else {
							this.loginAfter();
						}
					} else {
						this.loginAfter();
					}
				});
			},
			//领取福利
			GetGift() {
				this.showGiftTip = false;
				location.href = location.origin + '/#/myGift';
				location.reload();
			},
			//暂不领取
			NotGetGift() {
				this.showGiftTip = false;
				this.loginAfter();
			},
			// //点击忘记密码链接
			forgotpwd() {
				this.BeforeClose(); //关闭登录窗口
				this.$refs.forgotpassword.forgotpwd();
			},
		},
		created() {
			this.getCookie();

			let isUpCard = this.isUpCard == 1 && this.OrgID != '057' && this.OrgID != '062' && this.OrgID != '063'
		}
	};
</script>
<style>
	.dialog_transparent_content {
		background-color: transparent;
		box-shadow: 0 0 0;
	}

	.diaGift {
		background-image: url('/imgs/gifttipbackground.png');
		background-size: cover;
		/* 这将确保背景图覆盖整个对话框 */
		background-repeat: no-repeat;
		/* 防止背景图重复 */
		--el-dialog-bg-color: unset !important;
		--el-dialog-box-shadow: unset !important;
	}

	.diaGift .el-dialog__header {
		display: none;
	}
</style>
<style scoped="scope" lang="less">
	.type_name{
		font-size: 26px;
	}
	.type_tag{
		font-size: 16px;
	}
	.login-container {
		position: relative;
		width: 100%;
		height: 460px;
	}

	.login_close {
		font-size: 20px;
		position: absolute;
		margin-right: 20px;
		z-index: 9999;
		margin-top: 20px;
		display: inline-block;
		width: 30px;
		height: 30px;
		right: 0;
		cursor: pointer;
		color: #888;
	}

	.login_close:hover {
		opacity: 0.8;
	}

	.logo {
		position: absolute;
		width: 513px;
		top: 67px;
		right: 10px;
		text-align: center;

		span {
			font-size: 30px;
			display: inline-block;
			vertical-align: middle;
			color: #303133;
		}
	}

	.logoImg {
		height: 57px;
		margin-right: 10px;
		width: auto;
		display: inline-block;
		vertical-align: middle;
	}

	.loginImg {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: auto;
	}

	.login-page {
		border-radius: 5px;
		position: absolute;
		top: 180px;
		right: 50px;
		width: 340px;
		padding: 35px 35px 15px;
		background: #fff;
		border: 1px solid #eaeaea;
		box-shadow: 0 0 25px #cac6c6;
	}

	label.el-checkbox.rememberme {
		margin: 0px 0px 15px;
		text-align: left;
	}

	.sxt-org-select {
		width: 200px;
		height: 40px;
		background-color: #409EFF;
		color: #fff;
		margin-right: 10px;
		border: 0px;
	}

	.gobuybox {
		float: right;
	}
</style>