<template>
	<div>
		<div class="remark">
			注：联合条件搜索时，关键字之间用空格分割，是“并且”的关系。想搜索“<b>清华大学的工程力学专业</b>”，可输入“<span class="light">清华大学&nbsp;&nbsp;工程力学</span>”。
		</div>
		<div class="filter">
			<el-select v-model="_type" placeholder="请选择分类" class="selType" @change="filter">
				<el-option key="0" label="全部" :value="0" />
				<el-option v-for="item in dicTypes" :key="item.Key" :label="item.Value" :value="item.Key" />
			</el-select>
			<el-select v-model="_jieDuStatus" placeholder="解读状态" class="selType" @change="filter">
				<el-option key="0" label="不限" :value="0" />
				<el-option key="-1" label="未解读的" :value="-1" />
				<el-option key="1" label="已解读的" :value="1" />
			</el-select>
			<el-input v-model="keyWord" placeholder="关键字" @keyup="onKeyup" clearable></el-input>
			<el-button type="primary" @click="filter">搜 索</el-button>
		</div>
		<el-table :data="list" stripe style="width: 100%;" border>
			<el-table-column prop="TypeName" align="center" label="类型" width="100">
				<template #default="scope">
					<span :class="getClassByType(scope.row.Type)">{{scope.row.TypeName}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="Name" align="left" label="名称" width="220">
				<template #default="scope">
					<el-link v-if="scope.row.Href" :href="scope.row.Href" target="_blank">
						<span v-html="scope.row.NameHtml"></span>
					</el-link>
					<span v-else v-html="scope.row.Name"></span>
				</template>
			</el-table-column>
			<el-table-column prop="Alias" align="left" label="别名">
				<template #default="scope">
					<span v-html="scope.row.AliasHtml"></span>
				</template>
			</el-table-column>
			<el-table-column prop="JieDuNum" label="解读数" width="80">
				<template #default="scope">
					<el-link v-if="scope.row.JieDuNum>0" type="primary"
						@click="showJieDus(scope.row)">{{scope.row.JieDuNum}}</el-link>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column v-if="isCanOperate" align="center" label="操作" width="80">
				<template #default="scope">
					<el-link class="delLink" v-if="isIncluded(scope.row.ID)" @click="delTag(scope.row)" title="删除">
						<el-icon>
							<Remove />
						</el-icon>
					</el-link>
					<el-link class="addLink" v-else @click="addTag(scope.row)" title="添加">
						<el-icon>
							<CirclePlus />
						</el-icon>
					</el-link>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页器 -->
		<el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10,15,20,30]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
		</el-pagination>
	</div>
	<el-dialog v-model="isShowJieDu" title="所有解读" width="95%" destroy-on-close>
		<contentList @afterChanged="afterChanged" :dicID="focID" :isOnlyMine="false" :defaultStatus="0"
			:isDisabled="true"></contentList>
	</el-dialog>
</template>
<script>
	export default {
		components: {},
		props: {
			selIDs: {
				type: Array,
				default: []
			},
			//-1:未解读的；0:不限制；1:已解读的；
			jieDuStatus: {
				type: Number,
				default: 0
			},
			type: {
				type: Number,
				default: 0
			},
			isCanOperate: {
				type: Boolean,
				default: true
			}
		},
		emits: ['afterAdd', 'afterDel'],
		data() {
			return {
				dicTypes: [],
				_type: 0,
				keyWord: '',
				currentPage: 1, // 当前页码
				pageSize: 15, // 每页的数据条数
				totalCount: 0, // 总条数
				sidx: 'type,seq',
				sord: 'asc',
				list: [],
				tm: null,
				ids: [],
				_jieDuStatus: 0,
				isShowJieDu: false,
				focID: 0
			}
		},
		watch: {
			keyWord() {
				if (this.tm != null)
					window.clearTimeout(this.tm)
				this.tm = setTimeout(() => {
					this.filter()
				}, 1000)
			},
			selIDs: {
				handler() {
					this.ids = this.selIDs
				},
				deep: true
			},
			jieDuStatus() {
				this._jieDuStatus = this.jieDuStatus
			},
			type() {
				this._type = this.type
			}
		},
		beforeCreate() {
			//打破循环引用
			this.$options.components.contentList = require('./cp_contentList.vue').default
		},
		created() {
			this.ids = this.selIDs
			this.getDicTypes()
			this._jieDuStatus = this.jieDuStatus
			this._type = this.type
			this.getData()
		},
		methods: {
			getClassByType(type) {
				let val = 'type' + type
				return val
			},
			addTag(row) {
				this.$emit('afterAdd', row)
				this.ids.push(row.ID)
				this.$message.success('已加入标签')
			},
			delTag(row) {
				this.$emit('afterDel', row)
				let idx = this.ids.indexOf(row.ID)
				if (idx > -1)
					this.ids.splice(idx, 1)
				this.$message.warning('已移除标签')
			},
			getDicTypes() {
				this.$http.get('Tag/GetDicTypes', {}, true).then(res => {
					if (res.code == 1) {
						this.dicTypes = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			onKeyup(e) {
				if (e.keyCode == 13)
					this.filter()
			},
			getData() {
				this.$http.get('Tag/GetDicList', {
					type: this._type,
					keyWord: this.keyWord,
					rows: this.pageSize,
					page: this.currentPage,
					sidx: this.sidx,
					sord: this.sord,
					jieDuStatus: this._jieDuStatus
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data.list
						this.totalCount = res.data.TotalRows;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			//每页条数改变时触发 选择一页显示多少行
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				this.$store.commit("set_PageRows", val);
				this.getData();
			},
			//当前页改变时触发 跳转其他页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			filter() {
				this.currentPage = 1;
				this.getData();
			},
			isIncluded(id) {
				let val = false
				if (this.ids != null && this.ids.includes(id)) {
					val = true
				}
				return val
			},
			showJieDus(mdl) {
				this.focID = mdl.ID
				this.isShowJieDu = true
			},
			afterChanged() {
				this.$emit('afterChanged')
			},
		},
	}
</script>
<style scoped="scoped" lang="less">
	.title1 {
		font-size: 1.2;
		font-weight: 600;
		padding: 5px 0px;
	}

	.remark {
		background-color: #ecf5ff;
		padding: 15px;
		line-height: 1.6;
		margin-bottom: 10px;
		color: #333;
	}

	.light {
		font-weight: 600;
		color: #409EFF;
	}

	.optItem {
		margin: 10px 0px;

		.el-link {
			color: var(--el-link-hover-text-color) !important;
		}

		>*:nth-child(n+2) {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 5px;
		}
	}

	.bottomBar {
		margin: 10px 0px;
		display: flex;
		justify-content: right;
	}

	.type1 {
		color: #409EFF;
	}

	.type2 {
		color: #67C23A;
	}

	.type3 {
		color: #E6A23C;
	}

	.type4 {
		color: #00008B;
	}

	.type5 {
		color: #DB7093;
	}

	.type6 {
		color: #008080;
	}

	.level {
		margin: 10px 0px;
		display: flex;
		align-items: center;

		>*:nth-child(n+2) {
			margin-left: 10px;
		}
	}

	.filter {
		display: flex;
		margin: 10px 0px;

		.selType {
			width: 110px;
		}

		>*:nth-child(n+2) {
			margin-left: 10px;
			max-width: 250px;
		}
	}

	:deep(.is-left .cell) {
		text-align: left;
	}

	:deep(.keyword) {
		color: red;
	}

	.addLink {
		font-size: 16px;
	}

	.delLink {
		font-size: 16px;
		color: red;
	}
</style>