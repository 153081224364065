<template>
	<el-container>
		<el-form>
			<template v-if="XuanKeType == '3+3'">
				<el-form-item label="选科" class="row">
					<el-checkbox-group :max="3" v-model="SbjList" @change="change">
						<el-checkbox label="W">物理</el-checkbox>
						<el-checkbox label="H">化学</el-checkbox>
						<el-checkbox label="S">生物</el-checkbox>
						<el-checkbox label="L">历史</el-checkbox>
						<el-checkbox label="D">地理</el-checkbox>
						<el-checkbox label="Z">政治</el-checkbox>
						<el-checkbox label="J" v-if="AreaID == 33">技术</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</template>
			<template v-else-if="!XuanKeType||XuanKeType == '3+1+2'">
				<el-form-item label="首选科目" class="row">
					<el-radio-group v-model="FirstSbj" @change="change">
						<el-radio label="W">物理</el-radio>
						<el-radio label="L">历史</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="再选科目" class="row">
					<el-checkbox-group :max="2" v-model="SbjList" style="display: inline-block;" @change="change">
						<el-checkbox label="H">化学</el-checkbox>
						<el-checkbox label="S">生物</el-checkbox>
						<el-checkbox label="D">地理</el-checkbox>
						<el-checkbox label="Z">政治</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</template>
		</el-form>
	</el-container>
</template>

<script>
export default {
	emits: ['change'],
	props: {
		AreaID: {
			type: String,
			default: ''
		},
		XuanKeType: {
			type: String,
			default: ''
		},
		AllSbjList: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			FirstSbj: '',
			SbjList: [],
		}
	},
	watch: {
		XuanKeType(val1, val2) {
			this.init()
		},
		AllSbjList(val1, val2) {
			this.init()
		},
		FirstSbj(val1, val2) {
			// alert('FirstSbj')
			this.chgAllSbj()
		},
		SbjList(val1, val2) {
			// alert('SbjList')
			this.chgAllSbj()
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.FirstSbj = ''
			this.SbjList = new Array()
			if (this.XuanKeType == '3+3') {
				this.AllSbjList.forEach((item) => {
					this.SbjList.push(item)
				})
			} else if (this.XuanKeType == '3+1+2') {

				this.AllSbjList.forEach((item) => {
					if (item == 'W' || item == 'L')
						this.FirstSbj = item
					else
						this.SbjList.push(item)
				})
			}
		},
		chgAllSbj() {
			this.AllSbjList.splice(0, this.AllSbjList.length);

			if (this.FirstSbj)
				this.AllSbjList.push(this.FirstSbj)

			this.SbjList.forEach((item) => {
				this.AllSbjList.push(item)
			})
		},
		change() {
			//选科满足数量要求再抛事件
			// if (this.AllSbjList.length == 3)
			this.$emit('change')
		}
	}
}
</script>

<style scoped>
.row {
	margin-bottom: 5px;
}
</style>
