<template>
	<table border="1" rules="all" align="center" width="90%" cellspacing="0">
		<thead>
			<tr>
				<th style="width: 500px;">
					<el-radio-group v-model="by" size="large" @change='orderby'>
						<el-radio-button label="0">按录取难度排序<el-icon><CaretBottom /></el-icon></el-radio-button>
						<el-radio-button label="1">按综合实力排序<el-icon><CaretBottom /></el-icon>
						</el-radio-button>
					</el-radio-group>
				</th>
				<th style="width: 80px;">
					排名等级
				</th>
				<th style="width: 80px;">
					{{tableData.PlanYear}}计划
				</th>
				<th style="width: 80px;">
					<span style="color: #0D84FF;">参考排名</span>
				</th>
				<th style="width: 450px;">
			<tr>
				<th colspan="3" style="border: 0px;">
					<span style="color: #E6A23C;">最低排名</span>
					<span>-最低分数-</span>
					<span style="color: #409EFF;">招生计划</span>
				</th>
			</tr>
			<tr>
				<th style="width: 150px;border-left: 0px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[0]:'--'}}
				</th>
				<th style="width: 150px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[1]:'--'}}
				</th>
				<th style="width: 150px;border-right: 0px;border-bottom: 0px;">
					{{tableData.SchScoreYears!=undefined?tableData.SchScoreYears[2]:'--'}}
				</th>
			</tr>
			</th>
			</tr>
		</thead>
		<tbody>
			<template v-for="item in tableData.List">
				<tr>
					<td>
						<router-link target="_self" style="width: 600px;float: left;" :to="'/schoolSecond?SchID='+item.SchID">
							<div style="margin: 5px;">
								({{item.DaiHao}}){{item.SchName}}
							</div>
						</router-link>
						<div style="margin: 5px;">
							({{item.MajorPlan.DaiHao}}){{item.MajorPlan.MajorName}}
						</div>
					</td>
					<td style="text-align: center;">
						{{item.Grade}}
					</td>
					<td style="text-align: center;">
						{{item.PlanCount}}
					</td>
					<td style="text-align: center;">
						{{item.RefOrder}}
					</td>
					<td v-if="item.LuQuScores.length>0">
				<tr>
					<td style="width: 147px;border-left: 0px;border-top: 0px;border-right:0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=1">
						<span style="color: #E6A23C;">{{item.MajorPlan.LuQuScores[0].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[0].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[0].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-right:0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=2">
						<span style="color: #E6A23C;">{{item.MajorPlan.LuQuScores[1].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[1].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[1].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-left: 0px;border-top: 0px;border-right: 0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=3">
						<span style="color: #E6A23C;">{{item.MajorPlan.LuQuScores[2].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[2].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[2].PlanCount}}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-bottom:0px;border-right: 0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=4">
						<span style="color: #E6A23C;">{{item.MajorPlan.LuQuScores[3].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[3].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[3].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-right: 0px;border-bottom: 0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=5">
						<span style="color: #E6A23C;">{{item.MajorPlan.LuQuScores[4].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[4].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[4].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-left: 0px;border: 0px;text-align: center;"
						v-if="item.MajorPlan.LuQuScores.length>=6">
						<span style="color: #E6A23C;">{{item.LuQuScores[5].LOrder}}</span>
						<span>-{{item.MajorPlan.LuQuScores[5].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.MajorPlan.LuQuScores[5].PlanCount}}</span>
					</td>
				</tr>
				</td>
				</tr>
				<tr>
					<td>
						<div style="margin: 5px;">本校概况：
							<span>{{item.Address}}(全国：{{item.COrder}}，省：{{item.POrder}}，{{item.SchType}}：{{item.FieldOrder}})</span>
						</div>
						<div style="margin: 5px;">
							<el-tag size="small" v-if="item.Department!=null&&item.Department!=''">
								{{item.Department}}
							</el-tag>
							<el-tag size="small" v-if="item.SchType!=null&&item.SchType!=''">
								{{item.SchType}}
							</el-tag>
							<el-tag size="small" v-if="item.FoundType!=null&&item.FoundType!=''">
								{{item.FoundType}}
							</el-tag>
							<el-tag size="small" v-if="item.Is211">211</el-tag>
							<el-tag size="small" v-if="item.Is985">985</el-tag>
							<el-tag size="small" v-if="item.IsXiao211">小211</el-tag>
							<el-tag size="small" v-if="item.IsGraduateSch">研</el-tag>
						</div>
					</td>
					<td colspan="3" style="text-align: center;">
						院校分
					</td>
					<td v-if="item.LuQuScores.length>0">
				<tr>
					<td style="width: 147px;border-left: 0px;border-top: 0px;border-right: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=1">
						<span style="color: #E6A23C;">{{item.LuQuScores[0].LOrder}}</span>
						<span>-{{item.LuQuScores[0].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[0].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-right: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=2">
						<span style="color: #E6A23C;">{{item.LuQuScores[1].LOrder}}</span>
						<span>-{{item.LuQuScores[1].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[1].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-right: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=3">
						<span style="color: #E6A23C;">{{item.LuQuScores[2].LOrder}}</span>
						<span>-{{item.LuQuScores[2].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[2].PlanCount}}</span>
					</td>
				</tr>
				<tr>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-bottom:0px;border-right: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=4">
						<span style="color: #E6A23C;">{{item.LuQuScores[3].LOrder}}</span>
						<span>-{{item.LuQuScores[3].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[3].PlanCount}}</span>
					</td>
					<td style="width: 147px;border-top: 0px;border-left: 0px;border-bottom: 0px;border-right: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=5">
						<span style="color: #E6A23C;">{{item.LuQuScores[4].LOrder}}</span>
						<span>-{{item.LuQuScores[4].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[4].PlanCount}}</span>
					</td>
					<td style="width: 147px;border: 0px;border-left: 0px;text-align: center;"
						v-if="item.LuQuScores.length>=6">
						<span style="color: #E6A23C;">{{item.LuQuScores[5].LOrder}}</span>
						<span>-{{item.LuQuScores[5].LScore}}-</span>
						<span style="color: #0D84FF;">{{item.LuQuScores[5].PlanCount}}</span>
					</td>
				</tr>
				</td>
				</tr>
			</template>
		</tbody>
	</table>
</template>

<script>
	export default {
		props: ['tableData'],
		data() {
			return {
				by: '0'
			}
		},
		methods: {
			orderby() {
				this.$emit("orderby", this.by);
			}
		},
		created() {

		}
	}
</script>

<style scoped="scoped">
a{
	text-decoration-line: none;
}
</style>
